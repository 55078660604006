import React from 'react';

import ThinkingWavelengthBase from './ThinkingWavelengthBase.js';


class ThinkingWavelengthLifeplan extends ThinkingWavelengthBase {
  renderInner() {
    const {thinkingWavelength, currentJob} = this.state.data;

    const minLineWidth = 100;
    const avoidPadding = (this.GRAPH_WIDTH/10 * 1.5);

    const leftWidth = thinkingWavelength * this.GRAPH_WIDTH/10 - avoidPadding - this.GRAPH_OFFSET;
    if (leftWidth > minLineWidth) {
      var avoidLeft = (
        <g className="avoidLeft">
          <line
            x1={30 - this.GRAPH_OFFSET} y1="316"
            x2={leftWidth} y2="316"
            stroke="red"
            strokeWidth="2"
            markerEnd="url(#tick)"
            markerStart="url(#tick)"
          />
          <rect
            x={leftWidth/2 - 13 - this.GRAPH_OFFSET/2}
            y="304"
            width="60"
            height="20"
            fill="#ffffff"
          />
          <text
            x={leftWidth/2 + 18 - this.GRAPH_OFFSET/2}
            y="321"
            textAnchor="middle"
            fill="red"
            fontSize="18"
            fontWeight="bold"
          >
            AVOID
          </text>
        </g>
      );
    }

    const rightWidth = (10 - thinkingWavelength) * this.GRAPH_WIDTH/10 - avoidPadding - this.GRAPH_OFFSET;
    if (rightWidth > minLineWidth) {
      var avoidRight = (
        <g className="avoidRight">
          <line
            x1={this.GRAPH_WIDTH - rightWidth} y1="316"
            x2={this.GRAPH_WIDTH} y2="316"
            stroke="red"
            strokeWidth="2"
            markerEnd="url(#tick)"
            markerStart="url(#tick)"
          />
          <rect
            x={this.GRAPH_WIDTH - rightWidth/2 - 30}
            y="304"
            width="60"
            height="20"
            fill="#ffffff"
          />
          <text
            x={this.GRAPH_WIDTH - rightWidth/2}
            y="321"
            textAnchor="middle"
            fill="red"
            fontSize="18"
            fontWeight="bold"
          >
            AVOID
          </text>
        </g>
      );
    }

    return (
      <g>
        <g
          transform={`translate(${thinkingWavelength * this.GRAPH_WIDTH / 10}, 50)`}
          onMouseDown={(e) => this.startDrag(
            e,
            this.thinkingWavelengthElem,
            (newValue) => ({thinkingWavelength: newValue})
          )}
          ref={(e) => this.thinkingWavelengthElem = e}
          className="highContributionZone"
        >
          <rect x="-110" y="-15" width="220" height="245" fill="#00A275" fillOpacity="0.25" className="hczGradient"/>
          <line x1="0" x2="0" y1="0" y2="230" stroke="#00A275" strokeWidth="2" className="hczPointer" />
          <g className="hczValue">
            <rect x="-15" y="205" width="30" height="20" fill="#00A275" rx="3" ry="3" />
            <text y="220" textAnchor="middle" fill="#FFFFFF">{Math.round(thinkingWavelength * 10)/10}</text>
          </g>
          <g className="hczLabel">
            <line x1="-110" x2="110" y1="231" y2="231" stroke="#00A275" strokeWidth="2" className="hczHorizontal" />
            <line x1="-110" x2="110" y1="300" y2="300" stroke="#00A275" strokeWidth="2" className="hczHorizontal" />
            <line x1="0" x2="0" y1="300" y2="315" stroke="#00A275" strokeWidth="2" className="hczVertical" />
            <circle cx="0" cy="315" r="5" fill="#00A275" />
            <text textAnchor="middle" x="0" y="254" fontSize="18" fill="#00A275" fillOpacity="0.9" fontWeight="bold">
              HIGH
            </text>
            <text textAnchor="middle" x="0" y="272" fontSize="18" fill="#00A275" fillOpacity="0.9" fontWeight="bold">
              CONTRIBUTION
            </text>
            <text textAnchor="middle" x="0" y="290" fontSize="18" fill="#00A275" fillOpacity="0.9" fontWeight="bold">
              ZONE
            </text>
          </g>
        </g>

        <g
          transform={`translate(${currentJob * this.GRAPH_WIDTH / 10}, 53)`}
          onMouseDown={(e) => this.startDrag(
            e,
            this.currentJobElem,
            (newValue) => ({currentJob: newValue})
          )}
          ref={(e) => this.currentJobElem = e}
          className="currentJob"
        >

          <g className="currentJobInner" transform="scale(0.5)">
            <path
              className="star"
              fill="#00A275"
              d="M0,21 6,37h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
              transform="translate(0,-10) scale(2)"
            />
            <rect
              x={(currentJob * this.GRAPH_WIDTH / 10 < 150) ? 35 : -145}
              y="27"
              width="112"
              height="24"
              fill="#00A275"
              rx="3"
              ry="3"
              className="currentJobLabel"
              transform="scale(2)"
            />
            <text
              x={(currentJob * this.GRAPH_WIDTH / 10 < 150) ? 91 : -90}
              y="44"
              textAnchor="middle"
              fontSize="14"
              fontWeight="bold"
              fill="#ffffff"
              fillOpacity="0.9"
              transform="scale(2)"
            >
              CURRENT ROLE
            </text>
          </g>
        </g>

        {avoidLeft}
        {avoidRight}
      </g>
    );
  }
}


export default ThinkingWavelengthLifeplan;
