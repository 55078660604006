import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as keycodes from '../keycodes.js';


export class BaseEditableInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (this.props.isFocused && !this.state.editing) {
      this.startEditing();
    }
  }

  render() {
    if (this.state.editing) {
      const EditorElement = this.props.editorElement;
      return (
        <EditorElement
          className={classNames(this.props.className, 'inlineInput')}
          defaultValue={this.props.value}
          onBlur={this.stopEditing}
          onKeyUp={this.onKeyUp}
          onKeyDown={this.onKeyDown}
          ref={(input) => this.input = input}
          {...this.props.editorProps}
        />
      );
    } else {
      const ViewerElement = this.props.viewerElement;
      return (
        <ViewerElement
          className={classNames(this.props.className, 'inlineInput', {blank: !this.props.value})}
          tabIndex="0"
          onFocus={this.startEditing}
          onClick={this.startEditing}
          title="Click to edit"
        >
          {this.props.children || this.props.value || this.props.blankPlaceholder}
        </ViewerElement>
      );
    }
  }

  startEditing = () => {
    this.setState({
      editing: true
    }, () =>
      // hack to workaround firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
      // without this, a blur event fires immediately after focusing for input type number
      setTimeout(() => {
        this.input.focus();

        if (this.props.autoselect) {
          this.input.select();
        }
      }, 0)
    );
  };

  onKeyDown = (event) => {
    if (event.keyCode === keycodes.ENTER || event.keyCode === keycodes.ESCAPE) {
      // IE clears inputs on escape. We don't want that behavior.
      event.preventDefault();

      this.stopEditing(event);
    }
  }

  stopEditing = (event) => {
    this.props.onChange(
      this.input.value,
      event.keyCode === keycodes.ENTER,
      this.props.value !== this.input.value
    );
    this.setState({
      editing: false
    });
  };
}

BaseEditableInput.defaultProps = {
  blankPlaceholder: ' --- ',
  isFocused: false,
  autoselect: false,
};

BaseEditableInput.propTypes = {
  isFocused: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  blankPlaceholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.array,
  editorProps: PropTypes.object,
  autoselect: PropTypes.bool.isRequired,
  className: PropTypes.string,
};


export class InlineInput extends BaseEditableInput {}

InlineInput.defaultProps = {
  ...BaseEditableInput.defaultProps,
  editorElement: 'input',
  viewerElement: 'span',
};


export class InlineTextarea extends BaseEditableInput {
  constructor(props) {
    super(props);

    if (this.props.multiline) {
      // only stop editing on escape key press, not on enter
      this.onKeyDown = (event) => {
        if (event.keyCode === keycodes.ESCAPE) {
          // IE clears inputs on escape. We don't want that behavior.
          event.preventDefault();

          this.stopEditing(event);
        }
      };
    }
  }
}

InlineTextarea.defaultProps = {
  ...BaseEditableInput.defaultProps,
  editorElement: 'textarea',
  viewerElement: 'div',
  multiline: false,
};

InlineTextarea.propTypes = {
  ...BaseEditableInput.propTypes,
  multiline: PropTypes.bool,
};
