import React from 'react';
import ReactDOM from 'react-dom/client';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import smoothscroll from 'smoothscroll-polyfill';
import 'babel-polyfill';
import Raven from 'raven-js';
import 'react-dates/initialize';
import 'react-app-polyfill/ie11';


import Application from './components/Application.js';
import reducer from './reducers';

import './css/paterson-global.scss';

smoothscroll.polyfill();

if (process.env.REACT_APP_SENTRY_DSN) {
  Raven.config(process.env.REACT_APP_SENTRY_DSN, {
    release: process.env.REACT_APP_GIT_COMMIT,
  }).install();
}

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');
  middlewares.push(logger);
}

// TODO: allow this in production? It's only used if the extension is
// actually installed.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));


ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Application />
  </Provider>
);
