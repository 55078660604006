import BaseStatusIndicator from './BaseStatusIndicator.js';

import './StatusIndicator.scss';


export default class StatusIndicator extends BaseStatusIndicator {
  STATUSES = [
    null,
    1,
    2,
    3,
    4,
    5
  ]

  className = 'statusIndicator'

  getStatusClassNames(status) {
    return `status-${status}`;
  }
}

StatusIndicator.defaultProps = {
  ...BaseStatusIndicator.defaultProps,
  altTexts: {
    null: 'no status',
    1: 'good',
    2: 'good/neutral',
    3: 'neutral',
    4: 'neutral/bad',
    5: 'bad',
  }
};
