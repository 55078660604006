import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Editor from 'draft-js-plugins-editor';
import createInlineToolbarPlugin, { Separator } from 'draft-js-inline-toolbar-plugin';
import createAutoListPlugin from 'draft-js-autolist-plugin';
import {
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  ContentState,
  ContentBlock
} from 'draft-js';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  UnorderedListButton,
  OrderedListButton,
} from 'draft-js-buttons';

import statusIndicatorDecorator from './StatusIndicatorDecorator.js';

import 'draft-js-inline-toolbar-plugin/lib/plugin.css';


export default class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getStateFromValue(props.value),
      editing: false,
    };

    this.inlineToolbarPlugin = createInlineToolbarPlugin({
      structure: [
        ItalicButton,
        BoldButton,
        UnderlineButton,
        Separator,
        HeadlineOneButton,
        HeadlineTwoButton,
        UnorderedListButton,
        OrderedListButton,
      ]
    });
    this.autoListPlugin = createAutoListPlugin();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState(this.getStateFromValue(nextProps.value));
    }
  }

  getStateFromValue(value) {
    if (!this.state || this.state.editorState === null) {
      return {
        editorState: value ?
          EditorState.createWithContent(convertFromRaw(value))
          :
          EditorState.createEmpty()
      };
    } else {
      return {
        editorState: value ?
          EditorState.push(this.state.editorState, convertFromRaw(value))
        :
          EditorState.push(this.state.editorState, ContentState.createFromText(''))
      };
    }
  }

  render() {
    const { InlineToolbar } = this.inlineToolbarPlugin;

    return (
      <div
        className="richTextEditor"
        onClick={this.focus}
      >
        <Editor
          ref={(e) => this.editor = e}
          editorState={this.state.editorState}
          onChange={this.onChange}
          handleKeyCommand={this.handleKeyCommand}
          plugins={[this.inlineToolbarPlugin, this.autoListPlugin]}
          onBlur={this.stopEditing}
          readOnly={!this.props.onChange}
          onFocus={this.startEditing}
          onTab={this.stopEditing}
          decorators={statusIndicatorDecorator}
          spellCheck={true}
        />
        {
          // this is an optimization, rendering lots of InlineToolbars is slow
          this.state.editing ? <InlineToolbar /> : null
        }
      </div>
    );
  }

  onChange = (editorState) => {
    this.setState({
      editorState: editorState,
    });
  }

  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(this.state.editorState, command);
    if (newState) {
      this.onChange(newState);
    }
  }

  stopEditing = () => {
    this.props.onChange(convertToRaw(this.state.editorState.getCurrentContent()));
    // This triggers a warning message that we can't fix -- InlineToolbar has a
    // bug that causes it to call setState after it's been unmounted (it does
    // unregister its visibility event handler in ComponentWillUnmount, but the
    // handler calls setTimeout(0). So if you make the component not visible
    // and then immediately unmount it, the setTimeout will happen while the
    // component is mounted, but the callback will happen after it's unmount.
    this.setState({editing: false});
  }

  blur = () => {
    this.editor.blur();
  }

  focus = () => {
    this.editor.focus();
  }

  startEditing = () => {
    this.setState({editing: true});
  }
}

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
};
