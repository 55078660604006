import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import playbook from './playbook.js';
import saveState from './saveState.js';
import session from './session.js';
import api from './api.js';
import modal from './modal.js';

const reducer = combineReducers({
  playbook,
  saveState,
  session,
  api,
  modal: modal,
  form: formReducer,
});


export default reducer;
