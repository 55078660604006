import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Helmet from "react-helmet";
import PropTypes from 'prop-types';
import _ from 'underscore';

import * as actions from '../actions.js';
import Widget from './Widget.js';
import { removeUndoState } from '../reducers/playbook.js';
import { EditIcon } from './icons.js';
import { PLAYBOOK_TYPES } from '../reducers/playbook.js';
import lifeplanProcess from '../image/process-lifeplan.svg';
import stratopProcess from '../image/process-stratop.svg';
import lifeplanLogo from '../image/logo-lifeplan.png';
import stratopLogo from '../image/logo-stratop.png';
import westernApproach from '../image/stratop-western-approach.png';
import easternApproach from '../image/stratop-eastern-approach.png';
import POAPstillLP from '../image/POAP-still-LP.png';
import POAPstillSO from '../image/POAP-still-SO.png';
import { Outline, OutlineItem } from './Outline.js';
import { BarePlanOnAPage } from './PlanOnAPage.js';
import PermissionLevel from '../PermissionLevel.js';
import RequireLogin from '../RequireLogin.js';


class Viewer extends Component {
  state = {
    showBackToTopButton: false
  }

  interval = null;

  componentDidMount() {
    const loadPlaybook = () => this.props.loadPlaybook(decodeURIComponent(this.props.match.params.id));
    loadPlaybook();
    window.addEventListener('scroll', this.handleBackToTopButton, true);
    window.addEventListener('resize', this.handleBackToTopButton);
    this.interval = setInterval(loadPlaybook, 10 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.props.clearPlaybook();
    window.addEventListener('scroll', this.handleBackToTopButton, true);
    window.addEventListener('resize', this.handleBackToTopButton);
  }

  handleBackToTopButton = _.debounce(() => {
    // clear scrolled-to state, because we're scrolling away.
    this.props.scrollToWidget(null);
    if (this.planOnAPage) {
      const poap = this.planOnAPage.getBoundingClientRect();
      this.setState({showBackToTopButton: poap.top < 0});
    }
  }, 32)

  render() {
    if (!this.props.playbook) {
      return null;
    }

    const playbook = removeUndoState(this.props.playbook);

    let POAPstill;
    if (playbook.type === 'lifeplan') {
      POAPstill = <img className="barePlanOnAPageStill" src={ POAPstillLP } alt="Plan on a Page" />;
    } else {
      POAPstill = <img className="barePlanOnAPageStill" src={ POAPstillSO } alt="Plan on a Page" />;
    }

    let preamble;
    if (playbook.type === 'lifeplan') {
      preamble = [
        <li className="playbookChart locked" key="1" ref={(e) => this.coverPage = e}>
          <div className="chart coverPage lifeplanCover">
            <h1 className="chartTitle locked">{playbook.client_name}</h1>
            <img src={lifeplanLogo} alt="Paterson LifePlan" />
          </div>
        </li>,
        <li className="playbookChart locked" key="2" ref={(e) => this.processPage = e}>
          <div className="chart lifeplanProcess">
            <p className="stickyTitle">The LifePlan Process</p>
            <h1 className="chartTitle locked">The LifePlan Process</h1>
            <img src={lifeplanProcess} alt="The LifePlan Process" />
          </div>
        </li>
      ];
    } else {
      preamble = [
        <li className="playbookChart locked" key="1" ref={(e) => this.coverPage = e}>
          <div className="chart coverPage lifeplanCover">
            <h1 className="chartTitle locked">{playbook.client_name}</h1>
            <img src={stratopLogo} alt="Paterson StratOp" />
          </div>
        </li>,
        <li className="playbookChart locked" key="2" ref={(e) => this.processPage = e}>
          <div className="chart stratopProcess">
            <p className="stickyTitle">The StratOp Process</p>
            <h1 className="chartTitle">The StratOp Process</h1>
            <img src={stratopProcess} alt="The StratOp Process" />
          </div>
        </li>,
        <li className="playbookChart locked" key="3">
          <div className="chart understandingPerspective">
            <p className="stickyTitle">Understanding Perspective</p>
            <h1 className="chartTitle">Understanding Perspective</h1>
            <h2>TYPICAL WESTERN APPROACH</h2>
            <p>
              The typical western approach is a direct frontal assault,
              sometimes a snap shot from the hip motivated by a desire for a
              speedy &ldquo;solution,&rdquo; tending to produce one-dimensional
              answers to multi-dimensional problems.
            </p>
            <img src={westernApproach} alt="The Western Approach" />
            <h2>THE PATERSON STRATOP PROCESS, AN EASTERN APPROACH</h2>
            <p>
              The eastern approach is more circuitous, ever-closing in.
              Balanced perspective through thorough situation analysis and
              diagnostics. More thoughtful, system solutions. Multi-dimensional
              answers to multi-dimensional problems. Less work later through
              better up-front planning.
            </p>
            <img src={easternApproach} alt="The Eastern Approach" />
          </div>
        </li>
      ];
    }

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return (
      <div className={(isSafari ? 'isSafari' : '')}>
        <Helmet title={`${playbook.client_name}'s ${PLAYBOOK_TYPES[playbook.type]}`} />
        <div className="playbookMeta viewerActive">
          {
            playbook.permission_level >= PermissionLevel.EDIT ?
              <Link className="edit" to={`/editor/${encodeURIComponent(playbook.id)}`}>
                <EditIcon />
                Edit Playbook
              </Link>
            :
              null
          }
        </div>

        <Outline>
          <li key="title" className="locked title">
            <a onClick={() => this.coverPage.scrollIntoView({behavior: 'smooth'})}>
              <i/>Title Page
            </a>
          </li>
          <li key="process" className="locked process">
            <a onClick={() => this.processPage.scrollIntoView({behavior: 'smooth'})}>
              <i/>The Paterson Process
            </a>
          </li>
          <li key="planOnAPage" className="locked planOnAPage">
            <a onClick={() => this.planOnAPage.scrollIntoView({behavior: 'smooth'})}>
              <i/>Plan on a Page
            </a>
          </li>
          {
            playbook.document.widgets.map((widget, i) =>
              <OutlineItem key={widget.id} widget={widget} widgetIndex={i} />
            )
          }
        </Outline>

        <ul
          className={`viewer chartList ${playbook.type}`}
          ref={(e) => e ? e.focus() : null }
          tabIndex='-1'
        >
          { preamble }

          <li
            ref={(e) => this.planOnAPage = e}
            className='scrollMarker'
          />

          <li className="playbookChart">
            <div className="chart">
              <p className="stickyTitle">Plan on a Page</p>
              <section className="editableTitleWrap">
                <h1 className="chartTitle">Plan on a Page</h1>
              </section>
              <div className="planOnAPage">
                <Link
                  className="planOnAPage-BareWrapper"
                  to={`/plan-on-a-page/${playbook.id}`}
                >
                  <BarePlanOnAPage playbook={playbook} />
                  { POAPstill }
                </Link>
                <Link
                  className="button"
                  to={`/plan-on-a-page/${playbook.id}`}
                >
                  Open Plan on a Page
                </Link>
              </div>
            </div>
          </li>

          {
            playbook.document.widgets.map((widget, i) =>
              <li key={i} className="playbookChart">
                <Widget
                  data={widget}
                  scrolledTo={this.props.playbook.document.scrolledToIndex === widget.id}
                />
              </li>
            )
          }
        </ul>

        {
          this.state.showBackToTopButton ?
            <button
              className="backToTop"
              onClick={() => this.planOnAPage.scrollIntoView({behavior: "smooth"})}
            >
              Back to Top
            </button>
          :
            null
        }
      </div>
    );
  }
}

Viewer.contextTypes = {
  router: PropTypes.object,
};

Viewer.propTypes = {
  loadPlaybook: PropTypes.func.isRequired,
  playbook: PropTypes.object,
  match: PropTypes.object.isRequired,
  clearPlaybook: PropTypes.func.isRequired,
  scrollToWidget: PropTypes.func.isRequired,
};

Viewer = RequireLogin(Viewer);

Viewer = connect(
  (state) => ({
    playbook: state.playbook,
  }),
  {
    loadPlaybook: actions.loadPlaybook,
    clearPlaybook: actions.clearPlaybook,
    scrollToWidget: actions.scrollToWidget,
  }
)(Viewer);

export default Viewer;
