import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import './PlaybooksSearch.scss';


export default class PlaybooksSearch extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const params = new URLSearchParams(new FormData(e.target));
    const search = `?${params}`;
    this.props.history.push(search);
    e.target.reset();
  };

  render() {
    const search = new URLSearchParams(this.props.location.search).get('search');
    return (
      <div className="search-form-wrapper">
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="search" className="sr-only">Search:</label>
          <input id="search" type="text" name="search" />
          <input type="submit" value="Search" />
        </form>
        {search ? (
          <div className="search-results-label">
            Showing search results for "{search}".{' '}
            <Link to="/">Clear</Link>
          </div>
        ) : null}
      </div>
    );
  }
}

PlaybooksSearch = withRouter(PlaybooksSearch);
