import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Helmet from "react-helmet";
import PropTypes from 'prop-types';

import * as actions from '../actions.js';
import RequireLogin from '../RequireLogin.js';
import * as PermissionLevel from '../PermissionLevel.js';
import PlaybooksPagination from './PlaybooksPagination.js';
import PlaybooksSearch from './PlaybooksSearch.js';


function formatDate(dateString) {
  return new Date(dateString).toLocaleString('en-US', {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
}


function formatDateTime(dateTimeString) {
  return new Date(dateTimeString).toLocaleString('en-US', {
    timeZoneName: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
}

class TranscriberHome extends Component {
  render() {
    return (
      <div className="homeWrapper">
        <Helmet title="Paterson Playbook Builder" />
        <p className="newPlaybooks">
          <Link to="/new/lifeplan" className="button">New LifePlan</Link>
          <Link to="/new/stratop" className="button">New StratOp</Link>
        </p>
        <PlaybooksSearch />
        <h1 className="tableHeader">My Playbooks</h1>
        <table className="home">
          <thead>
            <tr>
              <th>Client</th>
              <th>Facilitator</th>
              <th>Type</th>
              <th>Facilitation</th>
              <th>Created</th>
              <th>Last Modified</th>
            </tr>
          </thead>
          <tbody>
            {this.props.playbooks.map((playbook) =>
              <tr key={playbook.url}>
                <td data-label="Client">
                  <Link to={`/editor/${encodeURIComponent(playbook.id)}`}>
                    {playbook.client_name}
                  </Link>
                </td>
                <td data-label="Facilitator">{this.props.facilitatorMap[playbook.facilitator].name}</td>
                <td data-label="Type" className={ playbook.type }><strong>{ playbook.type }</strong></td>
                <td data-label="Facilitation">
                  <time dateTime={playbook.facilitation_date}>
                    {formatDate(playbook.facilitation_date)}
                  </time>
                </td>
                <td data-label="Created">
                  <time dateTime={playbook.created_at}>
                    {formatDateTime(playbook.created_at)}
                  </time>
                </td>
                <td data-label="Last Modified">
                  <time dateTime={playbook.modified_at}>
                    {formatDateTime(playbook.modified_at)}
                  </time>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <PlaybooksPagination />
      </div>
    );
  }
}
TranscriberHome.propTypes = {
  playbooks: PropTypes.array.isRequired,
  facilitatorMap: PropTypes.object.isRequired,
};
TranscriberHome = connect(
  (state) => ({
    playbooks: state.api.playbooks?.results || [],
    facilitatorMap: state.api.facilitatorMap || {},
  })
)(TranscriberHome);


class PublicHome extends Component {
  render() {
    return (
      <div className="homeWrapper">
        <Helmet title="Paterson Playbooks" />
        <PlaybooksSearch />
        <h1 className="tableHeader publicHome">My Playbooks</h1>
        <table className="home">
          <thead>
            <tr>
              <th>Client</th>
              <th>Type</th>
              <th>Facilitation</th>
              <th>Permission Level</th>
            </tr>
          </thead>
          <tbody>
            {this.props.playbooks.map((playbook) =>
              <tr key={playbook.url}>
                <td data-label="Client">
                  <Link to={`/editor/${encodeURIComponent(playbook.id)}`}>
                    {playbook.client_name}
                  </Link>
                </td>
                <td data-label="Type" className={ playbook.type }><strong>{ playbook.type }</strong></td>
                <td data-label="Facilitation">
                  <time dateTime={playbook.facilitation_date}>
                    {formatDate(playbook.facilitation_date)}
                  </time>
                </td>
                <td data-label="Permission">
                  <span className={`level level-${playbook.permission_level}`}>{PermissionLevel.LABELS[playbook.permission_level]}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <PlaybooksPagination />
      </div>
    );
  }
}

PublicHome.propTypes = {
  playbooks: PropTypes.array.isRequired,
};

PublicHome = connect(
  (state) => ({
    playbooks: state.api.playbooks?.results || [],
  }),
)(PublicHome);

class Home extends Component {
  componentDidMount() {
    this.props.loadPlaybooks(this.props.location.search);
  }

  componentDidUpdate(prev) {
    if (this.props.location.search !== prev.location.search) {
      this.props.loadPlaybooks(this.props.location.search);
    }
  }

  render() {
    if (this.props.session.user.add_playbook) {
      return <TranscriberHome />;
    } else {
      return <PublicHome />;
    }
  }
}
Home.propTypes = {
  session: PropTypes.object.isRequired,
  loadPlaybooks: PropTypes.func.isRequired,
};

Home = RequireLogin(Home);

Home = connect((state) => ({
  session: state.session,
}),{
  loadPlaybooks: actions.loadPlaybooks,
})(Home);
Home = withRouter(Home);

export default Home;
