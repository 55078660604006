import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import EditableTitle from '../EditableTitle';
import DraggableInputChartBase from './DraggableInputChartBase.js';
import './OpportunityRiskAnalysis.scss';


export default class OpportunityRiskAnalysis extends DraggableInputChartBase {
  render() {
    return (
      <div className="chart">
        <p className="stickyTitle">{this.props.data.title}</p>
        <EditableTitle
          data={this.props.data}
          onEditData={this.props.onEditData}
        />
        <div className="dragInner">
          <div
            className={classNames('opportunityRiskAnalysis', {dragging: this.state.isDragging})}
          >
            <div
              onClick={this.props.onEditData ? this.addItem : null}
              ref={(e) => this.canvas = e}
              className="canvas">

              <div className="axis axisRisk">
                <span className="label">RISK</span>
                <span className="low">LOW</span>
                <span className="high">HIGH</span>
              </div>

              <div className="axis axisOpportunity">
                <span className="label">OPPORTUNITY</span>
                <span className="low">LOW</span>
                <span className="high">HIGH</span>
              </div>

              <div className="opportunityRiskAnalysisInner">
                <svg viewBox="0 0 1000 1000">

                  <defs>
                    <radialGradient id="ZoneGradient">
                      <stop offset="0%" stopColor="#83888F" stopOpacity="0" />
                      <stop offset="100%" stopColor="#83888F" stopOpacity="0.25" />
                    </radialGradient>
                    <path id="textPathFoolishness"
                      d="M 0 0 m -434, 0 a 434,434 0 1,0 868,0 a 434,434 0 1,0 -868,0" />
                    <path id="textPathEntrepreneurial"
                      d="M 1000 0 m -434, 0 a 434,434 0 1,0 868,0 a 434,434 0 1,0 -868,0" />
                    <path id="textPathGoldMine"
                      d="M 1000 1000 m -416,0 a 416,416 0 0,1 416,-416" />
                    <path id="textPathSecurity"
                      d="M 0 1000 m 0,-416 a 416,416 0 0,1 416,416" />

                    <path id="textPathHoHumTopLeft"
                      d="M 500 500 m -166,0 a 166,-166 0 0,1 166,-166" />
                    <path id="textPathHoHumTopRight"
                      d="M 500 500 m 0,-166 a 166,166 0 0,1 166,166" />
                    <path id="textPathHoHumBottomRight"
                      d="M 500 500 m 0,184 a 184,-184 0 0,0 184,-184" />
                    <path id="textPathHoHumBottomLeft"
                      d="M 500 500 m 0,166 a -166,-166 0 0,1 -166,-166" />
                  </defs>


                  <line x1="0" y1="0" x2="100%" y2="100%" stroke="#CCAE53" strokeWidth="2" />
                  <line x1="100%" y1="0" x2="0" y2="100%" stroke="#CCAE53" strokeWidth="2" />

                  {/* Foolishness */}
                  <circle cx="0" cy="0" r="425" strokeWidth="50" stroke="#FFFFFF" fill="transparent" />
                  <circle cx="0" cy="0" r="400" strokeWidth="2" stroke="#83888F" fill="url(#ZoneGradient)" />
                  <circle cx="0" cy="0" r="450" strokeWidth="2" stroke="#83888F" fill="transparent" />

                  <text fontSize="24" fontWeight="bold" fill="#83888F">
                    <textPath xlinkHref="#textPathFoolishness">
                      F O O L I S H N E S S &nbsp; F O O L I S H N E S S &nbsp; F
                      O O L I S H N E S S &nbsp; F O O L I S H N E S S &nbsp; F O
                      O L I S H N E S S &nbsp; F O O L I S H N E S S &nbsp; F O O
                      L I S H N E S S &nbsp; F O O L I S H N E S S &nbsp; F O O L
                      I S H N E S S &nbsp; F O O L I S H N E S S &nbsp; F O O L I
                      S H N E S S &nbsp; F O O L I S H N E S S &nbsp; F O O L I S
                      H N E S S &nbsp;
                    </textPath>
                  </text>

                  {/* Entrepreneurial */}
                  <circle cx="1000" cy="0" r="425" strokeWidth="50" stroke="#FFFFFF" fill="transparent" />
                  <circle cx="1000" cy="0" r="400" strokeWidth="2" stroke="#83888F" fill="url(#ZoneGradient)" />
                  <circle cx="1000" cy="0" r="450" strokeWidth="2" stroke="#83888F" fill="transparent" />
                  <text fontSize="24" fontWeight="bold" textAnchor="middle" fill="#83888F">
                    <textPath xlinkHref="#textPathEntrepreneurial">
                      E N T R E P R E N E U R I A L &nbsp; E N T R E P R E N E U
                      R I A L &nbsp; E N T R E P R E N E U R I A L &nbsp; E N T R
                      E P R E N E U R I A L &nbsp; E N T R E P R E N E U R I A L
                      &nbsp; E N T R E P R E N E U R I A L &nbsp; E N T R E P R E
                      N E U R I A L &nbsp; E N T R E P R E N E U R I A L &nbsp; E
                      N T R E P R E N E U R I A L &nbsp; E N T R E P R E N E U R
                      I A L &nbsp;
                    </textPath>
                  </text>

                  {/* Gold Mine */}
                  <circle cx="1000" cy="1000" r="425" strokeWidth="50" stroke="#FFFFFF" fill="transparent" />
                  <circle cx="1000" cy="1000" r="400" strokeWidth="2" stroke="#83888F" fill="url(#ZoneGradient)" />
                  <circle cx="1000" cy="1000" r="450" strokeWidth="2" stroke="#83888F" fill="transparent" />
                  <text fontSize="24" fontWeight="bold" textAnchor="middle" fill="#83888F">
                    <textPath xlinkHref="#textPathGoldMine">
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                      G O L D &nbsp; M I N E &nbsp; G O L D &nbsp; M I N E &nbsp;
                    </textPath>
                  </text>

                  {/* Security */}
                  <circle cx="0" cy="1000" r="425" strokeWidth="50" stroke="#FFFFFF" fill="transparent" />
                  <circle cx="0" cy="1000" r="400" strokeWidth="2" stroke="#83888F" fill="url(#ZoneGradient)" />
                  <circle cx="0" cy="1000" r="450" strokeWidth="2" stroke="#83888F" fill="transparent" />
                  <text fontSize="24" fontWeight="bold" textAnchor="middle" fill="#83888F">
                    <textPath xlinkHref="#textPathSecurity">
                      S E C U R I T Y &nbsp; S E C U R I T Y &nbsp; S E C U R I T
                      Y &nbsp; S E C U R I T Y &nbsp; S E C U R I T Y &nbsp; S E
                      C U R I T Y &nbsp; S E C U R I T Y &nbsp; S E C U R I T Y
                      &nbsp; S E C U R I T Y &nbsp; S E C U R I T Y &nbsp; S E C
                      U R I T Y &nbsp; S E C U R I T Y &nbsp; S E C U R I T Y
                      &nbsp; S E C U R I T Y &nbsp; S E C U R I T Y &nbsp; S E C
                      U R I T Y &nbsp; S E C U R I T Y &nbsp; S E C U R I T Y
                      &nbsp; S E C U R I T Y &nbsp; S E C U R I T Y &nbsp; S E C
                      U R I T Y &nbsp; S E C U R I T Y &nbsp; S E C U R I T Y
                      &nbsp; S E C U R I T Y &nbsp; S E C U R I T Y &nbsp; S E C
                      U R I T Y &nbsp; S E C U R I T Y &nbsp; S E C U R I T Y
                      &nbsp; S E C U R I T Y &nbsp; S E C U R I T Y &nbsp;
                    </textPath>
                  </text>

                  {/* Ho Hum */}
                  <circle cx="500" cy="500" r="175" strokeWidth="50" stroke="#FFFFFF" fill="transparent" />
                  <circle cx="500" cy="500" r="150" strokeWidth="2" stroke="#83888F" fill="url(#ZoneGradient)" />
                  <circle cx="500" cy="500" r="200" strokeWidth="2" stroke="#83888F" fill="transparent" />

                  <text
                    fontSize="24"
                    fontWeight="bold"
                    textAnchor="middle"
                    fill="#83888F"
                    transform="translate(500,-207) rotate(45)"
                  >
                    <textPath xlinkHref="#textPathHoHumTopLeft" startOffset="50%">
                      H O &nbsp; H U M
                    </textPath>
                    <textPath xlinkHref="#textPathHoHumTopRight" startOffset="50%">
                      H O &nbsp; H U M
                    </textPath>
                    <textPath xlinkHref="#textPathHoHumBottomRight" startOffset="50%">
                      H O &nbsp; H U M
                    </textPath>
                    <textPath xlinkHref="#textPathHoHumBottomLeft" startOffset="50%">
                      H O &nbsp; H U M
                    </textPath>
                  </text>

                  {/* Border */}
                  <rect x="0" y="0" width="100%" height="100%" stroke="#CCAE53" strokeWidth="4" fill="transparent" />
                </svg>
              </div>
            </div>

            {
              this.renderItems()
            }

            <div className="small">
              <div className="highRisk lowReward">
                <h2>Foolishness</h2>
                <h3>High Risk &mdash; Low Reward</h3>
                {
                  this.renderSmallItems((item) =>
                    item.risk > 0.6 && item.opportunity < 0.4
                  )
                }
              </div>
              <div className="highRisk highReward">
                <h2>Entrepreneurial</h2>
                <h3>High Risk &mdash; High Reward</h3>
                {
                  this.renderSmallItems((item) =>
                    item.risk > 0.6 && item.opportunity > 0.6
                  )
                }
              </div>
              <div className="lowRisk lowReward">
                <h2>Security</h2>
                <h3>Low Risk &mdash; Low Reward</h3>
                {
                  this.renderSmallItems((item) =>
                    item.risk < 0.4 && item.opportunity < 0.4
                  )
                }
              </div>
              <div className="lowRisk highReward">
                <h2>Gold Mine</h2>
                <h3>Low Risk &mdash; High Reward</h3>
                {
                  this.renderSmallItems((item) =>
                    item.risk < 0.4 && item.opportunity > 0.6
                  )
                }
              </div>
              <div>
                <h2>Ho Hum</h2>
                {
                  this.renderSmallItems((item) =>
                    item.risk >= 0.4 && item.risk <= 0.6 &&
                    item.opportunity >= 0.4 && item.opportunity <= 0.6
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  mouseCoordinatesToData = (x, y) => {
    const canvasBB = this.canvas.getBoundingClientRect();
    const canvasX = (x - canvasBB.left) / canvasBB.width;
    const canvasY = (canvasBB.bottom - y) / canvasBB.height;

    const p1 = {
      x: (canvasX + canvasY) / 2,
      y: (canvasX + canvasY) / 2,
    };

    const p2 = {
      x: (1 + canvasX - canvasY) / 2,
      y: 1 - (1 + canvasX - canvasY) / 2,
    };

    const d1 = Math.sqrt(Math.pow(p1.x - canvasX, 2) + Math.pow(p1.y - canvasY, 2));
    const d2 = Math.sqrt(Math.pow(p2.x - canvasX, 2) + Math.pow(p2.y - canvasY, 2));

    const closest = d1 < d2 ? p1 : p2;

    return {
      risk: Math.min(Math.max(closest.y, 0), 1),
      opportunity: Math.min(Math.max(closest.x, 0), 1),
    };
  }

  getItemProps = (itemIndex) => {
    const item = this.state.data.items[itemIndex];

    return {
      className: classNames('item', {
        highRisk: item.risk > 0.6,
        lowRisk: item.risk < 0.4,
        highOpportunity: item.opportunity > 0.6,
        lowOpportunity: item.opportunity < 0.4,
      }),
      style: {
        top: `${(1 - item.risk) * 100}%`,
        left: `${item.opportunity * 100}%`,
      },
    };
  }

  renderSmallItems = (cond) => {
    return (
      <ul>
        {
          this.state.data.items.filter(cond).map((item, i) =>
            <li key={i}>{item.name}</li>
          )
        }
      </ul>
    );
  }
}

OpportunityRiskAnalysis.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
