import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'underscore';

import RichTextEditor from '../RichTextEditor.js';
import { StrategicDashboardInner } from '../widgets/StrategicDashboard.js';
import { Section } from './lifeplan.js';
import { GenericTableSection } from './lifeplan.js';
import { CoreValues } from './lifeplan.js';
import ActionInitiativesProfileNormal from '../widgets/ActionInitiativesProfile.js';
import ValueBuildingCycleNormal from '../widgets/ValueBuildingCycle.js';
import stratopLogo from '../../image/logo-stratop-black-horizontal.png';



class OurVision extends GenericTableSection {
  widgetType = 'OurVision'

  /**
   * This widget shows its data in a column-major format instead of row-major
   * like a normal table.
   */
  renderInner(data) {
    const rowCount = data.rows.length;
    const columnCount = rowCount ? data.rows[0].length : 0;

    return (
      <div>
        {
          _.range(columnCount).map((i) =>
            <div key={i} className={classNames('column', {
              highlighted: data.highlightedColumns[i],
            })}>
              {
                _.range(rowCount).map((j) =>
                  <div key={j}>
                    <RichTextEditor value={data.rows[j][i]} />
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    );
  }
}


class CoreAssumptions extends GenericTableSection {
  widgetType = 'CoreAssumptions'
}


class OurBigIdeaCoreStrategies extends GenericTableSection {
  widgetType = 'OurBigIdeaCoreStrategies'
}


class OurMission extends Section {
  widgetType = 'OurMission'

  renderInner(data) {
    return (
      <span>{data.content}</span>
    );
  }
}


class ValueBuildingCycle extends Section {
  widgetType = 'ValueBuildingCycle'

  renderInner(data) {
    return <ValueBuildingCycleNormal data={data} />;
  }
}


class ActionInitiativesProfile extends Section {
  widgetType = 'ActionInitiativesProfile'

  renderInner(data) {
    return (
      <ActionInitiativesProfileNormal data={data} />
    );
  }
}


class StrategicDashboard extends Section {
  widgetType = 'StrategicDashboard'

  renderInner(data) {
    return <StrategicDashboardInner data={data} />;
  }
}

export default function StratOpPlanOnAPage({widgets, client_name}) {

  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <div className={"planOnAPage-Wrapper " + (isSafari ? 'isSafari' : '')}>
      <div className='planOnAPage-Poster planOnAPage-Stratop'>
        <h1 className='chartTitle'><img src={ stratopLogo } alt="StratOp" /> Plan on a Page &mdash; { client_name }</h1>
        <div className="left">
          <CoreAssumptions widgets={widgets} />
          <ValueBuildingCycle widgets={widgets} />
          <CoreValues widgets={widgets} />
          <OurBigIdeaCoreStrategies widgets={widgets} />
        </div>
        <div className="center">
          <OurMission widgets={widgets} />
          <OurVision widgets={widgets} />
        </div>
        <div className="right">
          <StrategicDashboard widgets={widgets} />
          <ActionInitiativesProfile widgets={widgets} />
        </div>
      </div>
    </div>
  );
}

StratOpPlanOnAPage.propTypes = {
  widgets: PropTypes.array.isRequired,
  client_name: PropTypes.string.isRequired,
};
