import React, { Component } from 'react';
import classNames from 'classnames';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';

import * as keycodes from '../keycodes.js';

export default class BaseStatusIndicator extends Component {
  state = {
    open: false,
  }

  render() {
    let { value, onChange, altTexts, nullable} = this.props;

    /* :hover while dragging does not work in chrome:
     * https://bugs.chromium.org/p/chromium/issues/detail?id=122746 so we have
     * to do this mouseenter/mouseleave thing
     */
    return (
      <ReactCSSTransitionGroup
        component="div"
        className={classNames(
          this.getStatusClassNames(value),
          this.className,
          {open : this.state.open}
        )}
        onMouseDown={onChange ? this.onMouseDown : null}
        ref={(elem) => this.elem = findDOMNode(elem)}
        title={!this.state.open ? altTexts[value] : null}
        tabIndex="0"
        onFocus={onChange ? () => this.setState({open: true}) : null}
        onBlur={onChange ? () => this.setState({open: false}) : null}
        onKeyDown={onChange ? this.onKeyDown : null}
        role="button"
        transitionName="open"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {
          (this.state.open ? this.STATUSES : []).map((status) =>
            status === null && !nullable ?
              <span
                key={status}
                className='status-null disabled'
              />
            :
              <span
                className={classNames(this.getStatusClassNames(status), {
                  hover: this.state.hoveredStatus === status,
                  active: value === status,
                })}
                key={status}
                onMouseUp={() => this.onMouseUp(status)}
                onMouseEnter={() => this.setState({hoveredStatus: status})}
                onMouseLeave={() => this.setState({hoveredStatus: undefined})}
                title={altTexts[status]}
                role="button"
              />
          )
        }
      </ReactCSSTransitionGroup>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      this.props.value === nextProps.value &&
      !!this.props.onChange === !!nextProps.onChange &&
      this.state.open === nextState.open
    );
  }

  onMouseDown = (event) => {
    if (event.button === 0) {
      this.elem.focus();
      event.preventDefault();
      // don't bubble up to the documentMouseDownHandler we're about to register
      event.stopPropagation();
      this.setState({
        open: true,
      });
      document.addEventListener('mousedown', this.documentMouseDownHandler);
    }
  }

  onKeyDown = (event) => {
    if (event.keyCode === keycodes.UP_ARROW || event.keyCode === keycodes.DOWN_ARROW) {
      event.preventDefault();

      const direction = event.keyCode === keycodes.UP_ARROW ? 1 : -1;
      let nextStatusIndex = this.STATUSES.indexOf(this.props.value) + direction;
      nextStatusIndex = nextStatusIndex % this.STATUSES.length;

      if (nextStatusIndex === 0 && !this.props.nullable) {
        nextStatusIndex = nextStatusIndex + direction;
      }

      nextStatusIndex = nextStatusIndex < 0 ? this.STATUSES.length - 1 : nextStatusIndex;

      this.props.onChange(this.STATUSES[nextStatusIndex]);
    }
    else if (event.keyCode === keycodes.ESCAPE || event.keyCode === keycodes.ENTER) {
      this.elem.blur();
    }
  }

  documentMouseDownHandler = (event) => {
    // we need to close whenever something other than one of our own buttons is
    // clicked.
    if (event.target.parentNode !== this.elem) {
      this.setState({
        open: false,
      });

      this.elem.blur();
    }
    document.removeEventListener('mousedown', this.documentMouseDownHandler);
  }

  onMouseUp = (status) => {
    this.setState({
      open: false,
      hoveredStatus: undefined,
    });
    this.props.onChange(status);
    document.removeEventListener('mousedown', this.documentMouseDownHandler);
    this.elem.blur();
  }
}


BaseStatusIndicator.defaultProps = {
  nullable: true,
};

BaseStatusIndicator.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  altTexts: PropTypes.object,
  nullable: PropTypes.bool,
};
