import _ from 'underscore';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import PropTypes from 'prop-types';

import * as actions from '../actions.js';
import RenderFormInput from './RenderFormInput.js';
import { isValidEmail } from '../utils.js';

import 'react-select/dist/react-select.css';
import 'react-dates/lib/css/_datepicker.css';


const REQUIRED = 'This field is required';


function validate(values) {
  const errors = {};

  if (!values.client_name.trim()) {
    errors.client_name = REQUIRED;
  }

  if (!values.client_email.trim()) {
    errors.client_email = REQUIRED;
  } else if (!isValidEmail(values.client_email)) {
    errors.client_email = 'This must be a valid email';
  }

  if (!values.facilitation_date.trim()) {
    errors.facilitation_date = REQUIRED;
  } else if (!/\d{4}-\d{2}-\d{2}/.test(values.facilitation_date)) {
    errors.facilitation_date = 'This field must be a date';
  }

  if (!values.facilitator) {
    errors.facilitator = REQUIRED;
  }

  return errors;
}


function FacilitatorSelect({ facilitators, input, label, meta: { touched, error } }) {
  const options = facilitators.map((f) => (
    {
      value: f.url,
      label: `${f.name} <${f.email}>`,
    }
  ));

  return (
    <div>
      <label>
        {label}
        <Select
          {...input}
          clearable={false}
          options={options}
          onChange={({ value }) => input.onChange(value)}
          /* https://github.com/erikras/redux-form/issues/82 */
          onBlur={() => input.onBlur()}
        />
      </label>
      {touched && error && <span className="error">{error}</span>}
    </div>
  );
}
FacilitatorSelect.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  facilitators: PropTypes.array.isRequired,
};


class FacilitationDate extends Component {
  state = {
    focused: false,
  }

  render() {
    const { label, input: {value, onChange}, meta: {touched, error} } = this.props;

    return (
      <div>
        <label>
          {label}
          <SingleDatePicker
            date={value ? moment(value) : null}
            onDateChange={(d) => onChange(d ? d.format('YYYY-MM-DD') : '')}
            onFocusChange={({ focused }) => this.setState({focused})}
            focused={this.state.focused}
            displayFormat="LL"
            placeholder=""
            isOutsideRange={() => false}
          />
        </label>
        {touched && error && <span className="error">{error}</span>}
      </div>
    );
  }
}

FacilitationDate.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};


let MetadataForm = ({ handleSubmit, onCancel, facilitators, submitText, error }) => {
  return (
    <form className="metadataForm" onSubmit={handleSubmit}>
      <h2 className="formTitle">Playbook Metadata</h2>
      <Field
        name="client_name"
        component={RenderFormInput}
        type="text"
        label="Client's Name"
      />
      <fieldset className="facilitationDateWrap">
        <Field
          name="facilitation_date"
          component={FacilitationDate}
          type="date"
          label="Date of Facilitation"
        />
      </fieldset>
      <Field
        name="client_email"
        component={RenderFormInput}
        type="email"
        label="Client's Email"
      />
      <Field
        name="facilitator"
        component={FacilitatorSelect}
        facilitators={facilitators}
        label="Facilitator"
      />
      <Field
        name="client_organization"
        component={RenderFormInput}
        type="text"
        label="Client's Organization (Optional)"
      />
      <div>
        <label>
          Notes (Optional)
          <Field name="notes" component="textarea" />
        </label>
      </div>
      {error && <span className="error">{error}</span>}
      <div>
        <button type="submit">
          {submitText}
        </button>
        <button type="button" className="cancel" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </form>
  );
};
MetadataForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  facilitators: PropTypes.array.isRequired,
  submitText: PropTypes.string.isRequired,
  error: PropTypes.string,
};

MetadataForm = reduxForm({
  form: 'metadata',
  validate,
})(MetadataForm);



class MetadataEditor extends Component {
  componentDidMount() {
    this.props.loadFacilitators();
  }

  render() {
    let { playbook } = this.props;

    if (!playbook) {
      return null;
    }

    let metadataValues = _.pick(playbook, [
      'client_name', 'facilitation_date', 'client_email', 'facilitator',
      'client_organization', 'notes',
    ]);

    return (
      <MetadataForm
        initialValues={metadataValues}
        onSubmit={this.props.onFormSubmit}
        onCancel={this.props.onCancel}
        facilitators={this.props.facilitators}
        submitText={this.props.submitText}
      />
    );
  }
}
MetadataEditor.propTypes = {
  playbook: PropTypes.object,
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  facilitators: PropTypes.array.isRequired,
  loadFacilitators: PropTypes.func.isRequired,
};

MetadataEditor = connect(
  (state) => ({
    facilitators: state.api.facilitators || [],
  }),
  {
    loadFacilitators: actions.loadFacilitators,
  }
)(MetadataEditor);

export default MetadataEditor;
