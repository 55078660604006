import React from 'react';
import PropTypes from 'prop-types';


export default function RenderFormInput({ input, label, type, meta: { touched, error } }) {
  return (
    <div>
      <label>
        {label}
        <input {...input} type={type} />
      </label>
      {touched && error && <span className="error">{error}</span>}
    </div>
  );
}

RenderFormInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};
