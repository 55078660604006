function session(state={status: 'loading'}, action) {
  switch (action.type) {
    case 'SET_SESSION_STATUS':
      if (action.user) {
        return {status: 'authenticated', user: action.user};
      } else {
        return {status: 'unauthenticated'};
      }
    default:
      return state;
  }
}

export default session;
