import _ from 'underscore';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditableTitle from '../EditableTitle';
import { clamp } from '../../utils.js';

import './ThinkingWavelength.scss';


class ThinkingWavelengthBase extends Component {
  GRAPH_WIDTH = 870;
  GRAPH_OFFSET = 44;
  LEVEL_BASE = 118;
  LEVEL_OFFSET = 100;

  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      isDragging: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.state, nextState);
  }

  height() {
    return 300;
  }

  numLevels() {
    return 1;
  }

  render() {
    let mobileVersion;
    if (!this.props.onEditData) {
      mobileVersion = (
        <svg
          viewBox="0 0 1000 1400"
          width="1000"
          height="1400"
          preserveAspectRatio="xMinYMin meet"
          ref={(svg) => this.svg = svg} className={this.state.isDragging ? 'dragging mobile' : 'mobile'}
        >
          <title>Thinking Wavelength</title>
          <g
            transform={`translate(${20 + this.GRAPH_OFFSET}, 0)`}
            ref={(graph) => this.graph = graph}
            className="graph"
          >
            { this.renderInner() }

            <g className="waves">
              <rect
                y="255"
                x="-50"
                width={2 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET + 100}
                height="135"
                fill="url(#MobileFillGradient)"
              />
              <rect
                y="390"
                x={2 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET - 50}
                width={2 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET + 100}
                height="135"
                fill="url(#MobileFillGradient)"
              />
              <rect
                y="525"
                x={4 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET - 50}
                width={2 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET + 100}
                height="135"
                fill="url(#MobileFillGradient)"
              />
              <rect
                y="660"
                x={6 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET - 50}
                width={2 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET + 100}
                height="135"
                fill="url(#MobileFillGradient)"
              />
              <rect
                y="795"
                x={8 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET - 50}
                width={2 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET + 100}
                height="135"
                fill="url(#MobileFillGradient)"
              />
              <rect
                y="255"
                x="-50"
                width={2 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET + 100}
                height="4"
                fill="url(#MobileLineGradientWide)"
              />
              <rect
                y="390"
                x="-50"
                width={4 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET + 100}
                height="4"
                fill="url(#MobileLineGradientWide)"
              />
              <rect
                y="525"
                x={2 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET - 50}
                width={4 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET + 100}
                height="4"
                fill="url(#MobileLineGradientWide)"
              />
              <rect
                y="660"
                x={4 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET - 50}
                width={4 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET + 100}
                height="4"
                fill="url(#MobileLineGradientWide)"
              />
              <rect
                y="795"
                x={6 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET - 50}
                width={4 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET + 100}
                height="4"
                fill="url(#MobileLineGradientWide)"
              />
              <rect
                y="930"
                x={8 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET - 50}
                width={2 * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET + 100}
                height="4"
                fill="url(#MobileLineGradientWide)"
              />
            </g>
            <g transform={`translate(${-20 - this.GRAPH_OFFSET}, -20)`} className="waveLabels">
              <text x="150" y="205" textAnchor="middle" fontSize="18" fontWeight="bold" fill="#67224C">
                GRINDERS
              </text>
              <text x="325" y="205" textAnchor="middle" fontSize="18" fontWeight="bold" fill="#67224C">
                MINDERS
              </text>
              <text x="500" y="205" textAnchor="middle" fontSize="18" fontWeight="bold" fill="#67224C">
                KEEPERS
              </text>
              <text x="675" y="205" textAnchor="middle" fontSize="18" fontWeight="bold" fill="#67224C">
                FINDERS
              </text>
              <text x="850" y="205" textAnchor="middle" fontSize="18" fontWeight="bold" fill="#67224C">
                CONCEIVERS
              </text>
            </g>

            <rect
              transform={`translate(${-this.GRAPH_OFFSET}, 0)`}
              x="-20"
              y="20"
              width="1000"
              height="30"
              rx="15"
              ry="15"
              fill="url(#ThinkingWavelengthGradient)"
              className="scoreBackground"
            />
            {
              _.range(11).map(i => (
                <text
                  key={i}
                  x={i * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET}
                  y="40"
                  textAnchor="middle"
                  fontSize="18"
                  fontWeight="bold"
                  fill="#ffffff"
                  className="score"
                >
                  {i}
                </text>
              ))
            }
          </g>
        </svg>
      );
    } else {
      mobileVersion = null;
    }

    return (
      <div className="chart" ref={(e) => this.chartElem = e} style={{position: "relative"}}>
        <p className="stickyTitle">{this.props.data.title}</p>
        <EditableTitle
          data={this.props.data}
          onEditData={this.props.onEditData}
        />
        <div className="thinkingWavelength">
          <div className="legend">
            <div>
              <h2>Administrative / <br />Operational</h2>
              <span className="line" />
              <p>
                Risk Averse<br />
                Resists Change<br />
                Few Variables<br />
                One Step at a Time<br />
                Low Tolerance for Ambiguity<br />
                Problem / Task-Oriented<br />
                Concrete
              </p>
            </div>
            <div>
              <h2>Sees / Appreciates<br />Both Sides</h2>
            </div>
            <div>
              <h2>Strategic / <br />Developmental</h2>
              <span className="line" />
              <p>
                Embraces Risk<br />
                Welcomes Change<br />
                Many Variables<br />
                Quantum Leaps<br />
                High Tolerance for Ambiguity<br />
                Opportunity-Oriented<br />
                Abstract
              </p>
            </div>
          </div>
          <svg className="defsOnly" width="0" height="0" viewBox="0 0 0 0">
            <defs>
              <marker id="tick" markerHeight="6" refX="0" refY="3">
                <line x1="0" y1="0" x2="0" y2="10" strokeWidth="2" stroke="red"/>
              </marker>
              <linearGradient id="WaveFillGradient" x1="0" x2="1" y1="0" y2="0">
                <stop offset="10%" stopColor="#FFFFFF" stopOpacity="0"/>
                <stop offset="15%" stopColor="#FFFFFF" stopOpacity="1"/>
                <stop offset="85%" stopColor="#FFFFFF" stopOpacity="1"/>
                <stop offset="90%" stopColor="#FFFFFF" stopOpacity="0"/>
              </linearGradient>
              <linearGradient id="ThinkingWavelengthGradient" x1="0" x2="1" y1="0" y2="0">
                <stop offset="0%" stopColor="#67224C" stopOpacity="1"/>
                <stop offset="100%" stopColor="#A04783" stopOpacity="1"/>
              </linearGradient>
              <linearGradient id="WaveGradient" x1="0" x2="1" y1="0" y2="0">
                <stop offset="13%" stopColor="#67224C" stopOpacity="0"/>
                <stop offset="14%" stopColor="#67224C" stopOpacity="1"/>
                <stop offset="86%" stopColor="#A04783" stopOpacity="1"/>
                <stop offset="87%" stopColor="#A04783" stopOpacity="0"/>
              </linearGradient>
              <linearGradient id="MobileLineGradientWide" x1="0%" x2="100%" y1="0%" y2="0%">
                <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0"/>
                <stop offset="10%" stopColor="#67224C" stopOpacity="0"/>
                <stop offset="25%" stopColor="#67224C" stopOpacity="1"/>
                <stop offset="75%" stopColor="#67224C" stopOpacity="1"/>
                <stop offset="90%" stopColor="#67224C" stopOpacity="0"/>
                <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0"/>
              </linearGradient>
              <linearGradient id="MobileFillGradient" x1="0%" x2="100%" y1="0%" y2="0%">
                <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0"/>
                <stop offset="33%" stopColor="#FFFFFF" stopOpacity="1"/>
                <stop offset="66%" stopColor="#FFFFFF" stopOpacity="1"/>
                <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0"/>
              </linearGradient>
            </defs>
          </svg>
          <svg
            viewBox="0 0 1000 300"
            width="1000"
            height={this.height()}
            preserveAspectRatio="xMinYMin meet"
            ref={(svg) => this.svg = svg}
            className={this.state.isDragging ? 'dragging desktop' : 'desktop'}
            onClick={this.onSvgClick}
            onMouseDown={this.onSvgMouseDown}
          >
            <title>Thinking Wavelength</title>
            <g
              transform={`translate(${20 + this.GRAPH_OFFSET}, 0)`}
              ref={(graph) => this.graph = graph}
              className="graph"
            >
              { this.renderInner() }

              <g
                transform={`translate(${-130 - this.GRAPH_OFFSET}, ${this.LEVEL_BASE + this.LEVEL_OFFSET * (this.numLevels() - 1)}), scale(0.87)`}
                className="waves"
                >
                <path
                  d="M0 200 Q 100 85, 200 200 T 400 200 T 600 200 T 800 200 T 1000 200 T 1200 200 T 1400 200"
                  fill="url(#WaveFillGradient)"
                  stroke="url(#WaveGradient)"
                  strokeWidth="2"
                />
                <path
                  d="M0 200 Q 100 315, 200 200 T 400 200 T 600 200 T 800 200 T 1000 200 T 1200 200 T 1400 200"
                  fill="url(#WaveFillGradient)"
                  stroke="url(#WaveGradient)"
                  strokeWidth="2"
                />
              </g>
              <g
                transform={`translate(${-20 - this.GRAPH_OFFSET}, ${this.LEVEL_BASE + this.LEVEL_OFFSET * (this.numLevels() - 1) - 25})`}
                className="waveLabels"
                >
                <text x="150" y="205" textAnchor="middle" fontSize="18" fontWeight="bold" fill="#67224C">
                  GRINDERS
                </text>
                <text x="325" y="205" textAnchor="middle" fontSize="18" fontWeight="bold" fill="#67224C">
                  MINDERS
                </text>
                <text x="500" y="205" textAnchor="middle" fontSize="18" fontWeight="bold" fill="#67224C">
                  KEEPERS
                </text>
                <text x="675" y="205" textAnchor="middle" fontSize="18" fontWeight="bold" fill="#67224C">
                  FINDERS
                </text>
                <text x="850" y="205" textAnchor="middle" fontSize="18" fontWeight="bold" fill="#67224C">
                  CONCEIVERS
                </text>
              </g>

              <rect
                transform={`translate(${-this.GRAPH_OFFSET}, 0)`}
                x="-20"
                y="20"
                width="1000"
                height="30"
                rx="15"
                ry="15"
                fill="url(#ThinkingWavelengthGradient)"
                className="scoreBackground"
              />
              {
                _.range(11).map(i => (
                  <text
                    key={i}
                    x={i * this.GRAPH_WIDTH/10 + this.GRAPH_WIDTH/20 - this.GRAPH_OFFSET}
                    y="40"
                    textAnchor="middle"
                    fontSize="18"
                    fontWeight="bold"
                    fill="#ffffff"
                    className="score"
                  >
                    {i}
                  </text>
                ))
              }
            </g>
          </svg>

          { mobileVersion }
          { this.renderAdditionalStuff() }
        </div>
      </div>
    );
  }

  mouseEventToThinkingWavelength(event) {
    const pt = this.svg.createSVGPoint();
    pt.x = event.clientX;
    pt.y = event.clientY;
    const svgPoint = pt.matrixTransform(this.graph.getScreenCTM().inverse());
    const newValue = (svgPoint.x - (this.state.dragOffset || 0)) / this.GRAPH_WIDTH * 10;
    return clamp(newValue, 0, 10);
  }

  mouseEventToLevel(event) {
    const pt = this.svg.createSVGPoint();
    pt.x = event.clientX;
    pt.y = event.clientY;
    const svgPoint = pt.matrixTransform(this.graph.getScreenCTM().inverse());
    return clamp(Math.round((svgPoint.y - this.LEVEL_BASE) / this.LEVEL_OFFSET), 0, this.numLevels());
  }

  startDrag = (event, dragElem, updateData) => {
    if (event.button !== 0) {
      return;
    }
    if (!this.props.onEditData) {
      return;
    }

    event.preventDefault();
    const pt = this.svg.createSVGPoint();
    pt.x = event.clientX;
    pt.y = event.clientY;
    const svgPoint = pt.matrixTransform(dragElem.getScreenCTM().inverse());
    this.setState({
      dragOffset: svgPoint.x,
      isDragging: true,
      didDrag: false,
    });

    const mousemove = (event) => {
      event.preventDefault();

      this.setState({
        data: {
          ...this.state.data,
          ...updateData(
            this.mouseEventToThinkingWavelength(event),
            this.mouseEventToLevel(event)
          )
        },
        didDrag: true,
      });
    };

    const mouseup = () => {
      document.removeEventListener("mousemove", mousemove);
      document.removeEventListener("mouseup", mouseup);
      this.props.onEditData(this.state.data);
      this.setState({
        isDragging: false,
        dragOffset: undefined,
      });
    };

    document.addEventListener("mousemove", mousemove);
    document.addEventListener("mouseup", mouseup);
  }

  onSvgMouseDown = () => {
    this.setState({didDrag: false});
  }

  renderAdditionalStuff = () => null;
}


ThinkingWavelengthBase.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};


export default ThinkingWavelengthBase;
