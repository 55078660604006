import Raven from 'raven-js';

import * as api from './api.js';
import { removeClientSideState } from './reducers/playbook.js';


export function ValidationError(errors) {
  this.errors = errors;
  this.errors._error = errors.non_field_errors;
}


function apiCall(actionCreater) {
  return (...args) => {
    const thunk = actionCreater(...args);
    return (dispatch, getState) =>
      thunk(dispatch, getState).catch((response) => {
        let message;


        if (response.status === 400) {
          return response.json().then((errors) => {
            throw new ValidationError(errors);
          });
        } else if (response.status === 401) {
          dispatch(setSessionStatus(null));
          throw response;
        } else if (response instanceof TypeError) {
          message = `Network error (${response.message})`;
        } else if (response.status === 403) {
          message = 'The access permissions for this Paterson Playbook have changed.';
        } else if (response.status >= 500 && response.status < 600) {
          message = 'A server error occurred. Please try again.';
        } else {
          message = `Unknown error (${response})`;
          Raven.captureMessage(message, {
            extra: {
              response: {
                status: response.status,
                type: response.type,
                url: response.url,
              },
              args: args
            }
          });
          console.log(response);
        }

        dispatch({
          type: 'SHOW_MODAL',
          modal: {
            message: message,
            dismissible: true,
          },
        });

        // If the error is not re-thrown, any chained promise handler will be called with the
        // return value of this error handler.
        // This will cause a warning of an uncaught error, even though it is actually caught
        // and handled.
        throw response;
      });
  };
}


let save = () => {
  return (dispatch, getState) => {
    const playbook = removeClientSideState(getState().playbook);

    dispatch({type: 'SAVE'});

    return api.savePlaybook(playbook)
      .then((json) => {
        dispatch({type: 'SAVE_COMPLETE', playbook: json});
      })
      .catch((resp) => {
        dispatch({type: 'SAVE_ERROR'});

        if (resp.status === 400) {
          dispatch({
            type: 'SHOW_MODAL',
            modal: {
              message: 'There was an error when saving the playbook.',
              dismissible: true,
            },
          });
        } else if (resp.status === 409) {
          return resp.json().then((json) => {
            const msg =
              'The playbook has been edited by someone else or in another window. ' +
              'Only one set of changes can be kept.';
            dispatch({
              type: 'SHOW_MODAL',
              modal: {
                message: msg,
                dismissible: false,
                actions: [
                  {
                    text: 'Keep yours (throw away theirs}',
                    action: () => {
                      dispatch(updateMetadata({
                        version: json.version,
                      }));
                      dispatch(closeModal());
                      dispatch(save());
                    }
                  },
                  {
                    text: 'Keep theirs (throw away yours)',
                    action: () => {
                      dispatch(playbookLoaded(json));
                      dispatch(closeModal());
                    },
                  },
                ]
              },
            });
          });
        } else {
          throw resp;
        }
      });
  };
};
save = apiCall(save);


let saveMetadata = (id, data) => {
  return (dispatch) => {
    return api.savePlaybook({...data, id: id})
      .then((json) => {
        dispatch(updateMetadata({...data, version: json.version}));
      });
  };
};
saveMetadata = apiCall(saveMetadata);


export function updateMetadata(metadata) {
  return {
    type: 'SAVE_METADATA',
    metadata: metadata,
  };
}


let loadPlaybook = (id) => {
  return (dispatch) => {
    return api.getPlaybook(id)
      .then((playbook) => {
        dispatch(playbookLoaded(playbook));
      })
      .catch((error) => {
        if (error.status === 404) {
          dispatch({
            type: 'SHOW_MODAL',
            modal: {
              message: '404 Playbook not found',
              dismissible: false,
            }});
        } else {
          throw error;
        }
      });
  };
};
loadPlaybook = apiCall(loadPlaybook);


let createPlaybook = (metadata) => {
  return () => {
    return api.createPlaybook(metadata);
  };
};
createPlaybook = apiCall(createPlaybook);

export function playbookLoaded(playbook) {
  return {
    type: 'PLAYBOOK_LOADED',
    playbook: playbook,
  };
}


let loadPlaybooks = (search) => {
  return (dispatch) => {
    const playbooksPromise = api.getPlayBooks(search);
    const facilitatorsPromise = api.getFacilitators()
      .catch((error) => {
        // not all users have permission to view facilitators
        if (error.status === 403)
          return [];
        else
          throw error;
      });

    return Promise.all([playbooksPromise, facilitatorsPromise])
      .then(([playbooks, facilitators]) => {
        const facilitatorMap = facilitators.reduce((accumulator, f) => {
          accumulator[f.url] = f;
          return accumulator;
        }, {});

        dispatch({
          type: 'PLAYBOOKS_LOADED',
          playbooks: playbooks,
          facilitatorMap: facilitatorMap,
        });
      });
  };
};
loadPlaybooks = apiCall(loadPlaybooks);


let loadFacilitators = () => {
  return (dispatch) => {
    return api.getFacilitators()
      .then((facilitators) => dispatch({
        type: 'FACILITATORS_LOADED',
        facilitators: facilitators,
      }));
  };
};
loadFacilitators = apiCall(loadFacilitators);


let getSessionStatus = () => {
  return (dispatch) => {
    return api.getLoggedInUser()
      .then((user) => dispatch(setSessionStatus(user)))
      .catch((response) => {
        if (response.status === 401) {
          dispatch(setSessionStatus(null));
        }
        else {
          throw response;
        }
      });
  };
};
getSessionStatus = apiCall(getSessionStatus);


export function setSessionStatus(user) {
  Raven.setUserContext(user);
  return {
    type: 'SET_SESSION_STATUS',
    user,
  };
}


let login = (email, password) => {
  return (dispatch) => {
    return api.login(email, password)
      .then((user) => dispatch(setSessionStatus(user)));
  };
};
login = apiCall(login);


let logout = () => {
  return (dispatch) => {
    return api.logout()
      .then(() => dispatch(setSessionStatus(null)));
  };
};
logout = apiCall(logout);


export function addWidget(widgetType, index, data={}) {
  return {
    type: 'ADD_WIDGET',
    widgetType: widgetType,
    index: index,
    data: data,
  };
}


export function closeModal() {
  return {
    type: 'CLOSE_MODAL',
  };
}


export function scrollToWidget(id) {
  return {
    type: 'SCROLL_TO_WIDGET',
    id,
  };
}

export function clearPlaybook() {
  return {
    type: 'CLEAR_PLAYBOOK',
  };
}

let loadPermissions = () => {
  return (dispatch, getState) => {
    return api.getPermissions(getState().playbook)
      .then((permissions) => dispatch({
        type: 'PERMISSIONS_LOADED',
        permissions,
      }));
  };
};
loadPermissions = apiCall(loadPermissions);

let setPermissionLevel = (email, level, resend) => {
  return (dispatch, getState) => {
    let playbook = getState().playbook;
    dispatch({
      type: 'SET_PERMISSION_LEVEL',
      email,
      level,
    });
    return api.setPermissionLevel(playbook, email, level, resend)
      .catch((response) => {
        if (response.status === 404) {
          // Double click, or someone else deleted it first. The effect is the
          // same as if we had deleted it, so just ignore.
          dispatch(loadPermissions());
        } else {
          throw response;
        }
      })
      .then(() => dispatch(loadPermissions()));
  };
};
setPermissionLevel = apiCall(setPermissionLevel);

export {
  save,
  saveMetadata,
  loadPlaybook,
  createPlaybook,
  loadPlaybooks,
  loadFacilitators,
  getSessionStatus,
  login,
  logout,
  loadPermissions,
  setPermissionLevel,
};
