import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Link, Switch, Route } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Editor from './Editor.js';
import Viewer from './Viewer.js';
import NewPlaybook from './NewPlaybook.js';
import Home from './Home.js';
import Login from './Login.js';
import Logout from './Logout.js';
import Modal from './Modal.js';
import PlanOnAPage from './PlanOnAPage.js';
import OldAuthenticationMessage from './OldAuthenticationMessage.js';
import * as actions from '../actions.js';

import logo from '../image/paterson-logo.png';
import icon from '../image/paterson-icon.png';


class Application extends Component {
  componentDidMount() {
    if (this.props.session.status === 'loading') {
      this.props.getSessionStatus();
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div className={classNames({
          modalOpen: !!this.props.modal,
        })}>
          <nav>
            <Link to="/"><img src={logo} alt="Paterson" className="navLogo" /></Link>
            <Link to="/"><img src={icon} alt="Paterson" className="navIcon" /></Link>
            {
              this.props.session.status === 'authenticated' ?
                <div>
                  <Link className="logoutLink button" to="/logout">Log Out</Link>
                  <a href="/auth/password_change/" className="changePasswordLink">Change Password</a>
                  <a className="helpLink" href="https://patersoncenter.com/help/" target="_blank">Help</a>
                </div>
              :
                null
            }
          </nav>
          <main className="content">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/" component={Home} />

              {/* Previously, UUIDs were used to grant permissions. These links
                * are no longer valid. A general UUID regex isn't used because
                * plan-on-a-page took an encrypted version in a different
                * format. 20 is chosen simply to be longer than any possible
                * numeric id. */}
              <Route path="/editor/:id(.{20,})" component={OldAuthenticationMessage} />
              <Route path="/viewer/:id(.{20,})" component={OldAuthenticationMessage} />
              <Route path="/plan-on-a-page/:id(.{20,})" component={OldAuthenticationMessage} />

              <Route path="/editor/:id" component={Editor} />
              <Route path="/viewer/:id" component={Viewer} />
              <Route path="/plan-on-a-page/:id" component={PlanOnAPage} />
              <Route exact path="/new/:type(lifeplan|stratop)" component={NewPlaybook} />
              <Route render={() => <div className="error"><h1>404 PAGE NOT FOUND</h1></div>} />
            </Switch>
            {
              this.props.modal ? <Modal closeModal={this.props.closeModal} {...this.props.modal} /> : null
            }
          </main>
        </div>
      </BrowserRouter>
    );
  }
}

Application.propTypes = {
  session: PropTypes.object,
  getSessionStatus: PropTypes.func.isRequired,
  modal: PropTypes.object,
  closeModal: PropTypes.func,
};

Application = connect(
  (state) => ({
    session: state.session,
    modal: state.modal,
  }),
  {
    getSessionStatus: actions.getSessionStatus,
    closeModal: actions.closeModal,
  }
)(Application);

export default Application;
