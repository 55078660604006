import React, { Component } from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import EditableTitle from '../EditableTitle';
import { InlineInput } from '../InlineInput.js';
import RichTextEditor from '../RichTextEditor.js';
import { arrayFill } from '../../utils.js';
import './LifePerspectiveFilter.scss';
import './TurningPointProfile.scss';



export const INITIAL_STATE = {
  title: "Turning Point Profile",
  description: "The purpose of this tool is to chart out your Turning Points, from birth to your present age, in order to gain perspective on how you got to where you are. All stories have seasons of great triumph, breakthrough, growth and celebration. And all stories have a degree of drama, challenges, and moments of loss and suffering. Both the highs and lows present opportunity for growth and learning.",
  gates: [],
  facets: ['Personal', 'Family', 'Vocation', 'Spiritual', 'Community'],
  themes: [
    {
      name: null,
      turningPoints: [
        {
          name: null,
          timeframe: '',
          rank: 1,
          descriptions: [null, null, null, null, null],
        },
      ],
    },
  ],
};

export function reducer(state=INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_FACET':
      return {
        ...state,
        facets: [...state.facets, ''],
        themes: state.themes.map((theme) => (
          {
            ...theme,
            turningPoints: theme.turningPoints.map((turningPoint) => (
              {
                ...turningPoint,
                descriptions: [...turningPoint.descriptions, null],
              }
            ))
          }
        )),
      };
    case 'DELETE_FACET':
      return {
        ...state,
        facets: state.facets.filter((facet, i) => i !== action.index),
        themes: state.themes.map((theme) => (
          {
            ...theme,
            turningPoints: theme.turningPoints.map((turningPoint) => (
              {
                ...turningPoint,
                descriptions: turningPoint.descriptions.filter((description, i) => i !== action.index),
              }
            ))
          }
        )),
      };
    case 'SET_FACET':
      return {
        ...state,
        facets: state.facets.map((facet, i) => i === action.index ? action.value : facet),
      };
    case 'SET_DESCRIPTION':
      return {
        ...state,
        themes: state.themes.map((theme, i) =>
          i === action.theme ?
            {
              ...theme,
              turningPoints: theme.turningPoints.map((turningPoint, j) =>
                j === action.turningPoint ?
                  {
                    ...turningPoint,
                    descriptions: turningPoint.descriptions.map((description, k) =>
                      k === action.facet ?
                        action.value
                      :
                        description
                    ),
                  }
                :
                  turningPoint,
              ),
            }
          :
            theme
        ),
      };
    case 'DELETE_TURNING_POINT':
      return {
        ...state,
        themes: state.themes.map((theme, i) =>
          i === action.theme ?
            {
              ...theme,
              turningPoints: theme.turningPoints.filter((turningPoint, j) => j !== action.turningPoint),
            }
          :
            theme
        ).filter((theme) =>
          theme.turningPoints.length !== 0
        ),
        // this is not necessarily the correct behaviour, but it's predictable and simple
        gates: state.gates.filter((gate, gateIndex) => gateIndex < state.gates.length - 1)
      };
    case 'SET_TURNING_POINT':
      if (action.rank !== undefined) {
        if (!(action.rank <= 10 && action.rank >= 1)) {
          action.rank = 5;
        }
      }
      return {
        ...state,
        themes: state.themes.map((theme, i) =>
          i === action.theme ?
            {
              ...theme,
              turningPoints: theme.turningPoints.map((turningPoint, j) =>
                j === action.turningPoint ?
                  {...turningPoint, ..._.pick(action, 'name', 'timeframe', 'rank')}
                :
                  turningPoint
              )
            }
          :
            theme
        )
      };
    case 'SET_TURNING_POINT_THEME':
      if (action.value || (action.theme === 0 && action.turningPoint === 0)) {
        // Editing the theme of a turning point splits it and creates a new
        // theme.
        const themes = state.themes.slice();
        const theme = themes[action.theme];

        const before = {
          ...theme,
          turningPoints: theme.turningPoints.filter((turningPoint, i) => i < action.turningPoint)
        };
        const after = {
          ...theme,
          name: action.value,
          turningPoints: theme.turningPoints.filter((turningPoint, i) => i >= action.turningPoint)
        };
        const replacements = [];
        if (before.turningPoints.length) {
          replacements.push(before);
        }
        if (after.turningPoints.length) {
          replacements.push(after);
        }
        themes.splice(action.theme, 1, ...replacements);

        return {
          ...state,
          themes: themes,
        };
      } else if (action.turningPoint === 0) {
        // Blanking the first turningpoint of a theme joins it with the
        // previous theme.
        const themes = state.themes.slice();
        const theme = themes[action.theme - 1];
        themes.splice(action.theme - 1, 2, {
          ...theme,
          turningPoints: theme.turningPoints.concat(themes[action.theme].turningPoints),
        });
        return {
          ...state,
          themes: themes,
        };
      } else {
        // blanking a turning point in the middle of a theme does notype,
        // because it's already blank.
        return state;
      }
    case 'ADD_TURNING_POINT':
      {
        let themes = state.themes;
        let emptyTurningPoint = {
          name: null,
          timeframe: '',
          rank: 1,
          descriptions: arrayFill(state.facets.length, null),
        };
        let gates = state.gates;

        if (themes.length) {
          gates = [...gates, null];
          themes = themes.map((theme, i) =>
            i === themes.length - 1 ?
              {
                ...theme,
                turningPoints: [...theme.turningPoints, emptyTurningPoint],
              }
            :
              theme
          );
        } else {
          themes = [
            {
              name: null,
              turningPoints: [emptyTurningPoint],
            },
          ];
        }

        return {
          ...state,
          gates: gates,
          themes: themes,
        };
      }
    case 'SET_GATE':
      return {
        ...state,
        gates: state.gates.map((gate, i) => i === action.index ? action.value : gate)
      };
    default:
      return state;
  }
}


export function focusNextType(type, themeIndex, turningPointIndex, data) {
  const lastTurningPointInTheme =
    turningPointIndex === data.themes[themeIndex].turningPoints.length - 1;

  if (type === 'DESCRIPTION') {
    return {
      type: 'DESCRIPTION',
      themeIndex: lastTurningPointInTheme ? themeIndex + 1 : themeIndex,
      turningPointIndex: lastTurningPointInTheme ? 0 : turningPointIndex + 1,
    };
  }
  else {
    let nextType;

    if (type === 'AGE') {
      nextType = 'TURNING_POINT';
    }
    else if (type === 'TURNING_POINT') {
      nextType = 'RANK';
    }
    else if (type === 'RANK') {
      nextType = 'AGE';
      themeIndex = lastTurningPointInTheme ? themeIndex + 1 : themeIndex;
      turningPointIndex = lastTurningPointInTheme ? 0 : turningPointIndex + 1;
    }
    return {
      type: nextType,
      themeIndex: themeIndex,
      turningPointIndex: turningPointIndex,
    };
  }
}


export function overlapBetweenRanges(r1, r2) {
  return Math.max(0, Math.min(r1[1], r2[1]) - Math.max(r1[0], r2[0]));
}


export function countOffscreenElements(elements, top) {
  let start = 0;

  for (let i=0; i < elements.length; i++) {
    let rect = elements[i];

    if (i === 0 && rect.top > top) {
      break;
    } else if (rect.bottom > top) {
      if (rect.top < top) {
        start += (top - rect.top) / (rect.bottom - rect.top);
      }
      break;
    } else {
      start += 1;
    }
  }

  return start;
}


export class RankGraph extends Component {
  render() {
    const { data, visibilitySliderStart, visibilitySliderWidth, showThemes } = this.props;

    const ranks = _.flatten(
      data.themes.map((theme) =>
        theme.turningPoints.map((turningPoint) =>
          ({timeframe: turningPoint.timeframe, rank:turningPoint.rank})
        )
      )
    );

    const themes = [];
    let turningPointCount = 0;

    data.themes.forEach((theme) => {
      themes.push({
        name: theme.name,
        start: turningPointCount,
        end: turningPointCount + theme.turningPoints.length
      });

      turningPointCount += theme.turningPoints.length;
    });

    const GRAPH_WIDTH = 1000;
    const GRAPH_BOTTOM = 140;
    const OFFSET_LEFT = 60;
    const OFFSET_TOP = 25;
    const PADDING_BOTTOM = showThemes ? 25 : 0;

    const GRAPH_HEIGHT = GRAPH_BOTTOM +  PADDING_BOTTOM;
    const ITEM_WIDTH = (GRAPH_WIDTH - OFFSET_LEFT) / ranks.length;

    return (
      <svg
        className="rankGraph"

        viewBox={`0 0 ${GRAPH_WIDTH} ${GRAPH_HEIGHT}`}
      >
        <defs>
          <marker
            id='circle'
            markerWidth="4"
            markerHeight="4"
            refX="2"
            refY="2"
          >
            <circle cx="2" cy="2" r="2" fill='#00A275' />
          </marker>
        </defs>
        {
          data.gates.map((gate, i) =>
            gate ?
              <line
                key={i}
                x1={OFFSET_LEFT + (i + 1) * ITEM_WIDTH}
                x2={OFFSET_LEFT + (i + 1) * ITEM_WIDTH}
                y1={OFFSET_TOP}
                y2={GRAPH_BOTTOM}
                stroke='#ff4500'
                strokeWidth='2'
              />
            :
              null
          )
        }

        {
          visibilitySliderStart !== undefined && visibilitySliderWidth !== undefined ?
            <rect
              x={OFFSET_LEFT + visibilitySliderStart * ITEM_WIDTH}
              y={OFFSET_TOP}
              width={visibilitySliderWidth * ITEM_WIDTH}
              height={GRAPH_BOTTOM - OFFSET_TOP}
              fill='#00A275'
              fillOpacity='0.1'
            />
          :
            null
        }

        <text
          x={10}
          y={15}
        >
          AGE:
        </text>

        <text
          x={25}
          y={87}
          textAnchor='middle'
        >
          RANK
        </text>

        <text
          x={25}
          y={47}
          className='rankLabel'
          textAnchor='middle'
        >
          10
        </text>

        <text
          x={25}
          y={GRAPH_BOTTOM - 17}
          className='rankLabel'
          textAnchor='middle'
        >
          1
        </text>

        <line
          x1={0}
          x2={GRAPH_WIDTH}
          y1={OFFSET_TOP}
          y2={OFFSET_TOP}
          stroke='#00A275'
          strokeWidth='2'
        />

        <line
          x1={OFFSET_LEFT}
          x2={GRAPH_WIDTH}
          y1={OFFSET_TOP + (GRAPH_BOTTOM - OFFSET_TOP)/2}
          y2={OFFSET_TOP + (GRAPH_BOTTOM - OFFSET_TOP)/2}
          stroke='#00A275'
          strokeDasharray='4 4'
          opacity='.5'
          strokeWidth='2'
        />

        {
          showThemes ?
            [
              <line
                key='0'
                x1={0}
                x2={OFFSET_LEFT - 4}
                y1={GRAPH_BOTTOM - 2}
                y2={GRAPH_BOTTOM - 2}
                stroke='#00A275'
                strokeWidth='4'
              />,
              <text
                key='1'
                x={25}
                y={GRAPH_BOTTOM + 17}
                className='themeLabel'
                textAnchor='middle'
              >
                THEME
              </text>,
              <line
                key='2'
                x1={GRAPH_WIDTH - 4}
                x2={GRAPH_WIDTH}
                y1={GRAPH_BOTTOM - 2}
                y2={GRAPH_BOTTOM - 2}
                stroke='#00A275'
                strokeWidth='4'
              />,
            ]

          :
            null
        }

        {
          showThemes ?
            themes.map((theme, i) =>
              <line
                key={i}
                x1={OFFSET_LEFT + theme.start * ITEM_WIDTH + 4}
                x2={OFFSET_LEFT + theme.end * ITEM_WIDTH - 4}
                y1={GRAPH_BOTTOM - 2}
                y2={GRAPH_BOTTOM - 2}
                stroke='#00A275'
                strokeWidth='4'
              />
            )
          :
            <line
              x1={0}
              x2={GRAPH_WIDTH}
              y1={GRAPH_BOTTOM - 1}
              y2={GRAPH_BOTTOM - 1}
              stroke='#00A275'
              strokeWidth='2'
            />
        }

        {
          showThemes ?
            themes.map((theme, i) =>
              <text
                key={i}
                x={OFFSET_LEFT + (theme.start + theme.end) / 2 * ITEM_WIDTH}
                y={GRAPH_BOTTOM + 17}
                textAnchor='middle'
              >
                {
                  <RichTextEditor value={theme.name} onChange={null} />
                }
              </text>
            )
          :
            null
        }

        {
          ranks.map((rank, i) =>
            <text
              key={i}
              x={OFFSET_LEFT + (i + 0.5) * ITEM_WIDTH}
              y={15}
              textAnchor='middle'
            >
              {rank.timeframe}
            </text>
          )
        }

        {
          _.zip(_.initial(ranks), _.rest(ranks)).map((pair, i) =>
            <line
              key={i}
              x1={OFFSET_LEFT + (i + 0.5) * ITEM_WIDTH}
              x2={OFFSET_LEFT + (i + 1.5) * ITEM_WIDTH}
              y1={GRAPH_BOTTOM - pair[0].rank * (GRAPH_BOTTOM - OFFSET_TOP) / 10}
              y2={GRAPH_BOTTOM - pair[1].rank * (GRAPH_BOTTOM - OFFSET_TOP) / 10}
              markerStart='url(#circle)'
              markerEnd='url(#circle)'
              stroke='#00A275'
              strokeWidth='2'
            />
          )
        }
        {
          ranks.map((rank, i) =>
            <text
              key={i}
              x={OFFSET_LEFT + (i + 0.5) * ITEM_WIDTH}
              y={GRAPH_BOTTOM - rank.rank * (GRAPH_BOTTOM - OFFSET_TOP) / 10 +
                (rank.rank > 2 ? 20 : -15)
              }
              textAnchor='middle'
              stroke='#00A275'
            >
              {rank.rank}
            </text>
          )
        }
      </svg>
    );
  }
}
RankGraph.defaultProps = {
  showThemes: false
};
RankGraph.propTypes = {
  data: PropTypes.object.isRequired,
  visibilitySliderStart: PropTypes.number,
  visibilitySliderWidth: PropTypes.number,
  showThemes: PropTypes.bool,
};


class TurningPointProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedIndex: null,
      visibilitySlider: {start: 0, width: 0},
    };
  }

  componentDidMount() {
    if (!this.props.onEditData) {
      window.addEventListener('scroll', this.handleVisibilitySlider, true);
      window.addEventListener('resize', this.handleVisibilitySlider);
    }
  }

  componentWillUnmount() {
    if (!this.props.onEditData) {
      window.removeEventListener('scroll', this.handleVisibilitySlider, true);
      window.removeEventListener('resize', this.handleVisibilitySlider);
    }
  }

  fractionOfRowVisible(i) {
    const row = this.turningPointRows[i].getBoundingClientRect();
    const rankGraphBottom = this.rankGraphWrap.getBoundingClientRect().bottom;

    const visiblePixels = overlapBetweenRanges(
      [row.top, row.bottom],
      [Math.max(0, rankGraphBottom), window.innerHeight]
    );
    return visiblePixels / (row.bottom - row.top);
  }

  handleVisibilitySlider = _.throttle(() => {
    const elementRects = this.turningPointRows.map((e) => e.getBoundingClientRect());
    const top = this.rankGraphWrap.getBoundingClientRect().bottom;
    const start = countOffscreenElements(elementRects, top);

    const width = this.turningPointRows.map((row, i) => this.fractionOfRowVisible(i))
                      .reduce((a, b) => a+b, 0);

    this.setState({visibilitySlider: {start: start, width: width}});
  }, 16, {trailing: false})

  gateIndex = (themeIndex, turningPointIndex) => {
    let accumulator = 0;
    for (let i=0; i < themeIndex; i++) {
      accumulator += this.props.data.themes[i].turningPoints.length;
    }

    accumulator += turningPointIndex;

    return accumulator;
  }

  render() {
    const { data, onEditData } = this.props;
    this.turningPointRows = [];

    let tableContents;
    if (onEditData) {
      tableContents = ([
        <thead key={0}>
          <tr>
            <th></th>
            <th className="themeCol">Theme</th>
            <th className="ageCol">Age</th>
            <th className="turningPointCol">Turning Point</th>
            <th className="rankCol">Rank</th>
            {
              data.facets.map((facet, facetIndex) =>
                <th key={facetIndex}>
                  <button
                    title="Delete facet"
                    className="delete"
                    onClick={() =>
                      onEditData(reducer(data, {
                        type: 'DELETE_FACET',
                        index: facetIndex,
                      }))
                    }
                  />
                  <InlineInput
                    value={facet}
                    blankPlaceholder="Add Domain..."
                    isFocused={
                      _.isEqual(this.state.focusedIndex, {
                        type: 'FACET',
                        facetIndex: facetIndex,
                      })
                    }
                    onChange={(val, enterPressed) => {
                      let newData = reducer(data, {
                        type: 'SET_FACET',
                        index: facetIndex,
                        value: val,
                      });

                      if (enterPressed && facetIndex === data.facets.length - 1) {
                        newData = reducer(newData, {type: 'ADD_FACET'});
                      }

                      onEditData(newData);

                      if (enterPressed) {
                        this.setState({focusedIndex: {
                          type: 'FACET',
                          facetIndex: facetIndex + 1,
                        }});
                      } else {
                        this.setState({focusedIndex: null});
                      }
                    }}
                  />
                </th>
              )
            }
            <th className="add">
              <button
                className="add"
                onClick={() => {
                  onEditData(reducer(data, {type: 'ADD_FACET'}));
                  this.setState({
                    focusedIndex: {
                      type: 'FACET',
                      facetIndex: data.facets.length,
                    }
                  });
                }}
              >
                <span className="plus">+</span>
              </button>
            </th>
          </tr>
        </thead>,
        <tbody key={1}>
          {
            _.flatten(
              data.themes.map((theme, themeIndex) =>
                theme.turningPoints.map((turningPoint, turningPointIndex) => {
                  const gateIndex = this.gateIndex(themeIndex, turningPointIndex);

                  return [
                    <tr key={`row-${gateIndex}`}>
                      <td className="delete">
                        <button
                          className="delete"
                          title="Delete"
                          onClick={() => onEditData(reducer(data, {
                            type: 'DELETE_TURNING_POINT',
                            theme: themeIndex,
                            turningPoint: turningPointIndex,
                          }))}
                        />
                      </td>
                      <td className="cellHighlightBright" rowSpan="2">
                        <RichTextEditor
                          viewerElement='span'
                          editorProps={{ rows:1 }}
                          value={turningPointIndex === 0 ? theme.name : null}
                          blankPlaceholder={theme.name || 'Add Theme...'}
                          onChange={(val) => {
                            onEditData(reducer(data, {
                              type: 'SET_TURNING_POINT_THEME',
                              theme: themeIndex,
                              turningPoint: turningPointIndex,
                              value: val,
                            }));

                            this.setState({focusedIndex: null});
                          }}
                        />
                      </td>

                      <td className="cellHighlight">
                        <InlineInput
                          value={turningPoint.timeframe}
                          isFocused={
                            _.isEqual(this.state.focusedIndex, {
                              type: 'AGE',
                              themeIndex: themeIndex,
                              turningPointIndex: turningPointIndex,
                            })
                          }
                          onChange={(val, enterPressed) => {
                            const newData = reducer(data, {
                              type: 'SET_TURNING_POINT',
                              theme: themeIndex,
                              turningPoint: turningPointIndex,
                              timeframe: val,
                            });

                            onEditData(newData);

                            let focusedIndex;
                            if (enterPressed) {
                              focusedIndex = focusNextType('AGE', themeIndex, turningPointIndex, newData);
                            } else {
                              focusedIndex = null;
                            }
                            this.setState({focusedIndex: focusedIndex});
                          }}
                        />
                      </td>

                      <td className="cellHighlight">
                        <RichTextEditor
                          value={turningPoint.name}
                          blankPlaceholder='Add Turning Point...'
                          isFocused={
                            _.isEqual(this.state.focusedIndex, {
                              type: 'TURNING_POINT',
                              themeIndex: themeIndex,
                              turningPointIndex: turningPointIndex,
                            })
                          }
                          onChange={(val, enterPressed) => {
                            let newData = reducer(data, {
                              type: 'SET_TURNING_POINT',
                              theme: themeIndex,
                              turningPoint: turningPointIndex,
                              name: val,
                            });

                            onEditData(newData);

                            if (enterPressed) {
                              this.setState({focusedIndex:
                                focusNextType('TURNING_POINT', themeIndex, turningPointIndex, newData)
                              });
                            } else {
                              this.setState({focusedIndex: null});
                            }
                          }}
                        />
                      </td>

                      <td>
                        <InlineInput
                          editorProps={{
                            type: 'number',
                            min: 1,
                            max: 10,
                          }}
                          value={turningPoint.rank}
                          isFocused={
                            _.isEqual(this.state.focusedIndex, {
                              type: 'RANK',
                              themeIndex: themeIndex,
                              turningPointIndex: turningPointIndex,
                            })
                          }
                          autoselect={true}
                          onChange={(val, enterPressed) => {
                            // Rank should be stored as a number butval passed by InlineInput is
                            // a string
                            val = parseInt(val, 10);

                            let newData = reducer(data, {
                              type: 'SET_TURNING_POINT',
                              theme: themeIndex,
                              turningPoint: turningPointIndex,
                              rank: val,
                            });

                            if (
                              enterPressed && themeIndex === data.themes.length - 1 &&
                              turningPointIndex === data.themes[data.themes.length - 1].turningPoints.length - 1
                            ) {
                              newData = reducer(newData, {type: 'ADD_TURNING_POINT'});
                            }

                            onEditData(newData);

                            if (enterPressed) {
                              this.setState({
                                focusedIndex: focusNextType('RANK', themeIndex, turningPointIndex, newData)
                              });
                            } else {
                              this.setState({focusedIndex: null});
                            }
                          }}
                        />
                      </td>
                      {
                        turningPoint.descriptions.map((description, descriptionIndex) =>
                          <td key={descriptionIndex}>
                            <RichTextEditor
                              value={description}
                              blankPlaceholder='Description...'
                              viewerElement='span'
                              editorProps={{ rows:1 }}
                              isFocused={
                                _.isEqual(this.state.focusedIndex, {
                                  type: 'DESCRIPTION',
                                  themeIndex: themeIndex,
                                  turningPointIndex: turningPointIndex,
                                  facetIndex: descriptionIndex,
                                })
                              }
                              onChange={(val, enterPressed) => {
                                const newData = reducer(data, {
                                  type: 'SET_DESCRIPTION',
                                  theme: themeIndex,
                                  turningPoint: turningPointIndex,
                                  facet: descriptionIndex,
                                  value: val,
                                });

                                onEditData(newData);

                                let focusedIndex;

                                if (enterPressed) {
                                  if (themeIndex === data.themes.length - 1 && turningPointIndex === theme.turningPoints.length - 1) {
                                    focusedIndex = {
                                      type: 'DESCRIPTION',
                                      turningPointIndex: 0,
                                      themeIndex: 0,
                                      facetIndex: descriptionIndex + 1,
                                    };
                                  } else {
                                    focusedIndex = {
                                      ...focusNextType('DESCRIPTION', themeIndex, turningPointIndex, newData),
                                      facetIndex: descriptionIndex,
                                    };
                                  }
                                }
                                else {
                                  focusedIndex = null;
                                }

                                this.setState({focusedIndex: focusedIndex});
                              }}
                            />
                          </td>
                        )
                      }
                    </tr>,
                    gateIndex < data.gates.length ?
                      <tr className='gate' key={`gate-${gateIndex}`}>
                        <td />
                        <td
                          colSpan={data.facets.length + 3}
                          className={classNames('gate', {
                            active: !!data.gates[gateIndex]
                          })}
                        >
                          <InlineInput
                            value={data.gates[gateIndex] || ''}
                            blankPlaceholder="Add Gate"
                            isFocused={
                              _.isEqual(this.state.focusedIndex, {
                                type: 'GATE',
                                gateIndex: gateIndex,
                              })
                            }
                            onChange={(val, enterPressed) => {
                              onEditData(reducer(data, {
                                type: 'SET_GATE',
                                index: gateIndex,
                                value: val,
                              }));

                              if (enterPressed && gateIndex < data.gates.length - 1) {
                                this.setState({focusedIndex: {
                                  type: 'GATE',
                                  gateIndex: gateIndex + 1,
                                }});
                              } else {
                                this.setState({focusedIndex: null});
                              }
                            }}
                          />
                          <button
                            className="delete"
                            title="Delete"
                            onClick={() => onEditData(reducer(data, {
                              type: 'SET_GATE',
                              index: gateIndex,
                              value: null,
                            }))}
                          />
                        </td>
                      </tr>
                    :
                      null
                  ];
                })
              )
            )
          }
          <tr />
          <tr className='add'>
            <td />
            <td colSpan={data.facets.length + 4}>
              <button className='add'
                onClick={() => {
                  let newData = reducer(data, {
                    type: 'ADD_TURNING_POINT',
                  });
                  onEditData(newData);
                  this.setState({
                    focusedIndex: {
                      type: 'THEME',
                      themeIndex: newData.themes.length - 1,
                      turningPointIndex: newData.themes[newData.themes.length - 1].turningPoints.length - 1,
                    }
                  });
                }}>
                <span className="plus">+</span> Add Turning Point
              </button>
            </td>
          </tr>
        </tbody>
      ]);
    } else {
      tableContents = ([
        <thead key={0}>
          <tr>
            <th className="themeCol">Theme</th>
            <th className="ageCol">Age</th>
            <th className="turningPointCol">Turning Point</th>
            {
              data.facets.map((facet, facetIndex) =>
                <th key={facetIndex}>
                  {facet}
                </th>
              )
            }
          </tr>
        </thead>,
        <tbody key={1}>
          {
            data.themes.map((theme, themeIndex) =>
              theme.turningPoints.map((turningPoint, turningPointIndex) =>
                <React.Fragment key={`${themeIndex}-${turningPointIndex}`}>
                  <tr
                    ref={(e) =>
                      this.turningPointRows[this.gateIndex(themeIndex, turningPointIndex)] = e
                    }
                  >
                    {
                      turningPointIndex === 0 ?
                        <td rowSpan={theme.turningPoints.length * 2} className="cellHighlightBright" data-label="Theme">
                          <RichTextEditor value={theme.name} onChange={null} />
                        </td>
                      :
                        null
                    }
                    <td className="cellHighlight" data-label="Age">{turningPoint.timeframe}</td>
                    <td className="cellHighlight" data-label="Turning Point">
                      <RichTextEditor value={turningPoint.name} onChange={null} />
                    </td>
                    {
                      turningPoint.descriptions.map((description, descriptionIndex) =>
                        <td key={descriptionIndex} data-label={data.facets[descriptionIndex]}>
                          <RichTextEditor value={description} onChange={null} />
                        </td>
                      )
                    }
                  </tr>
                  {
                    data.gates[this.gateIndex(themeIndex, turningPointIndex)] ?
                      <tr
                        className={classNames('gate', 'active', {
                          lastInTheme: turningPointIndex === theme.turningPoints.length - 1
                        })}
                         data-label={data.gates[this.gateIndex(themeIndex, turningPointIndex)]}
                      >
                        <td className="cellHighlight" />
                        <td className="cellHighlight" />
                        <td colSpan={data.facets.length} className="gateActiveSpan">
                          <span>
                            {data.gates[this.gateIndex(themeIndex, turningPointIndex)]}
                          </span>
                        </td>
                      </tr>
                    :
                      <tr
                        className={classNames('gate', {
                          lastInTheme: turningPointIndex === theme.turningPoints.length - 1
                        })}
                      >
                        <td colSpan={data.facets.length + 2 } />
                      </tr>
                  }
                </React.Fragment>
              )
            )
          }
        </tbody>
      ]);
    }

    return (
      <div className='chart turningPointProfile'>
        <p className="stickyTitle">{data.title}</p>
        <EditableTitle
          data={data}
          onEditData={onEditData}
        />
        {
          !onEditData ?
            <div
              className='rankGraphWrap'
              ref={(e) => this.rankGraphWrap = e}
            >
              <RankGraph
                data={data}
                visibilitySliderStart={this.state.visibilitySlider.start}
                visibilitySliderWidth={this.state.visibilitySlider.width}
              />
              <table className='turningPointProfile stickyHeaderTable'>
                {tableContents}
              </table>
            </div>
          :
            null
        }
        <div className='tableOverflowWrapper'>
          <table className='turningPointProfile'>
            {tableContents}
          </table>
        </div>
      </div>
    );
  }
}

TurningPointProfile.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};

export default TurningPointProfile;
