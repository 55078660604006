import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InlineInput } from '../InlineInput.js';


export default class DraggableInputChartBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      isDragging: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
    });
  }

  renderItems = () => {
    this.itemElems = [];

    return this.state.data.items.map((item, i) => (
      <div
        key={i}
        ref={(elem) => this.itemElems[i] = elem}
        {...this.getItemProps(i)}
      >
        <div className="shift">
          {
            this.props.onEditData ?
              <button onClick={(e) => this.deleteItem(e, i)} className="delete" />
            :
              null
          }
          <div
            className="inputWrapper"
            onMouseDown={this.props.onEditData ? (event) => this.startDrag(event, i) : null}
            onClickCapture={this.onClickCapture}
          >
            {
              this.props.onEditData ?
                <InlineInput
                  value={item.name}
                  onChange={(value) => this.editItem(i, value)}
                  isFocused={i === this.state.focusedIndex}
                />
              :
                <span>{item.name}</span>
            }
          </div>
        </div>
      </div>
    ));
  }

  onClickCapture = (event) => {
    // Our items need to do different things when they're clicked versus
    // dragged. If we did drag, we don't want the click to trickle down to the
    // InlineInput and open the editor.
    if (this.state.didDrag)
      event.stopPropagation();
  }

  startDrag = (event, itemIndex) => {
    if (event.button !== 0) {
      return;
    }
    if (event.target.tagName === 'INPUT') {
      return;
    }
    event.preventDefault();

    this.setState({
      dragOffset: {
        x: this.itemElems[itemIndex].getBoundingClientRect().left - event.clientX,
        y: this.itemElems[itemIndex].getBoundingClientRect().top - event.clientY,
      },
      isDragging: true,
      didDrag: false,
    });

    const mousemove = (event) => {
      event.preventDefault();

      this.setState((state) => ({
        didDrag: true,
        data: {
          ...state.data,
          items: this.state.data.items.map((item, i) =>
            (i === itemIndex ? {
              ...item,
              ...this.mouseCoordinatesToData(
                event.clientX + this.state.dragOffset.x,
                event.clientY + this.state.dragOffset.y
              )
            } : item)
          )
        }
      }));
    };

    const mouseup = () => {
      document.removeEventListener("mousemove", mousemove);
      document.removeEventListener("mouseup", mouseup);
      this.props.onEditData(this.state.data);
      this.setState({isDragging: false});
    };

    document.addEventListener("mousemove", mousemove);
    document.addEventListener("mouseup", mouseup);
  }

  addItem = (event) => {
    this.props.onEditData({
      ...this.props.data,
      items: [
        ...this.props.data.items,
        {
          name: '',
          ...this.mouseCoordinatesToData(event.clientX, event.clientY),
        }
      ]
    });

    this.setState({focusedIndex: this.props.data.items.length});
  }


  editItem = (index, value) => {
    this.props.onEditData({
      ...this.props.data,
      items: this.props.data.items.map((item, i) =>
        (i === index ? {...item, name: value} : item)
      )
    });
    this.setState({focusedIndex: null});
  }

  deleteItem = (event, index) => {
    event.stopPropagation();

    this.props.onEditData({
      ...this.props.data,
      items: this.props.data.items.filter((item, i) => i !== index)
    });
  }
}

DraggableInputChartBase.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
