import React from 'react';

export default function OldAuthenticationMessage() {
  let email = 'support@patersoncenter.com';
  return (
    <div className="error">
      <h1>
        The Playbook you are trying to access has been moved to a new location,
        as part of improvements we’ve made to our platform. If you didn’t
        receive a new invitation to your playbook by email, please email
        <a href={`mailto:${email}`}>{email}</a> to request access.
      </h1>
    </div>
  );
}
