import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Prompt, Redirect } from 'react-router-dom';
import { ActionCreators } from 'redux-undo';
import _ from 'underscore';
import classNames from 'classnames';
import { SubmissionError } from 'redux-form';
import Helmet from "react-helmet";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PropTypes from 'prop-types';

import * as actions from '../actions.js';
import MetadataEditor from './MetadataEditor.js';
import EditableWidget from './EditableWidget.js';
import { Outline, OutlineItem } from './Outline.js';
import { removeUndoState, removeClientSideState } from '../reducers/playbook.js';
import WIDGET_METADATA from '../widgetMetadata.js';
import { UndoIcon, RedoIcon, ViewIcon } from './icons.js';
import { PLAYBOOK_TYPES } from '../reducers/playbook.js';
import PermissionLevel from '../PermissionLevel.js';
import RequireLogin from '../RequireLogin.js';
import ShareModal from './ShareModal.js';


/**
 * Allows using a ReactCSSTransitionGroup with a single child without a wrapper span.
 * <https://facebook.github.io/react/docs/animation.html#rendering-a-single-child>
 **/
function FirstChild(props) {
  const childrenArray = React.Children.toArray(props.children);
  return childrenArray[0] || null;
}


function Shelf({children, isOpen, isCloseable, openShelf, closeShelf}) {
  return (
    <div className={classNames("shelf", { isOpen, isCloseable })}>
      <ReactCSSTransitionGroup
        transitionName='open'
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
        component={FirstChild}
      >
        {
          isOpen ?
            <ul>
              {children}
            </ul>
          :
            null
        }
      </ReactCSSTransitionGroup>
      {
        isOpen ?
          (isCloseable ?
            <button className="close" title="Close" onClick={closeShelf}><i/></button>
          :
            null)
        :
          <button className="open" title="Open" onClick={openShelf}><i/></button>
      }
    </div>
  );
}

Shelf.propTypes = {
  children: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isCloseable: PropTypes.bool.isRequired,
  openShelf: PropTypes.func.isRequired,
  closeShelf: PropTypes.func.isRequired,
};


let ShelfItem = ({type, addWidget}) => {
  return (
    <li>
      <a className={'chart ' + type} onClick={() => addWidget(type)}>
        <span className="image"/>
        {WIDGET_METADATA[type].title()}
      </a>
    </li>
  );
};
ShelfItem.propTypes = {
  type: PropTypes.string.isRequired,
  addWidget: PropTypes.func.isRequired,
};


let StratOpShelf = ({ addWidget, isOpen, isCloseable, openShelf, closeShelf }) => {
  return (
    <Shelf
      isOpen={isOpen}
      isCloseable={isCloseable}
      openShelf={openShelf}
      closeShelf={closeShelf}
    >
      <ShelfItem type='ThinkingWavelengthStratop' addWidget={addWidget} />
      <ShelfItem type='TurningPoints' addWidget={addWidget} />
      <ShelfItem type='TurningPointProfileStratop' addWidget={addWidget} />
      <ShelfItem type='TurningPointLearningsStratop' addWidget={addWidget} />
      <ShelfItem type='WhatIsOurBusiness' addWidget={addWidget} />
      <ShelfItem type='FourHelpfulListsStratop' addWidget={addWidget} />
      <ShelfItem type='PatternsTrendsAnalysis' addWidget={addWidget} />
      <ShelfItem type='InternalPatternsAndTrends' addWidget={addWidget} />
      <ShelfItem type='ExternalPatternsAndTrends' addWidget={addWidget} />
      <ShelfItem type='LifeCycleAnalysis' addWidget={addWidget} />
      <ShelfItem type='FountainOfYouth' addWidget={addWidget} />
      <ShelfItem type='OpportunityRiskAnalysis' addWidget={addWidget} />
      <ShelfItem type='MarketSaturation' addWidget={addWidget} />
      <ShelfItem type='OpportunityMap' addWidget={addWidget} />
      <ShelfItem type='StrategicDashboard' addWidget={addWidget} />
      <ShelfItem type='CoreAssumptions' addWidget={addWidget} />
      <ShelfItem type='OurPrimaryCustomer' addWidget={addWidget} />
      <ShelfItem type='OurPrimaryCustomersValues' addWidget={addWidget} />
      <ShelfItem type='ValueBuildingCycle' addWidget={addWidget} />
      <ShelfItem type='OurMission' addWidget={addWidget} />
      <ShelfItem type='OurVision' addWidget={addWidget} />
      <ShelfItem type='OurBigIdeaCoreStrategies' addWidget={addWidget} />
      <ShelfItem type='CoreValuesStratop' addWidget={addWidget} />
      <ShelfItem type='ActionInitiativesLongList' addWidget={addWidget} />
      <ShelfItem type='ActionInitiativesProfile' addWidget={addWidget} />
      <ShelfItem type='SituationalAnalysis' addWidget={addWidget} />
      <ShelfItem type='ActionInitiativePlan' addWidget={addWidget} />
      <ShelfItem type='GenericTable' addWidget={addWidget} />
    </Shelf>
  );
};

StratOpShelf.propTypes = {
  addWidget: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isCloseable: PropTypes.bool.isRequired,
  openShelf: PropTypes.func.isRequired,
  closeShelf: PropTypes.func.isRequired,
};


let LifePlanShelf = ({ addWidget, isOpen, isCloseable, openShelf, closeShelf }) => {
  return (
    <Shelf
      isOpen={isOpen}
      isCloseable={isCloseable}
      openShelf={openShelf}
      closeShelf={closeShelf}
    >
      <ShelfItem type='LifePlanObjectives' addWidget={addWidget} />
      <ShelfItem type='FourHelpfulLists' addWidget={addWidget} />
      <ShelfItem type='TurningPointProfile' addWidget={addWidget} />
      <ShelfItem type='TurningPointLearningsLifeplan' addWidget={addWidget} />
      <ShelfItem type='TalentHeartAssessment' addWidget={addWidget} />
      <ShelfItem type='ThinkingWavelengthLifeplan' addWidget={addWidget} />
      <ShelfItem type='InternalWiring' addWidget={addWidget} />
      <ShelfItem type='ReplenishmentCycle' addWidget={addWidget} />
      <ShelfItem type='CoreValuesLifeplan' addWidget={addWidget} />
      <ShelfItem type='MyLifeDashboard' addWidget={addWidget} />
      <ShelfItem type='LifePerspectiveFilter' addWidget={addWidget} />
      <ShelfItem type='VocationalGating' addWidget={addWidget} />
      <ShelfItem type='LifeInitiativesProfile' addWidget={addWidget} />
      <ShelfItem type='TimeAssessment' addWidget={addWidget} />
      <ShelfItem type='LifePlanAccountability' addWidget={addWidget} />
      <ShelfItem type='LifePlanLearnings' addWidget={addWidget} />
      <ShelfItem type='FourHelpfulListsMatrix' addWidget={addWidget} />
      <ShelfItem type='GenericTable' addWidget={addWidget} />
    </Shelf>
  );
};

LifePlanShelf.propTypes = {
  addWidget: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isCloseable: PropTypes.bool.isRequired,
  openShelf: PropTypes.func.isRequired,
  closeShelf: PropTypes.func.isRequired,
};


class UnloadAndNavigationPrompt extends Component {
  /*
   * React-router's navigation Prompt does not prompt before unload
   * https://github.com/ReactTraining/react-router/issues/4272
   */
  componentDidMount() {
    window.addEventListener('beforeunload', this.showUnsavedChangesConfirmation);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.showUnsavedChangesConfirmation);
  }

  showUnsavedChangesConfirmation = (event) => {
    if (this.props.when) {
      return event.returnValue = this.props.message;
    }
  }

  render() {
    return <Prompt
      message={this.props.message}
      when={this.props.when}
    />;
  }
}
UnloadAndNavigationPrompt.propTypes = {
  message: PropTypes.string.isRequired,
  when: PropTypes.bool.isRequired,
};
UnloadAndNavigationPrompt.defaultProps = {
  when: true,
};


class Editor extends Component {
  state = {
    openShelf: null,
    shareModalOpen: false,
    metadataEditorOpen: false,
  }

  componentDidMount() {
    this.props.loadPlaybook(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.clearPlaybook();
  }

  render() {
    if (!this.props.playbook) {
      return null;
    }

    const playbookState = this.props.playbook;
    const playbook = removeUndoState(playbookState);
    const viewUrl = `/viewer/${encodeURIComponent(playbook.id)}`;

    if (this.props.playbook.permission_level < PermissionLevel.EDIT) {
      return <Redirect to={viewUrl} />;
    }

    let {save, saveState, undo, redo, saveMetadata} = this.props;

    const isDirty = !_.isEqual(saveState.savedDocument, removeClientSideState(playbookState).document);

    return (
      <div className={classNames('editor', playbook.type)}>
        <Helmet
          title={`Playbook Editor - ${playbook.client_name}'s ${PLAYBOOK_TYPES[playbook.type]}`}
        />
        <UnloadAndNavigationPrompt
          message="You have unsaved changes. Are you sure you want to leave?"
          when={isDirty || saveState.saving}
        />
        <div className="playbookMeta">
          <span className="title">{playbook.client_name}&rsquo;s {PLAYBOOK_TYPES[playbook.type]}</span>
          <button className="save" onClick={save} disabled={!isDirty || saveState.saving}>
            {saveState.saving ? 'Saving' : (isDirty ? 'Save' : 'Saved')}
          </button>
          <button
            className="redo"
            onClick={redo}
            disabled={playbookState.document.future.length === 0}
          >
            Redo
            <RedoIcon />
          </button>
          <button
            className="undo"
            onClick={undo}
            disabled={playbookState.document.past.length === 0}
          >
            <UndoIcon />
            Undo
          </button>
          <Link className="view" to={viewUrl}>
            <ViewIcon />
            View
          </Link>
          {
            playbook.permission_level >= PermissionLevel.MANAGE ?
              <button
                className="share"
                onClick={() => this.setState({shareModalOpen: !this.state.shareModalOpen})}
              >
                Share
              </button>
            :
              null
          }

          {
            playbook.permission_level >= PermissionLevel.TRANSCRIBER ?
              <button
                className="editMetadata"
                onClick={() => this.setState({metadataEditorOpen: true})}
              >
                Edit Playbook Details
              </button>
            :
              null
          }

          {
            this.state.metadataEditorOpen ?
              <MetadataEditor
                onFormSubmit={(data) => {
                  return saveMetadata(playbook.id, data)
                    .then(() =>
                      this.setState({metadataEditorOpen: false})
                    )
                    .catch((err) => {
                      throw new SubmissionError(err.errors);
                    });
                }}
                onCancel={() =>
                  this.setState({metadataEditorOpen: false})
                }
                playbook={playbook}
                submitText="Save"
              />
            :
              null
          }

          {
            this.state.shareModalOpen ?
              <ShareModal
                closeModal={() => this.setState({shareModalOpen: false })}
                playbook={playbook}
              />
            :
              null
          }
        </div>

        <Outline>
          <li key="title" className="locked title">
            <a onClick={this.scrollToTop}><i/>Title Page</a>
          </li>
          <li key="process" className="locked process">
            <a onClick={this.scrollToTop}><i/>The Paterson Process</a>
          </li>
          <li key="planOnAPage" className="locked planOnAPage">
            <a onClick={this.scrollToTop}><i/>Plan on a Page</a>
          </li>
          {
            playbook.document.widgets.map((widget, i) =>
              <OutlineItem key={widget.id} widget={widget} widgetIndex={i} />
            )
          }
        </Outline>

        <section
          className="chartList"
        >
          <section className="locked playbookChart" ref={(e) => this.titlePage = e}>
            <h1 className="chartTitle">Title Page</h1>
          </section>
          <section className="locked playbookChart">
            <h1 className="chartTitle">The Paterson Process</h1>
          </section>
          <section className="locked playbookChart">
            <h1 className="chartTitle">Understanding Perspective</h1>
          </section>
          <section className="locked playbookChart">
            <h1 className="chartTitle">Plan on a Page</h1>
          </section>

          { this.getShelf(0) }
          {
            // This reduce dance is necesary instead of a map because each
            // widget gets turned into two <li>s. The map function could return
            // a two-element array, but then react doesn't respect the `key
            // attributes and the whole list gets rerendered.
            playbook.document.widgets.reduce((items, widget, i) =>
              items.concat([
                <EditableWidget
                  data={widget}
                  key={widget.id}
                  index={i}
                  scrolledTo={playbookState.document.scrolledToIndex === widget.id}
                />,
                this.getShelf(i + 1, widget.id)
              ]),
              []
            )
          }
        </section>
      </div>
    );
  }

  getShelf(index, id) {
    const playbook = this.props.playbook;
    const Component = playbook.type === 'lifeplan' ? LifePlanShelf : StratOpShelf;
    return <Component
      key={`shelf-${id}`}
      isOpen={this.state.openShelf === index || index === playbook.document.present.widgets.length}
      isCloseable={index !== playbook.document.present.widgets.length}
      openShelf={() => this.openShelf(index)}
      closeShelf={this.closeShelf}
      addWidget={(type, data) => {
        this.props.addWidget(type, index, data);
        this.closeShelf();
      }}
    />;
  }

  openShelf = (i) => {
    this.setState({
      openShelf: i,
    });
  }

  closeShelf = () => {
    this.setState({
      openShelf: null,
    });
  }

  scrollToTop = () => {
    this.titlePage.scrollIntoView({behavior: "smooth"});
  }
}

Editor.contextTypes = {
  router: PropTypes.object,
};

Editor.propTypes = {
  loadPlaybook: PropTypes.func.isRequired,
  playbook: PropTypes.object,
  save: PropTypes.func.isRequired,
  saveMetadata: PropTypes.func.isRequired,
  saveState: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  undo: PropTypes.func.isRequired,
  redo: PropTypes.func.isRequired,
  addWidget: PropTypes.func.isRequired,
  clearPlaybook: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

Editor = RequireLogin(Editor);

Editor = connect(
  (state) => ({
    saveState: state.saveState,
    playbook: state.playbook,
    session: state.session,
  }),
  {
    save: actions.save,
    saveMetadata: actions.saveMetadata,
    undo: ActionCreators.undo,
    redo: ActionCreators.redo,
    addWidget: actions.addWidget,
    loadPlaybook: actions.loadPlaybook,
    clearPlaybook: actions.clearPlaybook,
  }
)(Editor);

export default Editor;
