import React from 'react';


export function UndoIcon() {
  return (
    <svg
      width="12px"
      height="10px"
      viewBox="776 118 14 13"
      version="1.1"
    >
      <g
        id="back"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(776.730469, 118.472656)"
      >
        <path
          d="M13,4 L13,10 C13,11.103 12.104,12 11,12 L5,12 L5,9 L10,9 L10,5 L4,5 L4,7 L0,3.5 L4,0 L4,2 L11,2 C12.104,2 13,2.896 13,4 Z"
          id="Shape"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}


export function RedoIcon() {
  return (
    <svg
      width="12px"
      height="10px"
      viewBox="806 118 14 13"
      version="1.1"
    >
      <path
        d="M806.730469,122.472656 L806.730469,128.472656 C806.730469,129.575656 807.626469,130.472656 808.730469,130.472656 L814.730469,130.472656 L814.730469,127.472656 L809.730469,127.472656 L809.730469,123.472656 L815.730469,123.472656 L815.730469,125.472656 L819.730469,121.972656 L815.730469,118.472656 L815.730469,120.472656 L808.730469,120.472656 C807.626469,120.472656 806.730469,121.368656 806.730469,122.472656 Z"
        id="redo"
        stroke="none"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export function ViewIcon() {
  return (
    <svg
      width="14px"
      height="10px"
      viewBox="4006 28214 20 12"
      version="1.1"
    >
      <g
        id="eye"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(4006.000000, 28214.000000)"
      >
        <g
          id="Eye"
          fillRule="nonzero"
          fill="currentColor"
        >
          <path
            d="M10,0.4 C3.439,0.4 0,5.232 0,6 C0,6.766 3.439,11.6 10,11.6 C16.56,11.6 20,6.766 20,6 C20,5.232 16.56,0.4 10,0.4 Z M10,10.307 C7.545,10.307 5.555,8.379 5.555,6 C5.555,3.621 7.545,1.691 10,1.691 C12.455,1.691 14.444,3.621 14.444,6 C14.444,8.379 12.455,10.307 10,10.307 Z M10,6 C9.593,5.553 10.663,3.846 10,3.846 C8.772,3.846 7.777,4.811 7.777,6 C7.777,7.189 8.772,8.154 10,8.154 C11.227,8.154 12.223,7.189 12.223,6 C12.223,5.453 10.346,6.379 10,6 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
}



export function HideIcon() {
  return (
    <svg
    width="14px"
    height="10px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512">
      <g id="eye-slash">
        <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/>
      </g>
    </svg>
  );
}


export function EditIcon() {
  return (
    <svg
      width="12px"
      height="10px"
      viewBox="918 104 8 14"
      version="1.1"
    >
      <path
        d="M924.529999,106.847998 C924.998335,106.555288 925.140709,105.938338 924.847998,105.470001 C924.555288,105.001665 923.938338,104.859291 923.470001,105.152002 L918.470001,110.152002 C917.843333,110.543669 917.843333,111.456331 918.470001,111.847998 L923.470001,116.847998 C923.938338,117.140709 924.555288,116.998335 924.847998,116.529999 C925.140709,116.061662 924.998335,115.444712 924.529999,115.152002 L920.886796,111 L924.529999,106.847998 Z"
        id="Line"
        stroke="none"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}
