import React from 'react';
import CycleChartBase from './CycleChartBase.js';
import EditableTitle from '../EditableTitle';


export const INITIAL_STATE = {
  title: 'Value Building Cycle',
  description: 'The purpose of this tool is to create a visual picture of the primary customer and what they need, want, and value from us.',
  center: '',
  items: [
    {text: '', status: null},
  ],
};


export default class ValueBuildingCycle extends CycleChartBase {
  getHeader() {
    return (
      [
        <p key='0' className="stickyTitle">Value Building Cycle</p>,
        <EditableTitle key='1' data={this.props.data} onEditData={this.props.onEditData} />
      ]
    );
  }
}
