import {ContentState, convertFromHTML, convertToRaw} from 'draft-js';

import { EMPTY_COLUMN as LIFE_PERSPECTIVE_EMPTY_COLUMN } from "./components/widgets/LifePerspectiveFilter.js";
import { INITIAL_STATE as VOCATIONAL_GATING_INITIAL_STATE } from "./components/widgets/VocationalGating.js";
import { INITIAL_STATE as TURNING_POINT_PROFILE_INITIAL_STATE } from "./components/widgets/TurningPointProfile.js";
import { INITIAL_STATE as LIFE_INITIATIVES_PROFILE_INITIAL_STATE } from "./components/widgets/LifeInitiativesProfile.js";
import { INITIAL_STATE as FOUR_HELPFUL_LISTS_INITIAL_STATE } from "./components/widgets/FourHelpfulLists.js";
import { INITIAL_STATE as TALENT_HEART_ASSESSMENT_INITIAL_STATE } from "./components/widgets/TalentHeartAssessment.js";
import { INITIAL_STATE as GENERIC_TABLE_INITIAL_STATE } from "./components/widgets/GenericTable.js";
import { INITIAL_STATE as LIFEPLAN_ACCOUNTABILITY_INITIAL_STATE } from "./components/widgets/LifePlanAccountability.js";
import { INITIAL_STATE as MY_LIFE_DASHBOARD_INITIAL_STATE } from "./components/widgets/MyLifeDashboard.js";
import { INITIAL_STATE as REPLENISHMENT_CYCLE_INITIAL_STATE } from "./components/widgets/ReplenishmentCycle.js";
import { INITIAL_STATE as VALUE_BUILDING_CYCLE_INITIAL_STATE } from "./components/widgets/ValueBuildingCycle.js";
import { INITIAL_STATE as OUR_MISSION_INITIAL_STATE } from "./components/widgets/OurMission.js";
import { INITIAL_STATE as STRATEGIC_DASHBOARD_INITIAL_STATE } from "./components/widgets/StrategicDashboard.js";
import { INITIAL_STATE as OUR_PRIMARY_CUSTOMER_INITIAL_STATE } from "./components/widgets/OurPrimaryCustomer.js";
import { INITIAL_STATE as OUR_PRIMARY_CUSTOMERS_VALUES_INITIAL_STATE } from "./components/widgets/OurPrimaryCustomersValues.js";
import { INITIAL_STATE as ACTION_INITIATIVES_PROFILE_INITIAL_STATE } from "./components/widgets/ActionInitiativesProfile.js";


function richTextFromHTML(s) {
  if (s === '') {
    // This is required for tests to work, workaround for
    // <https://github.com/tmpvar/jsdom/issues/1779>.
    return null;
  } else {
    return convertToRaw(ContentState.createFromBlockArray(convertFromHTML(s)));
  }
}

/*
 * title is used for the title on the shelf and in the outline
 * It is called with no arguments for the shelf and with the widget data for the outline.
 */
const WIDGET_METADATA = {
  LifePlanObjectives: {
    defaults: {
      title: "LifePlan Objectives",
      description: "This tool helps us to identify and name your primary and secondary objectives as we begin the LifePlan process.",
      rows: [
        [
          '<h2>Objectives</h2>', '<h2>Specifics</h2>', '<h2>Accomplished</h2>'
        ].map(richTextFromHTML),
        [richTextFromHTML('Personal'), null, null],
        [richTextFromHTML('Family'), null, null],
        [richTextFromHTML('Work'), null, null],
        [richTextFromHTML('Kingdom'), null, null],
        [richTextFromHTML('Community'), null, null],
      ],
      highlightedRows: [true, false, false, false, false, false],
      highlightedColumns: [false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'LifePlanObjectives',
    },
    realType: 'GenericTable',
    title: () => "LifePlan Objectives",
  },
  TurningPointLearningsLifeplan: {
    defaults: {
      title: "Turning Point Learnings",
      description: "The purpose of this tool is to capture the key learnings from your story. Every story has major and minor plot lines, messages woven throughout, and insights into the main characters in the story.",
      rows: [
        ['<h2>Message</h2>'].map(richTextFromHTML),
        [null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false],
      isNumbered: true,
      fixedLayout: false,
      originalType: 'TurningPointLearningsLifeplan',
    },
    realType: 'GenericTable',
    title: () => "Turning Point Learnings",
  },
  CoreValues: {
    title: () => "Core Values"
  },
  CoreValuesLifeplan: {
    defaults: {
      title: "Core Values",
      description: "Core Values are those irrefutable foundations from which we think, live, and relate. They are “core,” not aspirational. Knowing our Core Values allows us to live more true to who we are.",
      rows: [
        ['<h2>VALUES</h2>', '<h2>SPECIFICS</h2>'].map(richTextFromHTML),
        [null, null],
      ],
      statuses: [null, null],
      highlightedRows: [true, false],
      highlightedColumns: [false],
      isNumbered: true,
      fixedLayout: false,
      originalType: 'CoreValuesLifeplan',
    },
    realType: 'CoreValues',
    title: () => "Core Values",
  },
  TimeAssessment: {
    defaults: {
      title: "Time Assessment",
      description: "The purpose of this tool is to assess where our time is going and where we want it to go. This helps us to determine what strategic steps must be taken to live our LifePlan with healthy integration across all life domains.\n\nKey Assumptions: 12 hours per day | 7 days per week | 4.33 weeks per month | 364 hours per month",
      rows: [
        [
          '<h2>Life Domain</h2>',
          'Current Hours/Month',
          'Planned Hours/Month',
          'Current % of Month',
          'Planned % of Month',
          'Notes',
        ].map(richTextFromHTML),
        ['Personal', '', '', '', '', ''].map(richTextFromHTML),
        ['Family', '', '', '', '', ''].map(richTextFromHTML),
        ['Vocation', '', '', '', '', ''].map(richTextFromHTML),
        ['Church', '', '', '', '', ''].map(richTextFromHTML),
        ['Community', '', '', '', '', ''].map(richTextFromHTML),
        ['<h2>Totals</h2>', '364', '364', '100%', '100%', ''].map(richTextFromHTML),
      ],
      highlightedRows: [true, false, false, false, false, false, true],
      highlightedColumns: [false, false, false, false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'TimeAssessment',
    },
    realType: 'GenericTable',
    title: () => "Time Assessment",
  },
  LifePlanLearnings: {
    defaults: {
      title: "LifePlan Learnings",
      description: "The purpose of this tool is to capture in words your key learnings over your LifePlan.",
      rows: [
        [richTextFromHTML('<h2>Specifics<h2>')],
        [null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false],
      isNumbered: true,
      fixedLayout: false,
      originalType: 'LifePlanLearnings',
    },
    realType: 'GenericTable',
    title: () => "LifePlan Learnings",
  },
  FourHelpfulListsMatrix: {
    defaults: {
      title: "The Four Helpful Lists Matrix",
      description: "As you review and refresh your LifePlan in the future, use the 4 Helpful Lists tool to gain fresh perspective.",
      rows: [
        [
          '<h2>Life Domains<h2>',
          'What is Right? (Optimize)',
          'What is Wrong? (Change)',
          'What is Confused? (Clarify)',
          'What is Missing? (Add)',
        ].map(richTextFromHTML),
        ['Personal', '', '', '', ''].map(richTextFromHTML),
        ['Family', '', '', '', ''].map(richTextFromHTML),
        ['Work', '', '', '', ''].map(richTextFromHTML),
        ['Kingdom', '', '', '', ''].map(richTextFromHTML),
        ['Community', '', '', '', ''].map(richTextFromHTML),
      ],
      highlightedRows: [true, false, false, false, false, false],
      highlightedColumns: [true, false, false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'FourHelpfulListsMatrix',
    },
    realType: 'GenericTable',
    title: () => "The Four Helpful Lists Matrix",
  },
  ThinkingWavelengthLifeplan: {
    defaults: {
      thinkingWavelength: 5,
      currentJob: 4,
      title: "Thinking Wavelength",
      description: "How are you hard-wired in your thinking? How do you relate to change, risk, and opportunity? Where is your high-contribution zone, your “sweet spot”?",
    },
    title: (data={}) => data.title || "Thinking Wavelength",
  },
  ThinkingWavelengthStratop: {
    defaults: {
      items: [],
      title: "Thinking Wavelength",
      description: "How are you hard-wired in your thinking? How do you relate to change, risk, and opportunity? Where is your high-contribution zone, your “sweet spot”?",
    },
    title: (data={}) => data.title || "Thinking Wavelength",
  },
  ReplenishmentCycle: {
    defaults: REPLENISHMENT_CYCLE_INITIAL_STATE,
    title: (data={}) => data.title || "Replenishment Cycle",
  },
  InternalWiring: {
    defaults: {
      primaryDriver: 0,
      primaryComfortZone: 0,
      title: "Internal Wiring",
      description: "Identify what drives you and clarify your zone of comfort.",
    },
    title: (data={}) => data.title || "Internal Wiring",
  },
  LifeCycleAnalysis: {
    defaults: {
      items: [],
      title: "Life Cycle Analysis",
      description: "Everything you do as an organization has a life-cycle. Acknowledge the truth about where your products and/or services are in their life cycle.",
    },
    title: (data={}) => data.title || "Life Cycle Analysis",
  },
  LifePerspectiveFilter: {
    defaults: {
      columns: [LIFE_PERSPECTIVE_EMPTY_COLUMN],
      title: "Life Perspective Filter",
      description: "We run your options and opportunities through the layers of perspective in your LifePlan. This helps you to identify the front-runner opportunity and to eliminate misfits.",
    },
    title: (data={}) => data.title || "Life Perspective Filter",
  },
  VocationalGating: {
    defaults: VOCATIONAL_GATING_INITIAL_STATE,
    title: (data={}) => data.title || "Vocational Gating Process",
  },
  FountainOfYouth: {
    defaults: {
      title: "Fountain of Youth",
      description: "This tool identifies opportunities to be sensed and seized. Having a vibrant fountain of youth keeps an organization young and evergreen.",
      sensing: null,
      seizing: null,
      building: null,
      reaping: null,
    },
    title: (data={}) => data.title || "Fountain of Youth",
  },
  TurningPointProfile: {
    defaults: TURNING_POINT_PROFILE_INITIAL_STATE,
    title: (data={}) => data.title || "Turning Point Profile",
  },
  OpportunityRiskAnalysis: {
    defaults: {
      title: "Opportunity-Risk Analysis",
      description: "The purpose of this tool is to plot the opportunity and risk of a product, service, relationship, market, etc. Entrepreneurial opportunities, once captured and harnessed, can be strategically moved to the Gold Mine quadrant. On the other hand, initiatives in the Foolishness and Security quadrants may need to be laid to rest in order to redirect resources to higher opportunities.",
      items: [],
    },
    title: (data={}) => data.title || "Opportunity - Risk Analysis",
  },
  GenericTable: {
    defaults: GENERIC_TABLE_INITIAL_STATE,
    title: (data={}) => data.title || "Generic Table",
  },
  LifeInitiativesProfile: {
    defaults: LIFE_INITIATIVES_PROFILE_INITIAL_STATE,
    title: (data={}) => data.title || "Life Initiatives Profile",
  },
  FourHelpfulLists: {
    defaults: FOUR_HELPFUL_LISTS_INITIAL_STATE,
    title: (data={}) => data.title || "Four Helpful Lists",
  },
  TalentHeartAssessment: {
    defaults: TALENT_HEART_ASSESSMENT_INITIAL_STATE,
    title: (data={}) => data.title || "Talent Heart Assessment",
  },
  LifePlanAccountability: {
    defaults: LIFEPLAN_ACCOUNTABILITY_INITIAL_STATE,
    title: (data={}) => data.title || "LifePlan Accountability",
  },
  TurningPoints: {
    defaults: {
      title: "Turning Points",
      description: "Every organization has a story. This Turning Points tool helps us to chart our story and capture insights and learnings that are so valuable to perspective and planning.",
      rows: [
        [
          'Year',
          'Specifics',
          'Impact on us',
        ].map(richTextFromHTML),
        [null, null, null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'TurningPoints',
    },
    title: () => "Turning Points",
    realType: 'GenericTable',
  },
  TurningPointLearningsStratop: {
    defaults: {
      title: "Turning Point Learnings",
      description: "The purpose of this tool is to capture and catalog the primary learnings from the Turning Point Profile. By doing so, we gain valuable perspective into how we got to where we are at today.",
      rows: [
        [richTextFromHTML('<h2>Specifics</h2>')],
        [null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false],
      isNumbered: true,
      fixedLayout: false,
      originalType: 'TurningPointLearningsStratop',
    },
    title: () => "Turning Point Learnings",
    realType: 'GenericTable',
  },
  WhatIsOurBusiness: {
    defaults: {
      title: "What is our Business?",
      description: "This tool captures the current profile of the organization. This isn't about our desired future yet; we are simply identifying the truth about where we stand today. This chart is a valuable reference to the conversations we have in the planning phase of StratOp around Mission and Vision.",
      rows: [
        [
          '<h2>Questions</h2>',
          '<h2>Learnings</h2>'
        ].map(richTextFromHTML),
        [null, null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'WhatIsOurBusiness',
    },
    title: () => "What is our Business",
    realType: 'GenericTable',
  },
  FourHelpfulListsStratop: {
    defaults: {
      title: "Four Helpful Lists",
      description: "This tool answers the question, “Where Are We Now?” It identifies what is right, what is wrong, what is confused, and what is missing. As a result of gaining perspective into our organization through these four questions, Core Issues emerge that will require attention, leadership, and resources.",
      rows: [
        [
          '<h2>Categories</h2>',
          '<h2>What is Right? (Optimize)</h2>',
          '<h2>What is Wrong? (Change)</h2>',
          '<h2>What is Confused? (Clarify)</h2>',
          '<h2>What is Missing? (Add)</h2>',
          '<h2>Core Issues</h2>'
        ].map(richTextFromHTML),
        [null, null, null, null, null, null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false, false, false, false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'FourHelpfulListsStratop',
    },
    title: () => "Four Helpful Lists",
    realType: 'GenericTable',
  },
  PatternsTrendsAnalysis: {
    defaults: {
      title: "Patterns/Trends Analysis",
      description: "This tool answers the question, “What is changing around us?” In today’s world, the rate of change continues to accelerate. This tool forces us to observe the winds of change and to perceive the future in light of those trends. We consider the specific implications to us, which informs what needs to change in order to navigate these trends.",
      rows: [
        [
          '<h2>Patterns/Trends</h2>',
          '<h2>Where it\'s Headed</h2>',
          '<h2>Implications for us</h2>',
        ].map(richTextFromHTML),
        [null, null, null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'PatternsTrendsAnalysis',
    },
    title: () => "Patterns/Trends Analysis",
    realType: 'GenericTable',
  },
  InternalPatternsAndTrends: {
    defaults: {
      title: "Internal Patterns & Trends",
      description: "This tool answers the question, “What is changing within us?” In today’s world, the rate of change continues to accelerate. This tool forces us to observe the winds of change and to perceive the future in light of those trends. We consider the specific implications to us, which informs what needs to change in order to navigate these trends.",
      rows: [
        [
          '<h2>Patterns/Trends</h2>',
          '<h2>Where it\'s Headed</h2>',
          '<h2>Implications for us</h2>',
        ].map(richTextFromHTML),
        [null, null, null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'InternalPatternsAndTrends',
    },
    title: () => "Internal Patterns & Trends",
    realType: 'GenericTable',
  },
  ExternalPatternsAndTrends: {
    defaults: {
      title: "External Patterns & Trends",
      description: "The number of factors that affect an organization are extensive. We must have built-in adaptation in our planning process in order to seize opportunity and manage risk. This tool helps us do this by identifying current external patterns and trends, projecting where they’ll be in the future, and by identifying the implications for our organization.",
      rows: [
        [
          '<h2>Patterns/Trends</h2>',
          '<h2>Where it\'s Headed</h2>',
          '<h2>Implications for us</h2>',
        ].map(richTextFromHTML),
        [null, null, null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'ExternalPatternsAndTrends',
    },
    title: () => "External Patterns & Trends",
    realType: 'GenericTable',
  },
  CoreAssumptions: {
    defaults: {
      title: "Core Assumptions",
      description: "The purpose of this tool is to state the strategic, operational, and financial assumptions that emerged from the perspective phase of StratOp. These assumptions form the foundation of our plan.",
      rows: [
        [
          richTextFromHTML('<h2>Strategic</h2>'),
          null
        ],
        [
          richTextFromHTML('<h2>Operational</h2>'),
          null
        ],
        [
          richTextFromHTML('<h2>Financial</h2>'),
          null
        ]
      ],
      highlightedRows: [false, false, false],
      highlightedColumns: [true, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'CoreAssumptions',
    },
    title: () => "Core Assumptions",
    realType: 'GenericTable',
  },
  OurVision: {
    defaults: {
      title: "Our Vision",
      description: "The purpose of this tool is to help us to define our Vision: where we stand, where we're headed, and how we're going to get there. Vision must be rooted in the truth of our Mission, and built upon the truth and perspective we have gained to this point.",
      rows: [
        [
          "<h2>Where We Stand</h2>",
          "<h2>Where We're Heading</h2>",
          "<h2>How We'll Get There</h2>"
        ].map(richTextFromHTML),
        [null, null, null]
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'OurVision',
    },
    title: () => "Our Vision",
    realType: 'GenericTable',
  },
  OurBigIdeaCoreStrategies: {
    defaults: {
      title: "Our \"Big Idea\" Core Strategies",
      description: "The purpose of this tool is to identify the strategic, operational, and financial elements that differentiate us.",
      rows: [
        [
          '<h2>Core Strategies</h2>',
          '<h2>Specifics</h2>',
        ].map(richTextFromHTML),
        [null, null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'OurBigIdeaCoreStrategies',
    },
    title: () => "Our \"Big Idea\" Core Strategies",
    realType: 'GenericTable',
  },
  CoreValuesStratop: {
    defaults: {
      title: "Core Values",
      description: "The purpose of this tool is to guide the team to identify the core values that exist or need to exist in order to create a healthy organizational culture, and operational boundaries. These values speak to how we think, live, and relate.",
      rows: [
        [
          '<h2>Values</h2>',
          '<h2>Specifics</h2>',
        ].map(richTextFromHTML),
        [null, null],
      ],
      statuses: [null, null],
      highlightedRows: [true, false],
      highlightedColumns: [false, false],
      isNumbered: true,
      fixedLayout: false,
      originalType: 'CoreValuesStratop',
    },
    title: () => "Core Values",
    realType: 'CoreValues',
  },
  SituationalAnalysis: {
    defaults: {
      title: "Situational Analysis",
      description: "In the StratOp spirit of \"perspective before planning\", we zoom out and get perspective on each Action Initiative, before putting together a specific Action Initiative Plan.",
      rows: [
        [
          '<h2>Strengths</h2>',
          '<h2>Weaknesses</h2>',
          '<h2>Opportunities</h2>',
          '<h2>Threats</h2>',
          '<h2>Action Needed</h2>',
        ].map(richTextFromHTML),
        [null, null, null, null, null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false, false, false, false, true],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'SituationalAnalysis',
    },
    title: () => "Situational Analysis",
    realType: 'GenericTable',
  },
  ActionInitiativesLongList: {
    defaults: {
      title: "Action Initiatives Long List",
      description: "As we look back at every one of the tools, we long-list potential Action Initiatives.",
      rows: [
        [null, null],
        [null, null],
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'ActionInitiativesLongList',
    },
    title: () => "Action Initiatives Long List",
    realType: 'GenericTable',
  },
  OpportunityMap: {
    defaults: {
      title: "Opportunity Map",
      description: "A sound plan will say where the growth comes from. This tool helps the team to consolidate what we have discovered in the previous opportunity tools and to identify opportunity projections in meaningful ways over an agreed-upon timeline.",
      rows: [
        ['<h2>Growth Options</h2>', '', '', '', '', ''].map(richTextFromHTML),
        ['<h2>Growth by Served Market</h2>', '', '', '', '', ''].map(richTextFromHTML),
        ['<h2>Growth by Share Capture</h2>', '', '', '', '', ''].map(richTextFromHTML),
        ['<h2>Growth by New Markets</h2>', '', '', '', '', ''].map(richTextFromHTML),
        ['<h2>TOTALS</h2>', '', '', '', '', ''].map(richTextFromHTML),
      ],
      highlightedRows: [true, false, false, false, true],
      highlightedColumns: [true, false, false, false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'OpportunityMap',
    },
    title: () => "Opportunity Map",
    realType: 'GenericTable',
  },
  TurningPointProfileStratop: {
    defaults: {
      title: "Turning Point Profile",
      description: "This tool charts and describes the turning points in chronological order. It also captures the relevant impact and insight of each turn on strategic, operational, and financial categories. By doing so, we can see the unique story of the organization.",
      rows: [
        ['<h2>Categories</h2>', '', '', '', ''].map(richTextFromHTML),
        ['', '', '', '', ''].map(richTextFromHTML),
        ['', '', '', '', ''].map(richTextFromHTML),
        ['', '', '', '', ''].map(richTextFromHTML),
        ['', '', '', '', ''].map(richTextFromHTML)
      ],
      highlightedRows: [true, false, false, false, false],
      highlightedColumns: [true, false, false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'TurningPointProfileStratop',
    },
    title: () => "Turning Point Profile",
    realType: 'GenericTable',
  },
  MarketSaturation: {
    defaults: {
      title: "Market Saturation",
      description: "There is a logic and strategy to saturate markets with products. This tool highlights this logic and charts current revenue in each quadrant.",
      rows: [
        [
          '<h2>CURRENT PRODUCTS - CURRENT MARKETS</h2>',
          '<h2>CURRENT PRODUCTS - NEW MARKETS</h2>'
        ].map(richTextFromHTML),
        [null, null],
        [
          '<h2>NEW PRODUCTS - CURRENT MARKETS</h2>',
          '<h2>NEW PRODUCTS - NEW MARKETS</h2>'
        ].map(richTextFromHTML),
        [null, null]
      ],
      highlightedRows: [true, false, true, false],
      highlightedColumns: [false, false],
      isNumbered: false,
      fixedLayout: true,
      originalType: 'MarketSaturation',
    },
    title: () => "Market Saturation",
    realType: 'GenericTable',
  },
  ActionInitiativePlan: {
    defaults: {
      title: "Action Initiative Plan",
      description: `The purpose of this tool is to create an in-perspective action plan for each Action Initiative from the W.I.N. Wheel. Each Action Plan is detailed in nature, accountable to individuals, and targeted towards the stated objective, key deliverables, and timelines.

Leader:

Team:

Plan Name:

Objectives:

Initiative Concerns:

Deliverables: `,
      rows: [
        [
          '<h2>Action Steps</h2>',
          '<h2>By?</h2>',
          '<h2>Status</h2>',
          '<h2>Accountability</h2>',
        ].map(richTextFromHTML),
        [
          null,
          null,
          null,
          null,
        ]
      ],
      highlightedRows: [true, false],
      highlightedColumns: [false, false, false, false],
      isNumbered: false,
      fixedLayout: false,
      originalType: 'ActionInitiativePlan',
    },
    title: () => "Action Initiative Plan",
    realType: 'GenericTable',
  },
  MyLifeDashboard: {
    defaults: MY_LIFE_DASHBOARD_INITIAL_STATE,
    title: (data={}) => data.title || "My Life Dashboard",
  },
  ValueBuildingCycle: {
    defaults: VALUE_BUILDING_CYCLE_INITIAL_STATE,
    title: () => "Value Building Cycle",
  },
  OurMission: {
    defaults: OUR_MISSION_INITIAL_STATE,
    title: (data={}) => data.title || "Our Mission",
  },
  StrategicDashboard: {
    defaults: STRATEGIC_DASHBOARD_INITIAL_STATE,
    title: () => "Strategic Dashboard",
  },
  OurPrimaryCustomer: {
    defaults: OUR_PRIMARY_CUSTOMER_INITIAL_STATE,
    title: (data={}) => data.title || "Our Primary Customer",
  },
  OurPrimaryCustomersValues: {
    defaults: OUR_PRIMARY_CUSTOMERS_VALUES_INITIAL_STATE,
    title: (data={}) => data.title || "Our Primary Customer's Values",
  },
  ActionInitiativesProfile: {
    defaults: ACTION_INITIATIVES_PROFILE_INITIAL_STATE,
    title: (data={}) => data.title || "Action Initiatives Profile",
  },
};

export default WIDGET_METADATA;
