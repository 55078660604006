import React, {Component} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import EditableTitle from '../EditableTitle';
import RichTextEditor from '../RichTextEditor.js';
import './OurPrimaryCustomer.scss';


export const INITIAL_STATE = {
  title: "Our Primary Customer",
  description: 'We can’t please everybody. We must understand who is at the bullseye of our target. Determining the primary customer can make or break a strategic plan. The purpose of this tool is to identify the primary and supporting customers of your client. Note: we did not say “secondary customer."',
  longList: null,
  supportingCustomers: null,
  primaryCustomer: null,
};


export function Section({name, title, data, onEditData}) {
  const changeCallback = onEditData ?
    (value) => onEditData(update(data, {
      [name]: {
        $set: value
      }
    }))
  :
    null;

  let className;
  if (data[name]) {
    if (data[name].blocks.length === 2) {
      className = 'two';
    } else if (data[name].blocks.length > 2) {
      className = 'many';
    }
  }

  return (
    <section
      className={className}
    >
      <h2>{title}</h2>

      <RichTextEditor
        value={data[name]}
        onChange={changeCallback}
        readonly={!onEditData}
      />
    </section>
  );
}

Section.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};


export default class OurPrimaryCustomer extends Component {
  render() {
    const {data, onEditData} = this.props;

    return (
      <div className="chart ourPrimaryCustomer">
        <p className="stickyTitle">{data.title}</p>
        <EditableTitle
          data={data}
          onEditData={onEditData}
        />
        <div className='ourPrimaryCustomerInner'>
          <Section
            name='longList'
            title='Long List'
            data={data}
            onEditData={onEditData}
          />
          <Section
            name='supportingCustomers'
            title='Supporting Customers'
            data={data}
            onEditData={onEditData}
          />
          <Section
            name='primaryCustomer'
            title='Primary Customer'
            data={data}
            onEditData={onEditData}
          />
        </div>
      </div>
    );
  }
}

OurPrimaryCustomer.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
