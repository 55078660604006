export default {
  VIEW: 0,
  EDIT: 1,
  MANAGE: 2,
  TRANSCRIBER: 3,
};

export const LABELS = {
  0: 'View',
  1: 'Edit',
  2: 'Manage',
};
