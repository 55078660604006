import React, { Component } from 'react';
import update from 'immutability-helper';
import _ from 'underscore';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import EditableTitle from '../EditableTitle';
import { InlineInput } from '../InlineInput.js';
import StatusAndTrendIndicator from '../StatusAndTrendIndicator.js';
import { ViewIcon, HideIcon } from '../icons.js';

import './FourHelpfulLists.scss';


const CONTROLLABILITY_VALUES = {
  1: 'full',
  2: 'partial',
  3: 'none'
};

const EMPTY_ROW = {item: "", "status": null, "controllability": 1};

export const INITIAL_STATE = {
  optimize: [EMPTY_ROW],
  change: [EMPTY_ROW],
  clarify: [EMPTY_ROW],
  add: [EMPTY_ROW],
  title: "Four Helpful Lists",
  description: 'This tool answers the question, “Where Am I Now?” It identifies what is right, what is wrong, what is confused, and what is missing. We want to optimize what is right, change what is wrong, clarify what is confused, and add what is missing.',
};


export default class FourHelpfulLists extends Component {
  state = {}

  render() {
    const { data, onEditData } = this.props;

    const sections = [
      {key: 'optimize', header: 'What is right? (Optimize)'},
      {key: 'change', header: 'What is wrong? (Change)'},
      {key: 'clarify', header: 'What is confused? (Clarify)'},
      {key: 'add', header: 'What is missing? (Add)'},
    ];

    return (
      <div className="chart fourHelpfulLists">
        <p className="stickyTitle">{data.title}</p>
        <EditableTitle
          data={data}
          onEditData={onEditData}
        />
        {
          onEditData ? (
            <div className="columnActions">
              <button
                onClick={() =>
                  onEditData({
                    ...data,
                    hideStatus: !data.hideStatus,
                  })
                }
              >
                {data.hideStatus ? <><ViewIcon /> Show</> : <><HideIcon /> Hide</>} Today's Status
              </button>
              <button
                onClick={() =>
                  onEditData({
                    ...data,
                    hideControllability: !data.hideControllability,
                  })
                }
              >
                {data.hideControllability ? <><ViewIcon /> Show</> : <><HideIcon /> Hide</>} Controllability
              </button>
            </div>
          ) : null
        }
        {
          sections.map((section, sectionIndex) =>
            <div className="fourHelpfulListsWrapper" key={sectionIndex}>
              <table>
                <thead className={section.key}>
                  <tr>
                    <th>{section.header}</th>
                    {data.hideStatus ? null : <th className="status-header">{"Today's Status"}</th>}
                    {data.hideControllability ? null : <th className="controllability-header">Controllability</th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    data[section.key].map((row, i) =>
                      <tr key={i}>
                        <td>
                          {
                            onEditData ?
                              <div>
                                <button
                                  title="Delete"
                                  className="delete"
                                  onClick={() =>
                                    onEditData(update(data, {
                                      [section.key]: {
                                        $splice: [
                                          [i, 1]
                                        ]
                                      }
                                    }))
                                  }
                                />
                                <InlineInput
                                  value={row.item}
                                  blankPlaceholder="Add status..."
                                  onChange={(value, enterPressed) => {
                                    let newData = update(data, {
                                      [section.key]: {
                                        [i]: {
                                          item: {
                                            $set: value
                                          }
                                        }
                                      }
                                    });

                                    if (enterPressed && i === data[section.key].length - 1) {
                                      newData = update(newData, {
                                        [section.key]: {
                                          $push: [EMPTY_ROW]
                                        }
                                      });
                                    }

                                    onEditData(newData);

                                    if (enterPressed) {
                                      this.setState({focusedIndex: {
                                        type: section.key,
                                        index: i + 1
                                      }});
                                    } else {
                                      this.setState({focusedIndex: null});
                                    }
                                  }}
                                  isFocused={
                                    _.isEqual(this.state.focusedIndex, {
                                      type: section.key,
                                      index: i
                                    })
                                  }
                                />
                              </div>
                            :
                              <span>{row.item}</span>
                          }
                        </td>
                        {
                          data.hideStatus ? null : (
                            <td>
                              <StatusAndTrendIndicator
                                value={row.status}
                                onChange={onEditData ?
                                  (value) => onEditData(update(data, {
                                    [section.key]: {
                                      [i]: {
                                        status: {
                                          $set: value
                                        }
                                      }
                                    }
                                  }))
                                :
                                  null
                              }
                              />
                            </td>
                          )
                        }
                        {
                          data.hideControllability ? null : (
                            <td>
                              {
                                onEditData ?
                                  <button
                                    className="controllabilityToggle"
                                    onClick={() =>
                                      onEditData(update(data, {
                                        [section.key]: {
                                          [i]: {
                                            controllability: {
                                              $set: ((row.controllability) % 3) + 1
                                            }
                                          }
                                        }
                                      }))
                                    }
                                  >
                                    <span
                                      className={classNames(
                                        'controllability',
                                        `controllability-${CONTROLLABILITY_VALUES[row.controllability]}`
                                      )}
                                    >
                                      {CONTROLLABILITY_VALUES[row.controllability]}
                                    </span>
                                  </button>
                                :
                                  <span
                                    className={classNames(
                                      'controllability',
                                      `controllability-${CONTROLLABILITY_VALUES[row.controllability]}`
                                    )}
                                  >
                                    {CONTROLLABILITY_VALUES[row.controllability]}
                                  </span>
                              }
                            </td>
                          )
                        }
                      </tr>
                    )
                  }
                  {
                    onEditData ?
                      <tr className="add">
                        <td className="add">
                          <button
                            className="add"
                            onClick={() => {
                              onEditData(update(data, {
                                [section.key]: {
                                  $push: [EMPTY_ROW]
                                }
                              }));

                              this.setState({focusedIndex: {
                                type: section.key,
                                index: data[section.key].length
                              }});
                            }}
                          >
                            <span className="plus">+</span> Add Status
                          </button>
                        </td>
                        {data.hideStatus ? null : <td />}
                        {data.hideControllability ? null : <td />}
                      </tr>
                    :
                      null
                  }
                </tbody>
              </table>
            </div>
          )
        }
      </div>
    );
  }
}

FourHelpfulLists.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
