import React, { Component } from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

import EditableTitle from '../EditableTitle';
import { InlineInput, InlineTextarea } from '../InlineInput.js';
import RichTextEditor from '../RichTextEditor.js';
import './TalentHeartAssessment.scss';
import talentHeartX from '../../image/talent-heart-x.svg';


export const INITIAL_STATE = {
  title: "Talent Heart Assessment",
  description: "The purpose of this tool is to discover and clarify your unique core talents and your heart's passions, desires, and burdens for the world. The intersection of your talents and heart is foundational to clarifying your life purpose.",
  talents: [{title: '', description: ''}],
  heart: [
    {title: 'What do I care about?', description: null},
    {title: 'What do I dream about?', description: null},
    {title: 'What is my "Opus Gloria" contribution in life?', description: null},
  ],
  clues: [
    {title: 'Passions', items: null},
    {title: 'Needs', items: null},
    {title: 'Drives', items: null},
    {title: 'Obsessions', items: null},
    {title: 'Characteristics', items: null},
    {title: 'Qualities', items: null},
    {title: 'Yearnings', items: null},
    {title: 'Hopes', items: null},
    {title: 'Achievements', items: null},
  ],
  messages: [{title: '', description: null}],
};


export default class TalentHeartAssessment extends Component {
  render() {
    const { data, onEditData } = this.props;

    return (
      <div className="chart talentHeartAssessment">
        <p className="stickyTitle">{this.props.data.title}</p>
        <EditableTitle
          data={this.props.data}
          onEditData={this.props.onEditData}
        />
        <section className="talent-heart-list">
          {
            this.getSection('talents', 'Talent')
          }
          <div className="talent-heart-x"><img src={ talentHeartX } alt="" /></div>
          {
            this.getSection('heart', 'Heart')
          }
        </section>

        <section className="clue-list">
          {
            data.clues.map((clue, i) =>
              <div key={i} className="clue">
                <div className="title">
                  {
                    onEditData ?
                      <button
                        className="delete"
                        title="Delete clue"
                        onClick={() => onEditData(update(data, {
                          clues: {
                            $splice: [[i, 1]]
                          }
                        }))}
                      />
                    :
                      null
                  }
                  {
                    onEditData ?
                      <InlineInput
                        value={clue.title}
                        blankPlaceholder="Set clue..."
                        onChange={(value) => onEditData(update(data, {
                          clues: {
                            [i]: {
                              title: {
                                $set: value
                              }
                            }
                          }
                        }))}
                      />
                    :
                      clue.title
                  }
                </div>
                <div className='clueTextWrap'>
                  <RichTextEditor
                    value={clue.items}
                    onChange={
                      onEditData ?
                        (value) => onEditData(update(data, {
                          clues: {
                            [i]: {
                              items: {
                                $set: value
                              }
                            }
                          }
                        }))
                      :
                        null
                    }
                  />
                </div>
              </div>
            )
          }
          {
            onEditData ?
              <div className="clue">
                <button
                  className="add"
                  onClick={() => onEditData(update(data, {
                    clues: {
                      $push: [{title: '', items: null}]
                    }
                  }))}
                >
                  <span className="plus">+</span> Add Clue
                </button>
              </div>
            :
              null
          }
        </section>

        <section className="message-list">
          {
            this.getSection('messages', 'Message', 'Messages')
          }
        </section>
      </div>
    );
  }

  getSection(key, name, title) {
    const {data, onEditData} = this.props;
    const DescriptionComponent = key === 'heart' || key === 'messages' ? RichTextEditor : InlineTextarea;
    return (
      <div className={key}>
        <h2 className={`${key}-header`}><span>{title || name}</span></h2>
        {
          data[key].map((item, i) =>
            <div key={i}>
              {
                onEditData ?
                  <InlineInput
                    value={item.title}
                    blankPlaceholder={`${name}...`}
                    onChange={(value) => onEditData(update(data, {
                      [key]: {
                        [i]: {
                          title: {
                            $set: value
                          }
                        }
                      }
                    }))}
                  />
                :
                  <span>{item.title}</span>
              }
              {
                onEditData ?
                  <DescriptionComponent
                    value={item.description}
                    blankPlaceholder={`${name} description...`}
                    onChange={(value) => onEditData(update(data, {
                      [key]: {
                        [i]: {
                          description: {
                            $set: value
                          }
                        }
                      }
                    }))}
                  /> 
                  : typeof item.description === 'string'
                  ? <div>{item.description}</div>
                  : <RichTextEditor value={item.description} onChange={null} />
              }
              {
                onEditData ?
                  <button
                    className="delete"
                    title={`Delete ${name}`}
                    onClick={() => onEditData(update(data, {
                      [key]: {
                        $splice: [[i, 1]]
                      }
                    }))}
                  />
                :
                  null
              }
            </div>
          )
        }
        {
          onEditData ?
            <div>
              <button
                className="add"
                onClick={() => onEditData(update(data, {
                  [key]: {
                    $push: [{title: '', description: key === 'talents' ? '' : null}]
                  }
                }))}
              >
                <span className="plus">+</span> Add {name}
              </button>
            </div>
          :
            null
        }
      </div>
    );
  }
}

TalentHeartAssessment.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
