import React, {Component} from 'react';
import update from 'immutability-helper';
import _ from 'underscore';
import PropTypes from 'prop-types';

import EditableTitle from '../EditableTitle';
import StatusAndTrendIndicator from '../StatusAndTrendIndicator.js';
import WinWheel from './WinWheel.js';
import { InlineInput } from '../InlineInput.js';

import './InitiativesProfile.scss';


export const DOMAIN_COLOURS = [
  '#00a275',
  '#008fa7',
  '#8e0f58',
  '#102777',
  '#f1a600',
  '#ff4500',
  '#83888f',
  '#91d2c0',
  '#91cad4',
  '#ca97b4',
  '#97a1c1',
  '#f1d391',
  '#f7ad91',
  '#c5c7ca'
];


export const INITIAL_STATE = {
  title: "Life Initiatives Profile & W.I.N. Wheel",
  description: "The purpose of this tool is to clearly define the objectives, practical next steps, targeted timeline, and current status of each life initiative from the Life W.I.N. Wheel.",
  domains: [
    {
      name: "",
      initiatives: [
        {
          initiative: "",
          objective: "",
          nextSteps: [
            {step: '', date: ''}
          ],
          todaysStatus: null,
        },
      ]
    }
  ],
  dateColumnTitle: 'Start Date',
};


export function getDomainColour(i) {
  return DOMAIN_COLOURS[i % DOMAIN_COLOURS.length];
}


export function reducer(state=INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_STEP':
      return update(state, {
        domains: {
          [action.domainIndex]: {
            initiatives: {
              [action.initiativeIndex]: {
                nextSteps: {
                  $push: [{step: '', date: ''}],
                }
              }
            }
          }
        }
      });
    case 'DELETE_STEP':
      return update(state, {
        domains: {
          [action.domainIndex]: {
            initiatives: {
              [action.initiativeIndex]: {
                nextSteps: {
                  $splice: [[action.stepIndex, 1]]
                }
              }
            }
          }
        }
      });
    case 'SET_STEP_TEXT':
    case 'SET_STEP_DATE':
      {
        let step;
        if (action.type === 'SET_STEP_TEXT') {
          step = {
            step: {
              $set: action.value
            }
          };
        } else {
          step = {
            date: {
              $set: action.value
            }
          };
        }

        return update(state, {
          domains: {
            [action.domainIndex]: {
              initiatives: {
                [action.initiativeIndex]: {
                  nextSteps: {
                    [action.stepIndex]: step
                  }
                }
              }
            }
          }
        });
      }
    case 'SET_INITIATIVE':
    case 'SET_OBJECTIVE':
    case 'SET_TODAYS_STATUS':
      {
        let initiative;
        if (action.type === 'SET_INITIATIVE') {
          initiative = {
            initiative: {
              $set: action.value
            }
          };
        } else if (action.type === 'SET_OBJECTIVE') {
          initiative = {
            objective: {
              $set: action.value
            }
          };
        } else {
          initiative = {
            todaysStatus: {
              $set: action.value
            }
          };
        }

        return update(state, {
          domains: {
            [action.domainIndex]: {
              initiatives: {
                [action.initiativeIndex]: initiative
              }
            }
          }
        });
      }
    case 'DELETE_INITIATIVE':
      return {
        ...state,
        domains: state.domains.map((domain, i) =>
          i === action.domainIndex ?
            {
              ...domain,
              initiatives: domain.initiatives.filter((initiative, j) => j !== action.initiativeIndex)
            }
          :
            domain
        ).filter((domain) =>
          domain.initiatives.length !== 0
        )
      };
    case 'ADD_INITIATIVE':
      {
        const emptyInitiative = {
          initiative: '',
          objective: '',
          nextSteps: [{step: '', date: ''}],
          todaysStatus: null,
        };

        if (state.domains.length > 0) {
          return update(state, {
            domains: {
              [state.domains.length - 1]: {
                initiatives: {
                  $push: [emptyInitiative]
                }
              }
            }
          });
        } else {
          return {
            ...state,
            domains: [
              {
                name: '',
                initiatives: [emptyInitiative]
              }
            ],
          };
        }
      }
    case 'SET_INITIATIVE_DOMAIN':
      if (action.value || (action.domainIndex === 0 && action.initiativeIndex === 0)) {
        // Editing the domain of an initiative splits it and creates a new domain
        const domains = state.domains.slice();
        const domain = domains[action.domainIndex];

        const before = {
          ...domain,
          initiatives: domain.initiatives.filter((initiative, i) => i < action.initiativeIndex)
        };

        const after = {
          ...domain,
          name: action.value,
          initiatives: domain.initiatives.filter((initiative, i) => i >= action.initiativeIndex)
        };

        const replacements = [];
        if (before.initiatives.length) {
          replacements.push(before);
        }
        if (after.initiatives.length) {
          replacements.push(after);
        }
        domains.splice(action.domainIndex, 1, ...replacements);

        return {
          ...state,
          domains: domains,
        };
      } else if (action.initiativeIndex === 0) {
        // Blanking the first initiative of a domain joins it with the previous domain
        const domains = state.domains.slice();
        const domain = domains[action.domainIndex - 1];

        domains.splice(action.domainIndex - 1, 2, {
          ...domain,
          initiatives: domain.initiatives.concat(domains[action.domainIndex].initiatives)
        });

        return {
          ...state,
          domains: domains,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}


export function focusNextType(params, data) {
  let { type, domainIndex, initiativeIndex } = params;

  if (type === 'STEP') {
    return {
      ...params,
      type: 'DATE'
    };
  } else if (type === 'DATE') {
    return {
      ...params,
      type: 'STEP',
      stepIndex: params.stepIndex + 1
    };
  } else {
    let nextType;
    const lastInitiativeInDomain =
      initiativeIndex === data.domains[domainIndex].initiatives.length - 1;

    if (type === 'INITIATIVE') {
      nextType = 'OBJECTIVE';
    } else if (type === 'OBJECTIVE') {
      nextType = 'INITIATIVE';
      domainIndex =  lastInitiativeInDomain ? domainIndex + 1 : domainIndex;
      initiativeIndex = lastInitiativeInDomain ? 0 : initiativeIndex + 1;
    }

    return {
      type: nextType,
      domainIndex: domainIndex,
      initiativeIndex: initiativeIndex,
    };
  }
}


function getWinWheelData(data) {
  return {
    groups: data.domains.map((domain) => ({
      name: domain.name,
      items: domain.initiatives.map((initiative) => ({
        name: initiative.initiative
      })),
    }))
  };
}


export default class LifeInitiativesProfile extends Component {
  state = {
    focusedIndex: null,
  }

  render() {
    const { data, onEditData } = this.props;

    return (
      <div className="chart lifeInitiativesProfile">
        <p className="stickyTitle">{data.title}</p>
        <EditableTitle
          data={data}
          onEditData={onEditData}
        />

        <div className="tableOverflowWrapper">
          <table>
            <thead>
              <tr>
                <th className="initiativesProfileGroup">
                  Domain
                </th>
                <th className="initiativesProfileInitiative">
                  Initiative
                </th>
                <th className="initiativesProfileObjective">
                  Objective
                </th>
                <th className="initiativesProfileNext">
                  Next Steps<span className="startDate"> ({data.dateColumnTitle})</span>
                </th>
                <th className="initiativesProfileStart">
                  {
                    onEditData ?
                      <InlineInput
                        value={data.dateColumnTitle}
                        onChange={(value) => onEditData({...data, dateColumnTitle: value})}
                      />
                    :
                      data.dateColumnTitle
                  }

                </th>
                <th className="initiativesProfileStatus">
                  {"Today's Status"}
                </th>
              </tr>
            </thead>
            <tbody>
              {
                _.flatten(
                  data.domains.map((domain, domainIndex) =>
                    onEditData ?
                      domain.initiatives.map((initiative, initiativeIndex) =>
                        <tr key={this.rowIndex(domainIndex, initiativeIndex)}>
                          <td>
                            <div>
                              <button
                                title="Delete"
                                className="delete"
                                onClick={() =>
                                  onEditData(reducer(data, {
                                    type: 'DELETE_INITIATIVE',
                                    domainIndex: domainIndex,
                                    initiativeIndex: initiativeIndex,
                                  }))
                                }
                              />
                              <InlineInput
                                value={initiativeIndex === 0 ? domain.name : ''}
                                blankPlaceholder={domain.name || 'Add Domain...'}
                                onChange={(value) => {
                                  onEditData(reducer(data, {
                                    type: 'SET_INITIATIVE_DOMAIN',
                                    domainIndex: domainIndex,
                                    initiativeIndex: initiativeIndex,
                                    value: value,
                                  }));

                                  this.setState({focusedIndex: null});
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div>
                              <InlineInput
                                value={initiative.initiative}
                                blankPlaceholder='Set initiative...'
                                onChange={(value, enterPressed) => {
                                  onEditData(reducer(data, {
                                    type: 'SET_INITIATIVE',
                                    domainIndex: domainIndex,
                                    initiativeIndex: initiativeIndex,
                                    value: value,
                                  }));

                                  if (enterPressed) {
                                    this.setState({
                                      focusedIndex: focusNextType({
                                        type: 'INITIATIVE',
                                        domainIndex: domainIndex,
                                        initiativeIndex: initiativeIndex,
                                      }, data)
                                    });
                                  } else {
                                    this.setState({focusedIndex: null});
                                  }
                                }}
                                isFocused={_.isEqual(this.state.focusedIndex, {
                                  type: 'INITIATIVE',
                                  domainIndex: domainIndex,
                                  initiativeIndex: initiativeIndex,
                                })}
                              />
                            </div>
                          </td>
                          <td>
                            <InlineInput
                              value={initiative.objective}
                              blankPlaceholder='Set objective...'
                              onChange={(value, enterPressed) => {
                                let newData = reducer(data, {
                                  type: 'SET_OBJECTIVE',
                                  domainIndex: domainIndex,
                                  initiativeIndex: initiativeIndex,
                                  value: value,
                                });

                                if (
                                  enterPressed && domainIndex === data.domains.length - 1 &&
                                  initiativeIndex === data.domains[data.domains.length - 1].initiatives.length - 1
                                ) {
                                  newData = reducer(newData, {type: 'ADD_INITIATIVE'});
                                }

                                onEditData(newData);

                                if (enterPressed) {
                                  this.setState({
                                    focusedIndex: focusNextType({
                                      type: 'OBJECTIVE',
                                      domainIndex: domainIndex,
                                      initiativeIndex: initiativeIndex,
                                    }, newData)
                                  });
                                } else {
                                  this.setState({focusedIndex: null});
                                }
                              }}
                              isFocused={_.isEqual(this.state.focusedIndex, {
                                type: 'OBJECTIVE',
                                domainIndex: domainIndex,
                                initiativeIndex: initiativeIndex,
                              })}
                            />
                          </td>
                          <td colSpan="2">
                            {
                              initiative.nextSteps.map((step, stepIndex) =>
                                <div key={stepIndex}>
                                  <button
                                    title="Delete Step"
                                    className="delete"
                                    onClick={() =>
                                      onEditData(reducer(data, {
                                        type: 'DELETE_STEP',
                                        domainIndex: domainIndex,
                                        initiativeIndex: initiativeIndex,
                                        stepIndex: stepIndex,
                                      }))
                                    }
                                  />
                                  <span className="step">
                                    <InlineInput
                                      value={step.step}
                                      blankPlaceholder="Set step..."
                                      onChange={(value, enterPressed) => {
                                        onEditData(reducer(data, {
                                          type: 'SET_STEP_TEXT',
                                          domainIndex: domainIndex,
                                          initiativeIndex: initiativeIndex,
                                          stepIndex: stepIndex,
                                          value: value,
                                        }));

                                        if (enterPressed) {
                                          this.setState({
                                            focusedIndex: focusNextType({
                                              type: 'STEP',
                                              domainIndex: domainIndex,
                                              initiativeIndex: initiativeIndex,
                                              stepIndex: stepIndex,
                                            }, data)
                                          });
                                        } else {
                                          this.setState({focusedIndex: null});
                                        }
                                      }}
                                      isFocused={_.isEqual(this.state.focusedIndex, {
                                        type: 'STEP',
                                        domainIndex: domainIndex,
                                        initiativeIndex: initiativeIndex,
                                        stepIndex: stepIndex,
                                      })}
                                    />
                                  </span>
                                  <span className="date">
                                    <InlineInput
                                      value={step.date}
                                      blankPlaceholder="Set date..."
                                      onChange={(value, enterPressed) => {
                                        let newData = reducer(data, {
                                          type: 'SET_STEP_DATE',
                                          domainIndex: domainIndex,
                                          initiativeIndex: initiativeIndex,
                                          stepIndex: stepIndex,
                                          value: value,
                                        });

                                        if (enterPressed &&
                                          stepIndex === initiative.nextSteps.length - 1
                                        ) {
                                          newData = reducer(newData, {
                                            type: 'ADD_STEP',
                                            domainIndex: domainIndex,
                                            initiativeIndex: initiativeIndex,
                                          });
                                        }

                                        onEditData(newData);

                                        if (enterPressed) {
                                          this.setState({
                                            focusedIndex: focusNextType({
                                              type: 'DATE',
                                              domainIndex: domainIndex,
                                              initiativeIndex: initiativeIndex,
                                              stepIndex: stepIndex,
                                            }, data)
                                          });
                                        } else {
                                          this.setState({focusedIndex: null});
                                        }
                                      }}
                                      isFocused={_.isEqual(this.state.focusedIndex, {
                                        type: 'DATE',
                                        domainIndex: domainIndex,
                                        initiativeIndex: initiativeIndex,
                                        stepIndex: stepIndex,
                                      })}
                                    />
                                  </span>
                                </div>
                              )
                            }
                            <button
                              className="add"
                              onClick={() => {
                                onEditData(reducer(data, {
                                  type: 'ADD_STEP',
                                  domainIndex: domainIndex,
                                  initiativeIndex: initiativeIndex,
                                }));

                                this.setState({focusedIndex: {
                                  type: 'STEP',
                                  domainIndex: domainIndex,
                                  initiativeIndex: initiativeIndex,
                                  stepIndex: initiative.nextSteps.length
                                }});
                              }}
                            >
                              <span className="plus">+ </span>
                              Add Step
                            </button>
                          </td>
                          <td>
                            <StatusAndTrendIndicator
                              value={initiative.todaysStatus}
                              onChange={(value) =>
                                onEditData(reducer(data, {
                                  type: 'SET_TODAYS_STATUS',
                                  domainIndex: domainIndex,
                                  initiativeIndex: initiativeIndex,
                                  value: value
                                }))
                              }
                            />
                          </td>

                        </tr>
                      )
                    :
                      domain.initiatives.map((initiative, initiativeIndex) =>
                        <tr key={this.rowIndex(domainIndex, initiativeIndex)}>
                          {
                            initiativeIndex === 0 ?
                              <td
                                className="domain"
                                rowSpan={domain.initiatives.length}
                                style={{
                                  backgroundColor: getDomainColour(domainIndex),
                                  color: getDomainColour(domainIndex)
                                }}
                              >
                                <span>{domain.name}</span>
                              </td>
                            :
                              null
                          }
                          <td className="initiative">
                            {initiative.initiative}
                          </td>
                          <td className="objective">
                            {initiative.objective}
                          </td>
                          <td className="steps" colSpan="2">
                            {
                              initiative.nextSteps.map((step, stepIndex) =>
                                <div key={stepIndex}>
                                  <span className="step">{step.step}</span>
                                  <span className="date">{step.date}</span>
                                </div>
                              )
                            }
                          </td>
                          <td className="status">
                            <StatusAndTrendIndicator
                              value={initiative.todaysStatus}
                            />
                          </td>
                        </tr>
                      )
                  )
                )
              }

              {
                onEditData ?
                  <tr className="add">
                    <td>
                      <button
                        className="add"
                        onClick={() => {
                          const newData = reducer(data, {
                            type: 'ADD_INITIATIVE',
                          });
                          onEditData(newData);

                          this.setState({focusedIndex: {
                            type: 'DOMAIN',
                            domainIndex: newData.domains.length - 1,
                            initiativeIndex: newData.domains[newData.domains.length - 1].initiatives.length - 1
                          }});
                        }}
                      >
                        <span className="plus">+</span> Add Initiative
                      </button>
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                :
                  null
              }
            </tbody>
          </table>
        </div>
        <WinWheel
          data={getWinWheelData(data)}
          show={!data.hideWinWheel}
          toggle={
            onEditData &&
            (() =>
              onEditData({
                ...data,
                hideWinWheel: !data.hideWinWheel,
              }))
          }
        />
      </div>
    );
  }

  rowIndex = (domainIndex, initiativeIndex) => {
    let accumulator = 0;
    for (let i=0; i < domainIndex; i++) {
      accumulator += this.props.data.domains[i].initiatives.length;
    }

    accumulator += initiativeIndex;

    return accumulator;
  }
}

LifeInitiativesProfile.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
