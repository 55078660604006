import undoable, { ActionTypes } from 'redux-undo';
import _ from 'underscore';
import update from 'immutability-helper';
import {v4 as uuid4} from 'uuid';

import WIDGET_METADATA from '../widgetMetadata.js';


let document = (state={widgets: []}, action) => {
  switch (action.type) {
    case 'ADD_WIDGET':
      return update(state,
        {
          widgets: {
            $splice: [
              [action.index === undefined ? state.widgets.length : action.index, 0, {
                type: WIDGET_METADATA[action.widgetType].realType || action.widgetType,
                id: uuid4(),
                ...WIDGET_METADATA[action.widgetType].defaults,
                ...action.data,
              }],
            ],
          },
        }
      );
    case 'EDIT_WIDGET':
      return {
        ...state,
        widgets: state.widgets.map((data, i) =>
          i === action.widgetIndex ? {type: data.type, ...action.data} : data
        )
      };
    case 'DELETE_WIDGET':
      return {
        ...state,
        widgets: state.widgets.filter((data, i) => i !== action.index)
      };
    case 'SET_ORDER':
      {
        const newIndex = action.newIndex === undefined ? state.widgets.length : action.newIndex;
        const oldWidget = state.widgets[action.oldIndex];
        const reorderedWidgets = state.widgets.filter((widget, i) => i !== action.oldIndex);
        reorderedWidgets.splice(newIndex, 0, oldWidget);

        return {
          ...state,
          widgets: reorderedWidgets,
        };
      }
    default:
      return state;
  }
};


function deepDistinctState(action, currentState, previousState) {
  return !_.isEqual(currentState, previousState);
}


document = undoable(document, {
  filter: deepDistinctState,
});

const documentWithScrollBehavior = (state, action) => {
  let applyScroll = (newDocument) => {
    if (newDocument.widgets.length >= state.present.widgets.length) {
      let changedIndexes = _.zip(newDocument.widgets, state.present.widgets).map((pair, index) =>
        _.isEqual(pair[0], pair[1]) ? null : index
      ).filter((index) =>
        index !== null
      );

      return {
        ...state,
        scrolledToIndex: changedIndexes[0],
      };
    } else {
      return state;
    }
  };
  switch (action.type) {
    case ActionTypes.UNDO:
      state = applyScroll(state.past[state.past.length - 1]);
      break;
    case ActionTypes.REDO:
      state = applyScroll(state.future[0]);
      break;
    case 'ADD_WIDGET':
      state = {
        ...state,
        scrolledToIndex: action.index !== undefined ? action.index : state.present.widgets.length,
      };
      break;
    case 'SCROLL_TO_WIDGET':
      state = {
        ...state,
        scrolledToIndex: action.id,
      };
      break;
    default:
      state = {
        ...state,
        scrolledToIndex: null
      };
  }
  return document(state, action);
};

export default documentWithScrollBehavior;
