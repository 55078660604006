import React, { Component } from 'react';
import update from 'immutability-helper';
import _ from 'underscore';
import PropTypes from 'prop-types';

import EditableTitle from '../EditableTitle';
import { InlineInput } from '../InlineInput.js';
import './LifePlanAccountability.scss';



export const INITIAL_STATE = {
  title: "LifePlan Accountability",
  description: "The purpose of this tool is to identify specific individuals whom you intend to invite into the content of your LifePlan for encouragement, sharpening, and follow-through. We can't do this alone, so building a healthy Life Circle is essential to living your LifePlan.",
  items: [
    {
      title: "LifePlan Partners",
      people: [],
    },
    {
      title: "LifePlan Mentors",
      people: [],
    },
    {
      title: "Close Friends",
      people: [],
    },
    {
      title: "Protégés",
      people: [],
    },
  ]
};


export default class LifePlanAccountability extends Component {
  state = {}

  render() {
    const {data, onEditData} = this.props;

    return (
      <div className="chart lifePlanAccountability">
        <p className="stickyTitle">{data.title}</p>
        <EditableTitle
          data={data}
          onEditData={onEditData}
        />
        <div className="lifePlanAccountabilityOuter">
          <div className="lifePlanAccountabilityInner">
            <div className="circle">
              <div className="center">
                Life Circle
              </div>

              {
                data.items.map((item, i) =>
                  <div key={i} className={`group-${i+1} group`}>
                    {
                      onEditData ?
                        <InlineInput
                          value={item.title}
                          onChange={(value) => onEditData(update(data, {
                            items: {
                              [i]: {
                                title: {
                                  $set: value
                                }
                              }
                            }
                          }))}
                        />
                      :
                        <span>{item.title}</span>
                    }
                    <ul>
                      {
                        item.people.map((person, j) =>
                          <li key={j}>
                            {
                              onEditData ?
                                <button
                                  title="Delete"
                                  className="delete"
                                  onClick={() => onEditData(update(data, {
                                    items: {
                                      [i]: {
                                        people: {
                                          $splice: [[j, 1]]
                                        }
                                      }
                                    }
                                  }))}
                                />
                              :
                                null
                            }
                            {
                              onEditData ?
                                <InlineInput
                                  value={person}
                                  onChange={(value, enterPressed) => {
                                    let newData = update(data, {
                                      items: {
                                        [i]: {
                                          people: {
                                            [j]: {
                                              $set: value
                                            }
                                          }
                                        }
                                      }
                                    });

                                    if (enterPressed) {
                                      if (j === item.people.length - 1) {
                                        newData = update(newData, {
                                          items: {
                                            [i]: {
                                              people: {
                                                $push: ['']
                                              }
                                            }
                                          }
                                        });
                                      }

                                      this.setState({focusedIndex: {
                                        itemIndex: i,
                                        personIndex: j+1,
                                      }});
                                    }
                                    else {
                                      this.setState({focusedIndex: null});
                                    }

                                    onEditData(newData);
                                  }}
                                  isFocused={_.isEqual(this.state.focusedIndex, {
                                    itemIndex: i,
                                    personIndex: j,
                                  })}
                                />
                              :
                                <span>{person}</span>
                            }
                          </li>
                        )
                      }
                    </ul>
                    {
                      onEditData ?
                        <button
                          className="add"
                          onClick={() => onEditData(update(data, {
                            items: {
                              [i]: {
                                people: {
                                  $push: ['']
                                }
                              }
                            }
                          }))}
                        >
                          <span className="plus">+</span> Add
                        </button>
                      :
                        null
                    }
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LifePlanAccountability.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
