import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import EditableTitle from '../EditableTitle';

import './InternalWiring.scss';


export const DRIVERS = {
  0: 'Contribution',
  1: 'Presentation',
  2: 'Primary Leadership',
  3: 'Secondary Leadership',
};

export const COMFORT_ZONES = {
  0: 'Things',
  1: 'People',
  2: 'Primary Ideas',
  3: 'Secondary Ideas',
};


export default class InternalWiring extends Component {
  render() {
    const {
      primaryDriver,
      primaryComfortZone,
      secondaryDriver,
      secondaryComfortZone,
    } = this.props.data;

    const BOX_CENTER_COORIDNATES = [
      [460, 100],
      [820, 100],
      [460, 300],
      [820, 300],
    ];

    return (
      <div className="chart">
        <p className="stickyTitle">{this.props.data.title}</p>
        <EditableTitle
          data={this.props.data}
          onEditData={this.props.onEditData}
        />
        <div className="dragInner">
          <div className="internalWiringWrapper">
            <svg className="internalWiring" viewBox="0 0 1000 900">
              <defs>
                <linearGradient id="DriverGradient" x1="0" x2="0" y1="0" y2="1">
                  <stop offset="0%" stopColor="#102777" stopOpacity="1"/>
                  <stop offset="100%" stopColor="#008FA7" stopOpacity="1"/>
                </linearGradient>
                <linearGradient id="DriverGradient_Print" x1="0" x2="0" y1="0" y2="1">
                  <stop offset="0%" stopColor="#1842d0" stopOpacity="1"/>
                  <stop offset="100%" stopColor="#00B1D0" stopOpacity="1"/>
                </linearGradient>
                <linearGradient id="ComfortGradient" x1="0" x2="0" y1="0" y2="1">
                  <stop offset="0%" stopColor="#8E0F58" stopOpacity="1"/>
                  <stop offset="100%" stopColor="#FF4500" stopOpacity="1"/>
                </linearGradient>
                <linearGradient id="ComfortGradient_Print" x1="0" x2="0" y1="0" y2="1">
                  <stop offset="0%" stopColor="#C6167D" stopOpacity="1"/>
                  <stop offset="100%" stopColor="#FF4500" stopOpacity="1"/>
                </linearGradient>
              </defs>

              {/* Top Grid */}
              <line x1="280" y1="1" x2="1000" y2="1" stroke="#999" strokeWidth="2" />
              <line x1="280" y1="201" x2="1000" y2="201" stroke="#999" strokeWidth="2" />
              <line x1="280" y1="399" x2="1000" y2="399" stroke="#999" strokeWidth="2" />
              <line x1="641" y1="0" x2="641" y2="400" stroke="#999" strokeWidth="2" />
              <line x1="999" y1="0" x2="999" y2="400" stroke="#999" strokeWidth="2" />

              {/* Bottom Grid */}
              <g transform="translate(0, 500)">
                <line x1="280" y1="1" x2="1000" y2="1" stroke="#999" strokeWidth="2" />
                <line x1="280" y1="201" x2="1000" y2="201" stroke="#999" strokeWidth="2" />
                <line x1="280" y1="399" x2="1000" y2="399" stroke="#999" strokeWidth="2" />
                <line x1="641" y1="0" x2="641" y2="400" stroke="#999" strokeWidth="2" />
                <line x1="999" y1="0" x2="999" y2="400" stroke="#999" strokeWidth="2" />
              </g>

              <path
                stroke="#8E0F58" strokeWidth="4" fill="none"
                d={`M ${BOX_CENTER_COORIDNATES[primaryDriver][0]} ${BOX_CENTER_COORIDNATES[primaryDriver][1]}
                C ${640 - (BOX_CENTER_COORIDNATES[primaryDriver][0] - 640)/5} ${BOX_CENTER_COORIDNATES[primaryDriver][1]}
                ${640 - (BOX_CENTER_COORIDNATES[primaryComfortZone][0] - 640)/5} ${BOX_CENTER_COORIDNATES[primaryComfortZone][1]+500}
                ${BOX_CENTER_COORIDNATES[primaryComfortZone][0]} ${BOX_CENTER_COORIDNATES[primaryComfortZone][1]+500}`}
              />

              {
                (secondaryDriver !== undefined && secondaryComfortZone !== undefined) ? (
                  <path
                    stroke="#00A275" strokeWidth="3" fill="none" strokeDasharray="10 5"
                    d={`M ${BOX_CENTER_COORIDNATES[secondaryDriver][0]} ${BOX_CENTER_COORIDNATES[secondaryDriver][1]}
                    C ${640 - (BOX_CENTER_COORIDNATES[secondaryDriver][0] - 640)/5} ${BOX_CENTER_COORIDNATES[secondaryDriver][1]}
                    ${640 - (BOX_CENTER_COORIDNATES[secondaryComfortZone][0] - 640)/5} ${BOX_CENTER_COORIDNATES[secondaryComfortZone][1]+500}
                    ${BOX_CENTER_COORIDNATES[secondaryComfortZone][0]} ${BOX_CENTER_COORIDNATES[secondaryComfortZone][1]+500}`}
                  />
                ) : null
              }

              <g>
                <ellipse
                  cx={BOX_CENTER_COORIDNATES[primaryDriver][0]}
                  cy={BOX_CENTER_COORIDNATES[primaryDriver][1]}
                  rx="130" ry="80" stroke="#8E0F58" strokeWidth="4" fill="white"
                />

                {
                  (secondaryDriver !== undefined) ? (
                    <ellipse
                      cx={BOX_CENTER_COORIDNATES[secondaryDriver][0]}
                      cy={BOX_CENTER_COORIDNATES[secondaryDriver][1]}
                      rx="125" ry="75" stroke="#00A275" strokeWidth="3" fill="white" strokeDasharray="10 5"
                    />
                  ) : ''
                }

                <rect x="0" y="0" width="1000" height="400"  fill="none" />

                <rect
                  x="0"
                  y="0"
                  width="280"
                  height="400"
                  className="labelBackground_Drivers"
                  fill="url(#DriverGradient)"
                />
                <text
                  x="140"
                  y="200"
                  dy="0.4em"
                  fontSize="30"
                  textAnchor="middle"
                  fill="white"
                  className="sectionLabel text_Drivers"
                >
                  Drivers
                </text>


                <rect
                  x="280" y="0" width="360" height="200" fillOpacity="0" className="clickable"
                  onClick={(e) => this.setDriver(e, 0)}
                />
                <text x="460" y="100" dy="0.5em" fontSize="24" textAnchor="middle" className="text_Contribution">
                  Contribution
                </text>

                <rect
                  x="640" y="0" width="360" height="200" fillOpacity="0" className="clickable"
                  onClick={(e) => this.setDriver(e, 1)}
                />
                <text x="820" y="100" dy="-0.3em" fontSize="24" textAnchor="middle" className="text_ImagePresentation">
                  <tspan x="820">Image/</tspan>
                  <tspan x="820" dy="1.2em">Presentation</tspan>
                </text>

                <rect
                  x="280" y="200" width="360" height="200" fillOpacity="0" className="clickable"
                  onClick={(e) => this.setDriver(e, 2)}
                />
                <text y="300" dy="-0.5em" fontSize="24" textAnchor="middle" className="text_PrimaryLeadership">
                  <tspan x="460">Primary</tspan>
                  <tspan x="460" dy="1.2em">Leadership</tspan>
                </text>

                <rect
                  x="640" y="200" width="360" height="200" fillOpacity="0" className="clickable"
                  onClick={(e) => this.setDriver(e, 3)}
                />
                <text y="300" dy="-0.5em" fontSize="24" textAnchor="middle" className="text_SecondaryLeadership">
                  <tspan x="820">Secondary</tspan>
                  <tspan x="820" dy="1.2em">Leadership</tspan>
                </text>
              </g>

              <g transform="translate(0, 500)">
                <ellipse
                  cx={BOX_CENTER_COORIDNATES[primaryComfortZone][0]}
                  cy={BOX_CENTER_COORIDNATES[primaryComfortZone][1]}
                  rx="130" ry="80" stroke="purple" strokeWidth="4" fill="white"
                />

                {
                  (secondaryComfortZone !== undefined) ? (
                    <ellipse
                      cx={BOX_CENTER_COORIDNATES[secondaryComfortZone][0]}
                      cy={BOX_CENTER_COORIDNATES[secondaryComfortZone][1]}
                      rx="125" ry="75" stroke="#00A275" strokeWidth="3" fill="white" strokeDasharray="10 5"
                    />
                  ) : ''
                }

                <rect x="0" y="0" width="1000" height="400" fill="none" />

                <rect
                  x="0"
                  y="0"
                  width="280"
                  height="400"
                  className="labelBackground_ComfortZones"
                  fill="url(#ComfortGradient)"
                />
                <text y="200" fontSize="30" textAnchor="middle" fill="white" className="sectionLabel text_ComfortZones">
                  <tspan x="140">Comfort</tspan>
                  <tspan x="140" dy="1.2em">Zones</tspan>
                </text>

                <rect
                  x="280" y="0" width="360" height="200" fillOpacity="0" className="clickable"
                  onClick={(e) => this.setComfortZone(e, 0)}
                />
                <text x="460" y="100" dy="0.5em" fontSize="24" textAnchor="middle" className="text_Things">Things</text>

                <rect
                  x="640" y="0" width="360" height="200" fillOpacity="0" className="clickable"
                  onClick={(e) => this.setComfortZone(e, 1)}
                />
                <text x="820" y="100" dy="0.5em" fontSize="24" textAnchor="middle" className="text_People">People</text>

                <rect
                  x="280" y="200" width="360" height="200" fillOpacity="0" className="clickable"
                  onClick={(e) => this.setComfortZone(e, 2)}
                />
                <text y="300" dy="-0.5em" fontSize="24" textAnchor="middle" className="text_PrimaryIdeas">
                  <tspan x="460">Primary</tspan>
                  <tspan x="460" dy="1.2em">Ideas</tspan>
                </text>

                <rect
                  x="640" y="200" width="360" height="200" fillOpacity="0" className="clickable"
                  onClick={(e) => this.setComfortZone(e, 3)}
                />
                <text y="300" dy="-0.5em" fontSize="24" textAnchor="middle" className="text_SecondaryIdeas">
                  <tspan x="820">Secondary</tspan>
                  <tspan x="820" dy="1.2em">Ideas</tspan>
                </text>
              </g>
              <g className="hint">
                <rect fill="rgba(2555, 255, 255, .9)" x="43.25%" y="48.25%" width="300" height="32" />
                <rect fill="#cccccc" x="49.25%" width="55" y="48.75%" height="26" rx="2" ry="2" />
                <text x="45%" y="50%" textAnchor="start" fill="#999999" fontSize="20" transform="translate(0,10)">
                  Hold
                </text>
                <text x="50%" y="50%" textAnchor="start" fill="#333333" fontSize="20" transform="translate(0,10)">
                  Shift
                </text>
                <text x="55.5%" y="50%" textAnchor="start" fill="#999999" fontSize="20" transform="translate(0,10)">
                  and click to modify secondary zones.
                </text>
              </g>
            </svg>
          </div>
        </div>
      </div>
    );
  }

  setDriver = (event, index) => {
    if (!this.props.onEditData) {
      return;
    }

    if (!event.shiftKey) {
      this.props.onEditData({
        ...this.props.data,
        primaryDriver: index
      });
    }
    else {
      this.props.onEditData(
        this.props.data.secondaryDriver !== index ?
          {
            ...this.props.data,
            secondaryDriver: index
          }
        :
          _.omit(this.props.data, 'secondaryDriver')
      );
    }
    event.preventDefault();
  };

  setComfortZone = (event, index) => {
    if (!this.props.onEditData) {
      return;
    }

    if (!event.shiftKey) {
      this.props.onEditData({
        ...this.props.data,
        primaryComfortZone: index
      });
    }
    else {
      this.props.onEditData(
        this.props.data.secondaryComfortZone !== index ?
          {
            ...this.props.data,
            secondaryComfortZone: index,
          }
        :
          _.omit(this.props.data, 'secondaryComfortZone')
      );
    }
    event.preventDefault();
  };
}
InternalWiring.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
