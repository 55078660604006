import React from 'react';
import update from 'immutability-helper';
import _ from 'underscore';

import ThinkingWavelengthBase from './ThinkingWavelengthBase.js';
import { clamp } from '../../utils.js';
import * as keycodes from '../../keycodes.js';


class ThinkingWavelengthStratop extends ThinkingWavelengthBase {
  renderInner() {
    this.itemElems = [];
    this.labelElems = [];

    const sortedItems = this.state.data.items.map((item, i) => ({
      itemIndex: i,
      item: item
    }));

    sortedItems.sort((a, b) => a.item.thinkingWavelength - b.item.thinkingWavelength);
    const numLevels = this.numLevels();

    return (
      <g className="currentJobs">
        {
          _.range(numLevels + 1).map((level) =>
            <g
              key={level}
              className="levelLine"
              transform={`translate(0, ${this.LEVEL_BASE + this.LEVEL_OFFSET * level})`}
            >
              <line
                x1="0"
                x2={this.GRAPH_WIDTH}
                y1="0"
                y2="0"
                strokeWidth="1"
                stroke="black"
                strokeDasharray="1, 5"
              />
            </g>
          )
        }
        {
          sortedItems.map((item) => (
            <g
              key={item.itemIndex}
              transform={`translate(${item.item.thinkingWavelength * this.GRAPH_WIDTH / 10}, ${53 + item.item.level * this.LEVEL_OFFSET})`}
              onMouseDown={
                (e) => this.startDrag(
                  e,
                  this.itemElems[item.itemIndex],
                  (wavelength, level) => this.updateItem(item, wavelength, level)
                )
              }
              ref={(e) => this.itemElems[item.itemIndex] = e}
              className="currentJob"
            >

              <g className="currentJobInner" transform="scale(0.5)">
                <path
                  className="star"
                  fill="#00A275"
                  d="M0,21 6,37h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
                  transform="translate(0,85) scale(1)"
                />
                <rect
                  x="-190"
                  y="62"
                  width="190"
                  height="34"
                  fill="transparent"
                  rx="3"
                  ry="3"
                  className="currentJobLabel"
                  transform="scale(2) rotate(-35) translate(-20,-25)"
                  tabIndex="0"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.openItemEditor(item.itemIndex);
                  }}
                  ref={(e) => this.labelElems[item.itemIndex] = e}
                />
                <text
                  x="-40"
                  y="84"
                  textAnchor="end"
                  startOffset="100%"
                  fontSize="16"
                  fontWeight="bold"
                  fill="#00A275"
                  fillOpacity="1"
                  transform="scale(2) rotate(-35) translate(-20,-25)"
                  className="nameLabel"
                >
                  {item.item.name || 'Add Name...'}
                </text>
                {
                  this.props.onEditData ?
                    <g
                      className="delete"
                      onClick={(event) => this.deleteItem(event, item.itemIndex)}
                      transform="translate(-14,40) scale(0.75)"
                    >
                      <rect
                        className="deleteBackground"
                        x="-20"
                        y="-24"
                        width="80"
                        height="80"
                        rx="5"
                        ry="5"
                        strokeWidth="3"
                        stroke="black"
                        fill="white"
                      />
                      <path
                        d="M 0,0 l 0,40 l 40,0 l 0,-40 m 0,-5 l -40,0 m 10,-1 l 20,0 m 0,-1 l -20,0 m 0,12 l 0,25 m 10,0 l 0,-25 m 10,0 l 0,25"
                        fill="transparent"
                        strokeWidth="2"
                        stroke="black"
                      />
                    </g>
                  :
                    null
                }
              </g>
            </g>
          ))
        }
      </g>
    );
  }

  height() {
    return super.height() + this.numLevels() * this.LEVEL_OFFSET;
  }

  numLevels() {
    if (_.isEmpty(this.state.data.items))
      return 1;
    else
      return clamp(_.max(this.state.data.items, (item) => item.level).level + 1, 1, 4);
  }

  renderAdditionalStuff = () => {
    if (this.state.editing && this.state.editing.position) {
      const position = this.state.editing.position;

      return (
        <input
          type='text'
          value={this.state.data.items[this.state.editing.itemIndex].name}
          ref={(e) => this.input = e}
          style={{
            position: 'absolute',
            padding: '5px 10px',
            top: position.top + 50,
            left: position.left + 26,
            width: 120,
            height: 32,
            'text-align': 'right',
            'font-size': 12,
            'transform': 'rotate(-35deg)',
            'box-shadow': 'rgba(0,0,0,0.5) -1px 2px 6px'
          }}
          onChange={(event) => {
            this.setState({data: update(this.state.data, {
              items: {
                [this.state.editing.itemIndex]: {
                  name: {
                    $set: event.target.value
                  }
                }
              }
            })});
          }}
          onBlur={this.stopEditing}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.handleKeyPress}
        />
      );
    }
  }

  componentDidUpdate() {
    if (this.state.editing && this.labelElems[this.state.editing.itemIndex]) {
      const labelRect = this.labelElems[this.state.editing.itemIndex].getBoundingClientRect();

      this.setState({
        editing: {
          itemIndex: this.state.editing.itemIndex,
          position: {
            top: labelRect.top - this.chartElem.getBoundingClientRect().top,
            left: labelRect.left - this.chartElem.getBoundingClientRect().left,
          }
        }
      });
    }

    if (this.state.editing && this.state.editing.position) {
      this.input.focus();
    }
  }

  stopEditing = () => {
    this.props.onEditData(this.state.data);
    this.setState({editing: null});
  }

  onKeyDown = (event) => {
    if (event.keyCode === keycodes.ESCAPE) {
      // IE clears inputs on escape. We don't want that behavior.
      event.preventDefault();
    }
  }

  handleKeyPress = (event) => {
    if (event.keyCode === keycodes.ENTER || event.keyCode === keycodes.ESCAPE) {
      event.preventDefault();
      this.stopEditing();
    }
    else if (event.keyCode === keycodes.TAB) {
      event.preventDefault();
      this.stopEditing();
      const nextIndex = this.state.editing.itemIndex + (event.shiftKey ? -1 : 1);

      if (nextIndex >= 0 && nextIndex < this.state.data.items.length) {
        this.openItemEditor(nextIndex);
      }
    }
  }

  onSvgClick = (event) => {
    if (this.props.onEditData && !this.state.didDrag) {
      this.props.onEditData(update(this.state.data, {
        items: {
          $push: [{
            name: '',
            thinkingWavelength: this.mouseEventToThinkingWavelength(event),
            level: this.mouseEventToLevel(event),
          }]
        }
      }));

      this.openItemEditor(this.state.data.items.length);
    }
  }

  deleteItem = (event, itemIndex) => {
    this.stopEditing();
    event.stopPropagation();

    this.props.onEditData(update(this.state.data, {
      items: {
        $splice: [
          [itemIndex, 1]
        ]
      }
    }));
  }

  openItemEditor = (itemIndex) => {
    if (!this.state.didDrag && this.props.onEditData) {
      this.setState({
        editing: {
          itemIndex: itemIndex,
        }
      });
    }
  }

  updateItem(item, wavelength, level) {
    return update(this.state.data, {
      items: {
        [item.itemIndex]: {
          thinkingWavelength: {
            $set: wavelength,
          },
          level: {
            $set: level,
          }
        }
      }
    });
  }
}


export default ThinkingWavelengthStratop;
