import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import classNames from 'classnames';

import RichTextEditor from '../RichTextEditor.js';
import { DRIVERS, COMFORT_ZONES } from '../widgets/InternalWiring.js';
import { MyLifeDashboardInner } from '../widgets/MyLifeDashboard.js';
import LifeInitiativesProfileNormal from '../widgets/LifeInitiativesProfile.js';
import { RankGraph } from '../widgets/TurningPointProfile.js';
import './LifePlanOnAPage.scss';
import lifeplanLogo from '../../image/logo-lifeplan-black-horizontal.png';
import talentHeartX from '../../image/talent-heart-x.svg';
import ReplenishmentCycleNormal from '../widgets/ReplenishmentCycle.js';
import SimpleStatusIndicator from '../SimpleStatusIndicator.js';



export class Section extends Component {
  filter = (widgetData) => widgetData.type === this.widgetType

  render() {
    const { widgets } = this.props;
    const matchingWidgets = _.filter(widgets, this.filter);
    const widget = matchingWidgets.length >= 1 ? matchingWidgets[0] : null;
    const className = this.widgetType.charAt(0).toLowerCase() + this.widgetType.slice(1);

    if (!widget) {
      return null;
    }

    return (
      <section
        className={classNames(
          'planOnAPageSection',
          className,
          {
            empty: matchingWidgets.length === 0
          }
        )}
      >
        <h1
          className='planOnAPageTitle'
        >
          <span className={classNames('chartIcon', this.widgetType)} />
          {widget.title}
        </h1>
        {
          widget ?
            this.renderInner(widget)
          :
            <span className='chartMissing'>
              Playbook does not contain this chart
            </span>
        }
      </section>
    );
  }
}
Section.propTypes = {
  widgets: PropTypes.array.isRequired,
};


export class GenericTableSection extends Section {
  filter = (widgetData) =>
    widgetData.type === 'GenericTable' && widgetData.originalType === this.widgetType;

  renderInner(data) {
    return (
      <table>
        <tbody>
          {
            data.rows.map((row, i) =>
              <tr
                key={i}
                className={classNames({
                  highlighted: data.highlightedRows[i]
                })}
              >
                {
                  data.isNumbered ?
                    <td className="numbers">
                      {(data.highlightedRows[0] ? i : i + 1) || '#'}
                    </td>
                  :
                    null
                }
                {
                  row.map((value, j) =>
                    <td
                      key={j}
                      className={classNames({highlighted: data.highlightedColumns[j]})}
                    >
                      <RichTextEditor
                        value={value}
                      />
                    </td>
                  )
                }
              </tr>
            )
          }
        </tbody>
      </table>
    );
  }
}


class InternalWiring extends Section {
  widgetType = 'InternalWiring'

  renderInner(data) {
    return (
      <div className='internalWiringWrap'>
        <div className='internalWiringSection'>
          <p className='internalWiringDrivers'>Drivers</p>
          <p className='internalWiringComfort'>Comfort Zones</p>
        </div>
        <div className='internalWiringSection internalWiringPrimary'>
          <span>{DRIVERS[data.primaryDriver]}</span>
          <svg height='2px' viewBox='0 0 100 2' preserveAspectRatio='none'>
            <defs>
              <linearGradient x1='100%' y1='50%' x2='0%' y2='50%' id='primaryGradient'>
                <stop stopColor='#8E0F58' offset='0%'></stop>
                <stop stopColor='#FF4500' offset='100%'></stop>
              </linearGradient>
            </defs>
            <rect
              x='0'
              y='0'
              width='100'
              height='2'
              rx='1'
              ry='1'
              id='Line'
              fill='url(#primaryGradient)'
            />
          </svg>
          <span>{COMFORT_ZONES[data.primaryComfortZone]}</span>
        </div>
        {
          data.secondaryDriver ?
            <div className='internalWiringSection internalWiringSecondary'>
              <span>{DRIVERS[data.secondaryDriver]}</span>

              <svg height='2px' viewBox='0 0 100 2' preserveAspectRatio='none'>
                <path
                  d='M1,1 L100,1'
                  id='stroke'
                  stroke='#102178'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeDasharray='6'
                />
              </svg>
              <span>{COMFORT_ZONES[data.secondaryComfortZone]}</span>
            </div>
          :
            null
        }
      </div>
    );
  }
}


class ThinkingWavelength extends Section {
  widgetType = 'ThinkingWavelengthLifeplan'

  renderInner(data) {
    let type;

    if (data.thinkingWavelength < 2) {
      type = 'Grinder';
    } else if (data.thinkingWavelength < 4) {
      type = 'Minder';
    } else if (data.thinkingWavelength < 6) {
      type = 'Keeper';
    } else if (data.thinkingWavelength < 8) {
      type = 'Finder';
    } else {
      type = 'Conceiver';
    }


    return (
      <div className='thinkingWavelengthWrap'>
        <span>{data.thinkingWavelength.toFixed(1)}</span>
        <svg height='2px' viewBox='0 0 100 2' preserveAspectRatio='none'>
          <defs>
            <linearGradient x1='100%' y1='50%' x2='0%' y2='50%' id='thinkingWavelengthGradient'>
              <stop stopColor='#008FA7' offset='0%'></stop>
              <stop stopColor='#102178' offset='100%'></stop>
            </linearGradient>
          </defs>
          <rect
            x='0'
            y='0'
            width='100'
            height='2'
            rx='1'
            ry='1'
            id='Line'
            fill='url(#thinkingWavelengthGradient)'
          />
        </svg>
        <span>{type}</span>
      </div>
    );
  }
}


class TalentHeartAssessment extends Section {
  widgetType = 'TalentHeartAssessment'

  getSection(data, key, title) {
    return (
      <div className={key}>
        <h2>{title}</h2>
        <ul>
          {
            data[key].map((item, i) =>
              <li key={i}>
                <h3>{item.title}</h3>
                <span>
                  {
                    typeof item.description === 'string'
                      ? item.description
                      : <RichTextEditor value={item.description} onChange={null} />
                  }
                </span>
              </li>
            )
          }
        </ul>
      </div>
    );
  }

  renderInner(data) {
    return (
      <div className='talentHeartAssessmentInner'>
        {this.getSection(data, 'talents', 'Talent')}
        <div className="talent-heart-x">
          <img src={ talentHeartX } alt="" />
        </div>
        {this.getSection(data, 'heart', 'Heart')}
      </div>
    );
  }
}


class TurningPointProfile extends Section {
  widgetType = 'TurningPointProfile'

  renderInner(data) {
    return (
      <div>
        <RankGraph data={data} showThemes={true} />
      </div>
    );
  }
}


class MyLifeNet extends Section {
  widgetType = 'LifePlanAccountability'

  renderInner(data) {
    return (
      <div className='lifePlanAccountabilityInner'>
        {
          data.items.map((item, i) =>
            <ul key={i}>
              <h2>{item.title}</h2>
              <ul>
                {
                  item.people.map((p, j) =>
                    <li key={j}>
                      {p}
                    </li>
                  )
                }
              </ul>
            </ul>
          )
        }
      </div>
    );
  }
}


class ReplenishmentCycle extends Section {
  widgetType = 'ReplenishmentCycle'

  renderInner(data) {
    return <ReplenishmentCycleNormal data={data} />;
  }
}


class MyLifeDashboard extends Section {
  widgetType = 'MyLifeDashboard'

  renderInner(data) {
    return <MyLifeDashboardInner data={data} />;
  }
}


class LifeInitiativesProfile extends Section {
  widgetType = 'LifeInitiativesProfile'

  renderInner(data) {
    return (
      <LifeInitiativesProfileNormal data={data} />
    );
  }
}


export class CoreValues extends GenericTableSection {
  widgetType = 'CoreValues'
  filter = (widgetData) => widgetData.type === this.widgetType

  renderInner(data) {
    return (
      <table>
        <tbody>
          {
            data.rows.map((row, i) =>
              <tr
                key={i}
                className={classNames({
                  highlighted: data.highlightedRows[i]
                })}
              >
                {
                  data.isNumbered ?
                    <td className="numbers">
                      {(data.highlightedRows[0] ? i : i + 1) || '#'}
                    </td>
                  :
                    null
                }
                {
                  row.map((value, j) =>
                    <td
                      key={j}
                      className={classNames({highlighted: data.highlightedColumns[j]})}
                    >
                      <RichTextEditor
                        value={value}
                      />
                    </td>
                  )
                }
                <td>
                  {
                    i === 0 && data.highlightedRows[0]
                      ? <h2>TODAY'S STATUS</h2> 
                      : <SimpleStatusIndicator value={data.statuses[i]} />
                  }
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    );
  }
}


export default function LifePlanOnAPage({widgets, client_name}) {

  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <div className={"planOnAPage-Wrapper " + (isSafari ? 'isSafari' : '')}>
      <div className='planOnAPage-Poster planOnAPage-Lifeplan'>
        <h1 className='chartTitle'><img src={ lifeplanLogo } alt="LifePlan" /> Plan on a Page &mdash; { client_name }</h1>

        <div className="left">

          <TurningPointProfile widgets={widgets} />
          <TalentHeartAssessment widgets={widgets} />

          <div className="split">
            <ReplenishmentCycle widgets={widgets} />
            <InternalWiring widgets={widgets} />
            <ThinkingWavelength widgets={widgets} />
          </div>
          <div className="split">
            <MyLifeNet widgets={widgets} />
         	  <CoreValues widgets={widgets} />
          </div>
        </div>
        <div className="right">
          <MyLifeDashboard widgets={widgets} />
          <LifeInitiativesProfile widgets={widgets} />
        </div>
      </div>
    </div>


  );
}

LifePlanOnAPage.propTypes = {
  widgets: PropTypes.array.isRequired,
  client_name: PropTypes.string.isRequired,
};
