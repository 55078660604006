import React, {Component} from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';

import { InlineInput } from '../InlineInput.js';
import StatusIndicator from '../StatusIndicator.js';
import './CycleChartBase.scss';
import RichTextEditor from '../RichTextEditor.js';


const ITEM_WIDTH = '180px';
const RADIUS = `(100% - ${ITEM_WIDTH}) / 2`;


// these functions are exported just for testing
export function itemReducer(state, action) {
  switch (action.type) {
    case 'SET_STATUS':
      return {
        ...state,
        status: action.value,
      };
    case 'EDIT_TEXT':
      return {
        ...state,
        text: action.text,
      };
    default:
      return state;
  }
}


export function reducer(state, action) {
  if (action.index !== undefined) {
    state = {
      ...state,
      items: state.items.map((item, i) =>
          action.index === i ? itemReducer(item, action) : item
      ),
    };
  }

  switch (action.type) {
    case 'DELETE_ITEM':
      return {
        ...state,
        items: state.items.filter((item, i) => i !== action.index),
      };
    case 'ADD_ITEM':
      return {
        ...state,
        items: [...state.items, {text: action.value, status: null}],
      };
    case 'EDIT_CENTER':
      return {
        ...state,
        center: action.text,
      };
    default:
      return state;
  }
}


export default class CycleChartBase extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  STATUS_COMPONENT = StatusIndicator;
  ALT_TEXTS = {
    null:  "no description",
    1: "Healthy/In good order",
    2: "Healthy, but watch closely",
    3: "Caution, work to be done",
    4: "Caution, not working well",
    5: "In trouble, fix"
  };
  DESCRIPTION_COMPONENT = InlineInput;

  render () {
    let { data, onEditData } = this.props;

    return (
      <div className="chart editableHeaders">
        {
          this.getHeader()
        }

        <div className="cycleWrapper">
          <div className="cycle">
            <div className="circle" style={{
              height: `calc(${RADIUS} * 2)`,
              width: `calc(${RADIUS} * 2)`,
              top: `calc(${ITEM_WIDTH} / 2)`,
              left: `calc(${ITEM_WIDTH} / 2)`,
            }} />
            <div className="center" style={{
              height: ITEM_WIDTH,
              width: ITEM_WIDTH,
              top: `calc(${RADIUS})`,
              left: `calc(${RADIUS})`,
            }}>

              {
                onEditData ?
                  <InlineInput
                    value={data.center}
                    onChange={(val) =>
                      onEditData(reducer(data, {type: 'EDIT_CENTER', text: val}))
                    }
                  />
                :
                  <span>{data.center}</span>
              }

            </div>
            <ul>
              {data.items.map((item, i) =>
                <li
                  key={i}
                  className="item"
                  style={calculateItemStyle(i, data.items.length)}
                >
                  <this.STATUS_COMPONENT
                    value={item.status}
                    onChange={onEditData ?
                      (status) =>
                        onEditData(reducer(data, {
                          type: 'SET_STATUS',
                          index: i,
                          value: status,
                        }))
                      :
                      null
                    }
                    altTexts={this.ALT_TEXTS}
                    nullable={true}
                  />
                  {
                    onEditData ?
                      <this.DESCRIPTION_COMPONENT
                        value={item.text}
                        onChange={(newText, enterPressed) => {
                          let newData = reducer(data, {
                            type: 'EDIT_TEXT',
                            index: i,
                            text: newText,
                          });

                          if (enterPressed && i === data.items.length - 1) {
                            newData = reducer(newData, {
                              type: 'ADD_ITEM',
                            });
                          }
                          if (!_.isEqual(data, newData)) {
                            onEditData(newData);
                          }
                          if (enterPressed) {
                            this.setState({focusedIndex: i + 1});
                          } else {
                            this.setState({focusedIndex: null});
                          }
                        }}
                        isFocused={i === this.state.focusedIndex}
                      />
                    : typeof item.text === 'string' ? <span>{item.text}</span>
                    : <RichTextEditor value={item.text} onChange={null} />
                  }
                  {
                    onEditData ?
                      <button title="Delete item" className="delete" onClick={(event) => {
                        onEditData(reducer(data, {type: 'DELETE_ITEM', index: i}));
                        event.stopPropagation();
                      }}></button>
                    :
                      null
                  }
                </li>
              )}
            </ul>
            {
              onEditData ?
                <button title="Add a new item" onClick={() => {
                  onEditData(reducer(data, {
                    type: 'ADD_ITEM',
                    value: this.DESCRIPTION_COMPONENT === RichTextEditor ? null : ''
                  }));
                  this.setState({
                    focusedIndex: data.items.length,
                  });
                }}>ADD</button>
              :
                null
            }
          </div>
        </div>
      </div>
    );
  }
}

CycleChartBase.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};


function calculateItemStyle(index, count) {
  let theta = Math.PI * 2 / count * index;
  return {
    top: `calc(${RADIUS} - (${Math.cos(theta).toFixed(10)} * ${RADIUS}))`,
    left: `calc(${Math.sin(theta).toFixed(10)} * ${RADIUS} + ${RADIUS})`,
    height: ITEM_WIDTH,
    width: ITEM_WIDTH
  };
}
