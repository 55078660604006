import React, {Component} from 'react';
import PropTypes from 'prop-types';

import EditableTitle from '../EditableTitle';
import {Section} from './OurPrimaryCustomer.js';
import './OurPrimaryCustomer.scss';


export const INITIAL_STATE = {
  title: "Our Primary Customer's Values",
  description: "The purpose of this tool is to identify the top 4-5 needs, wants, and values of the primary customer.",
  longList: null,
  topValues: null,
};


export default class OurPrimaryCustomersValues extends Component {
  render() {
    const {data, onEditData} = this.props;

    return (
      <div className="chart ourPrimaryCustomer ourPrimaryCustomersValues">
        <p className="stickyTitle">{this.props.data.title}</p>
        <EditableTitle
          data={this.props.data}
          onEditData={this.props.onEditData}
        />
        <div className='ourPrimaryCustomerInner'>
          <Section
            name='longList'
            title='Long List'
            data={data}
            onEditData={onEditData}
          />
          <Section
            name='topValues'
            title='Top 4-5 Values'
            data={data}
            onEditData={onEditData}
          />
        </div>
      </div>
    );
  }
}

OurPrimaryCustomersValues.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
