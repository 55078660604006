import React, { Component } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';

import { InlineInput } from '../InlineInput.js';
import EditableTitle from '../EditableTitle';
import StatusIndicator from '../StatusIndicator.js';
import './LifePerspectiveFilter.scss';


const DEFAULT_STATUS = 1;


export const EMPTY_COLUMN = {
  title: "",
  items: {
    lifeExperiences: DEFAULT_STATUS,
    talents: DEFAULT_STATUS,
    heart: DEFAULT_STATUS,
    thinkingWavelength: DEFAULT_STATUS,
    internalWiring: DEFAULT_STATUS,
    coreValues: DEFAULT_STATUS,
    lifeDirectionPathway: DEFAULT_STATUS,
    results: DEFAULT_STATUS,
  }
};


function Row({ title, name, data, setStatus }) {
  return (
    <tr>
      <td>{title}{name === 'results' ? <span>One red means it&rsquo;s dead</span> : null}</td>
      {
        data.columns.map((column, i) =>
          <td key={i} data-title={column.title}>
            <StatusIndicator
              value={column.items[name]}
              onChange={setStatus ? (status) => setStatus(name, i, status) : null}
              altTexts={{
                null: "no description",
                1: "Great fit",
                2: "Good fit, but some caution",
                3: "Caution, some things to be worked on",
                4: "Caution, not a good fit",
                5: "Not a fit, no alignment"
              }}
            />
          </td>
        )
      }
      <td className="add"></td>
    </tr>
  );
}
Row.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  setStatus: PropTypes.func,
};

function columnReducer(state = EMPTY_COLUMN, action) {
  switch (action.type) {
    case 'SET_STATUS':
      return {
        ...state,
        items:  {
          ...state.items,
          [action.name]: action.status,
        },
      };
    case 'SET_TITLE':
      return {
        ...state,
        title: action.value,
      };
    default:
      return state;
  }
}

function reducer(state, action) {
  if (action.index !== undefined) {
    state = {
      ...state,
      columns: state.columns.map((column, i) =>
          action.index === i ? columnReducer(column, action) : column
      ),
    };
  }

  switch (action.type) {
    case 'ADD_COLUMN':
      return {
        ...state,
        columns: [...state.columns, columnReducer(undefined, action)],
      };
    case 'DELETE_COLUMN':
      return {
        ...state,
        columns: state.columns.filter((c, i) => i !== action.index),
      };
    default:
      return state;
  }
}

export default class LifePerspectiveFilter extends Component {
  state = {
    focusedIndex: null
  }

  render () {
    const { data, onEditData } = this.props;
    const setStatus = onEditData ? this.setStatus : null;
    const rowProps = {
      data,
      setStatus
    };

    return (
      <div className="chart">
        <p className="stickyTitle">{this.props.data.title}</p>
        <EditableTitle
          data={this.props.data}
          onEditData={this.props.onEditData}
        />
        <div className="dragInner">
          <table className="lifePerspectiveFilter filterTable">
            <thead>
              <tr>
                <th />
                {
                  data.columns.map((column, i) =>
                    <th key={i}>
                      {
                        onEditData ?
                          <div>
                            <button
                              className="delete"
                              title="Delete"
                              onClick={() =>
                                onEditData(reducer(data, {
                                  type: 'DELETE_COLUMN',
                                  index: i,
                                }))
                              }
                            ></button>
                            <InlineInput
                              value={column.title}
                              isFocused={i === this.state.focusedIndex}
                              blankPlaceholder='Add Opportunity Title...'
                              onChange={(val, enterPressed) => {
                                let newData = reducer(data, {
                                  type: 'SET_TITLE',
                                  index: i,
                                  value: val,
                                });

                                if (enterPressed && i === data.columns.length - 1) {
                                  newData = reducer(newData, {
                                    type: 'ADD_COLUMN',
                                  });
                                }
                                if (!_.isEqual(data, newData)) {
                                  onEditData(newData);
                                }
                                if (enterPressed) {
                                  this.setState({focusedIndex: i + 1});
                                } else {
                                  this.setState({focusedIndex: null});
                                }
                              }}
                            />
                          </div>
                        :
                          column.title
                      }
                    </th>
                  )
                }
                {
                  onEditData ?
                    <th className="add">
                      <button className="add" onClick={() => {
                        onEditData(reducer(data, {
                          type: 'ADD_COLUMN',
                        }));
                        this.setState({
                          focusedIndex: data.columns.length,
                        });
                      }}><span className="plus">+</span></button>
                    </th>
                  :
                    null
                }
              </tr>
            </thead>
            <tbody>
              <Row title="Life Experience" name="lifeExperiences" {...rowProps} />
              <Row title="Talents" name="talents" {...rowProps} />
              <Row title="Heart" name="heart" {...rowProps} />
              <Row title="Thinking Wavelength" name="thinkingWavelength" {...rowProps} />
              <Row title="Internal Wiring" name="internalWiring" {...rowProps} />
              <Row title="Core Values" name="coreValues" {...rowProps} />
              <Row title="Life Direction Pathway" name="lifeDirectionPathway" {...rowProps} />
            </tbody>
            <tfoot>
              <Row title="Results" name="results" {...rowProps} />
            </tfoot>
          </table>
        </div>
      </div>
    );
  }

  setStatus = (name, index, status) => {
    this.props.onEditData(reducer(this.props.data, {
      type: 'SET_STATUS',
      index: index,
      status: status,
      name: name,
    }));
  };
}

LifePerspectiveFilter.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
