import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import withScrolling from 'react-dnd-scrollzone';
import PropTypes from 'prop-types';

import * as actions from '../actions.js';
import WIDGET_METADATA from '../widgetMetadata.js';


const outlineDropTarget = {
  drop(props, monitor) {
    if (!monitor.didDrop()) {
      props.sendToEnd(monitor.getItem().index);
    }
  }
};

class Outline extends Component {
  render() {
    const { isOver, connectDropTarget, children } = this.props;

    return connectDropTarget(
      <ol className={classNames('outline', {isOver: isOver})}>
        {children}
      </ol>
    );
  }
}
Outline.propTypes = {
  children: PropTypes.array.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
};

Outline = DropTarget('WIDGET', outlineDropTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver({shallow: true}),
}))(Outline);

Outline = connect(null, {
  sendToEnd: (movedWidgetIndex) => ({
    type: 'SET_ORDER',
    oldIndex: movedWidgetIndex,
    newIndex: undefined
  }),
})(Outline);

Outline = DragDropContext(HTML5Backend)(Outline);

Outline = withScrolling(Outline);


export function reorderIndex(oldIndex, droppedOnIndex, direction) {
  if (droppedOnIndex === oldIndex - 1 && direction === 'below') {
    return oldIndex;
  } else if (droppedOnIndex === oldIndex + 1 && direction === 'above') {
    return oldIndex;
  }

  return droppedOnIndex + (direction === 'below') - (oldIndex === 0);
}

const outlineItemDropTarget = {
  hover(props, monitor, component) {
    const draggedData = monitor.getItem();
    if (draggedData.index === props.index) {
      return;
    }

    const mousePosition = monitor.getClientOffset();

    // the bounding box of the widget being hovered over
    // https://github.com/gaearon/react-dnd/issues/591
    // eslint-disable-next-line react/no-find-dom-node
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    // center of bounding box
    const hoverMiddleY = (hoverBoundingRect.top + hoverBoundingRect.bottom)/2;
    component.getDecoratedComponentInstance().setState({
      hoverPosition: mousePosition.y > hoverMiddleY ? 'below' : 'above',
    });
  },

  drop(props, monitor, component) {
    const draggedItemData = monitor.getItem();
    const editableWidgetInstance = component.getDecoratedComponentInstance();
    const hoverPosition = editableWidgetInstance.state.hoverPosition;

    const draggedIndex = draggedItemData.index;
    if (draggedIndex === props.index) {
      return;
    }
    props.dropOn(draggedIndex, reorderIndex(draggedIndex, props.widgetIndex, hoverPosition));
  }
};

class OutlineItem extends Component {
  render() {
    const { widget, scrollToWidget, connectDragSource, connectDropTarget,
      isOver, isDragging } = this.props;

    return connectDragSource(connectDropTarget(
      <li className={classNames({
        isOver: isOver,
        hoverBelow: isOver && this.state.hoverPosition === 'below',
        hoverAbove: isOver && this.state.hoverPosition === 'above',
        isDragging: isDragging,
      })}>
        <a onClick={scrollToWidget}>
          <i></i>
          {
            WIDGET_METADATA[widget.type].title(widget)
          }
        </a>
      </li>
    ));
  }
}

OutlineItem.propTypes = {
  widget: PropTypes.object.isRequired,
  scrollToWidget: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired,
};

const outlineDragSource = {
  beginDrag(props) {
    props.onBeginDrag();
    return {
      type: 'REORDER',
      index: props.widgetIndex,
    };
  },

  endDrag(props) {
    props.onEndDrag();
  },
};

OutlineItem = DragSource('WIDGET', outlineDragSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(OutlineItem);

OutlineItem = DropTarget('WIDGET', outlineItemDropTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(OutlineItem);

OutlineItem = connect(null, (dispatch, ownProps) => ({
  scrollToWidget: () =>
    dispatch(actions.scrollToWidget(ownProps.widget.id)),
  onBeginDrag: () =>
    dispatch({type: 'BEGIN_REORDER'}),
  onEndDrag: () =>
    dispatch({type: 'END_REORDER'}),
  dragHover: (hoveredIndex, position) => {
    dispatch({
      type: 'DRAG_HOVER',
      hoveredIndex: hoveredIndex,
      position: position,
    });
  },
  dropOn: (movedWidgetIndex, newIndex) => {
    dispatch({
      type: 'SET_ORDER',
      oldIndex: movedWidgetIndex,
      newIndex: newIndex,
    });
  },
}))(OutlineItem);

export { Outline, OutlineItem };
