import React, { Component } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';

import EditableTitle from '../EditableTitle';
import { InlineInput } from '../InlineInput.js';
import StatusIndicator from '../StatusIndicator.js';
import { arrayFill } from '../../utils.js';
import './LifePerspectiveFilter.scss';
import './VocationalGating.scss';
import { ViewIcon, HideIcon } from '../icons.js';


export const DEFAULT_STATUS = 1;


export const INITIAL_STATE = {
  title: "Vocational Gating Process",
  description: "We assess opportunities that survived the Life Perspective Filter and run those against your customized, non-negotiable major gates and important, tie-breaking minor gates. This helps you to prioritize any front-runner opportunities.",
  opportunities: [''],
  major: {
    gates: [''],
    cells: [
      [DEFAULT_STATUS]
    ],
    results: [DEFAULT_STATUS],
  },
  minor: {
    gates: [''],
    cells: [
      [DEFAULT_STATUS]
    ],
    results: [DEFAULT_STATUS],
  },
};

const STATUS_ALT_TEXTS = {
  null: "no description",
  1: "Great fit",
  2: "Good fit, but some caution",
  3: "Caution, some things to be worked on",
  4: "Caution, not a good fit",
  5: "Not a fit, no alignment"
};


function gateTableReducer(state, action) {
  switch (action.type) {
    case 'ADD_OPPORTUNITY':
      return {
        ...state,
        cells: state.cells.map((row) => [...row, DEFAULT_STATUS]),
        results: [...state.results, DEFAULT_STATUS],
      };
    case 'DELETE_OPPORTUNITY':
      return {
        ...state,
        cells: state.cells.map((row) =>
            row.filter((c, i) => i !== action.index)
        ),
        results: state.results.filter((r, i) => i !== action.index),
      };
    case 'SET_STATUS':
      return {
        ...state,
        cells: state.cells.map((row, i) =>
            i === action.gate ?
              row.map((status, j) =>
                  j === action.opportunity ? action.value : status)
            :
              row
        ),
      };
    case 'SET_RESULT':
      return {
        ...state,
        results: state.results.map((r, i) => i === action.index ? action.value : r),
      };
    case 'SET_GATE':
      return {
        ...state,
        gates: state.gates.map((gate, i) => i === action.index ? action.value : gate),
      };
    case 'DELETE_GATE':
      return {
        ...state,
        gates: state.gates.filter((gate, i) => i !== action.index),
      };
    default:
      return state;
  }
}

export function reducer(state=INITIAL_STATE, action) {
  state = {
    ...state,
    minor: (
      action.gateType === 'minor' || action.gateType === undefined
    ) ? gateTableReducer(state.minor, action) : state.minor,
    major: (
      action.gateType === 'major' || action.gateType === undefined
    ) ? gateTableReducer(state.major, action) : state.major,
  };

  switch (action.type) {
    case 'ADD_OPPORTUNITY':
      return {
        ...state,
        opportunities: [...state.opportunities, ''],
      };
    case 'DELETE_OPPORTUNITY':
      return {
        ...state,
        opportunities: state.opportunities.filter((_, i) => i !== action.index),
      };
    case 'ADD_GATE':
      // This action has to be handled here instead of in gateTableReducer
      // because it needs to know the number of opportunities (in order to know
      // how many columns the new row has).
      var table = state[action.gateType];
      return {
        ...state,
        [action.gateType]: {
          ...table,
          cells: [...table.cells, arrayFill(state.opportunities.length, DEFAULT_STATUS)],
          gates: [...table.gates, ''],
        },
      };
    case 'SET_OPPORTUNITY':
      return {
        ...state,
        opportunities: state.opportunities.map((o, i) => i === action.index ? action.value : o),
      };
    default:
      return state;
  }
}

function GateTable({ data, type, title, onEditData, focus, focusedGate }) {
  const { cells, gates, results } = data[type];
  const { opportunities } = data;

  return (
    <tbody>
      <tr className="gateHeader">
        <th>{title}</th>
        {
          opportunities.map((o, i) => <th key={i}/>)
        }
        <td className="add" />
      </tr>
      {
        gates.map((gate, gateIndex) =>
          <tr key={gateIndex}>
            <td>
              {
                onEditData ?
                  <div>
                    <button
                      title="Delete gate"
                      className="delete"
                      onClick={() =>
                          onEditData(reducer(data, {
                            type: 'DELETE_GATE',
                            index: gateIndex,
                            gateType: type,
                          }))
                      }
                    />
                    <InlineInput
                      value={gate}
                      blankPlaceholder="Add Gate Description..."
                      isFocused={focusedGate === gateIndex}
                      onChange={(val, enterPressed) => {
                        let newData = reducer(data, {
                          type: 'SET_GATE',
                          index: gateIndex,
                          value: val,
                          gateType: type,
                        });

                        if (enterPressed && gateIndex === gates.length - 1) {
                          newData = reducer(newData, {
                            type: 'ADD_GATE',
                            gateType: type,
                          });
                        }
                        if (!_.isEqual(data, newData)) {
                          onEditData(newData);
                        }

                        if (enterPressed) {
                          focus(gateIndex + 1);
                        } else {
                          focus(null);
                        }
                      }}
                    />
                  </div>
                :
                  <span>{gate}</span>
              }
            </td>
            {
              cells[gateIndex].map((cell, cellIndex) =>
                <td key={cellIndex} data-title={opportunities[cellIndex]}>
                  <StatusIndicator
                    value={cell}
                    onChange={
                      onEditData ?
                        (val) =>
                          onEditData(reducer(data, {
                            type: 'SET_STATUS',
                            gateType: type,
                            gate: gateIndex,
                            opportunity: cellIndex,
                            value: val,
                          }))
                      :
                        null
                    }
                    altTexts={STATUS_ALT_TEXTS}
                  />
                </td>
              )
            }
            <td className="add" />
          </tr>
        )
      }
      <tr className="add">
        <td>
          {
            onEditData ?
              <button
                className="add"
                onClick={() =>
                    onEditData(reducer(data, {
                      type: 'ADD_GATE',
                      gateType: type,
                    }))
                }
              ><span className="plus">+</span> Add Gate</button>
            :
              null
          }
        </td>
        {
          opportunities.map((o, i) => <td key={i} />)
        }
        <td className="add" />
      </tr>
      <tr className="results">
        <td>
          Results
        </td>
        {
          results.map((result, i) =>
            <td key={i} data-title={opportunities[i]}>
              <StatusIndicator
                value={result}
                onChange={
                  onEditData ?
                    (val) =>
                      onEditData(reducer(data, {
                        type: 'SET_RESULT',
                        index: i,
                        value: val,
                        gateType: type,
                      }))
                  :
                    null
                }
                altTexts={STATUS_ALT_TEXTS}
              />
            </td>
          )
        }
        <td className="add" />
      </tr>
    </tbody>
  );
}

GateTable.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onEditData: PropTypes.func,
  focus: PropTypes.func.isRequired,
  focusedGate: PropTypes.number,
};

export default class VocationalGating extends Component {
  state = {
    focusedIndex: null
  };

  render() {
    const { data, onEditData } = this.props;

    return (
      <div className="chart">
        <div>
          <p className="stickyTitle">{data.title}</p>
          <EditableTitle
            data={data}
            onEditData={onEditData}
          />
        </div>
        <div className="dragInner">
          <table className="vocationalGating filterTable">
            <thead>
              <tr>
                <th />
                {
                  data.opportunities.map((opportunity, i) =>
                    <th key={i}>
                      {
                        onEditData ?
                          <div>
                            <InlineInput
                              value={opportunity}
                              blankPlaceholder="Add Opportunity Title..."
                              isFocused={
                                this.state.focusedIndex &&
                                this.state.focusedIndex.type === 'opportunity' &&
                                this.state.focusedIndex.index === i
                              }
                              onChange={(val, enterPressed) => {
                                let newData = reducer(data, {
                                  type: 'SET_OPPORTUNITY',
                                  index: i,
                                  value: val,
                                });
                                if (enterPressed && i === data.opportunities.length - 1) {
                                  newData = reducer(newData, {
                                    type: 'ADD_OPPORTUNITY',
                                  });
                                }
                                if (!_.isEqual(data, newData)) {
                                  onEditData(newData);
                                }
                                if (enterPressed) {
                                  this.setState({
                                    focusedIndex: {
                                      type: 'opportunity',
                                      index: i + 1,
                                    },
                                  });
                                } else {
                                  this.setState({focusedIndex: null});
                                }
                              }}
                            />
                            <button
                              className="delete"
                              title="Delete opportunity"
                              onClick={() =>
                                  onEditData(reducer(data, {
                                    type: 'DELETE_OPPORTUNITY',
                                    index: i,
                                  }))
                              }
                            />
                          </div>
                        :
                          <span>{opportunity}</span>
                      }
                    </th>
                  )
                }
                <th className="add">
                  {
                    onEditData ?
                      <button className="add"
                        onClick={() => {
                          onEditData(reducer(data, {
                            type: 'ADD_OPPORTUNITY',
                          }));
                        }}><span className="plus">+</span></button>
                    :
                      null
                  }
                </th>
              </tr>
            </thead>
            <GateTable
              data={data}
              type="major"
              title="major gates"
              onEditData={onEditData}
              focusedGate={
                this.state.focusedIndex && this.state.focusedIndex.type === 'major' ?
                  this.state.focusedIndex.index
                :
                  null
              }
              focus={this.handleGatefocus('major')}
            />
            <tbody>
              <tr className="spacer"><td></td></tr>
            </tbody>
            {
              onEditData
                ? (
                  <button
                    onClick={() =>
                      onEditData({
                        ...data,
                        hideMinor: !data.hideMinor,
                      })
                    }
                    >
                  {data.hideMinor ? <><ViewIcon /> Show</> : <><HideIcon /> Hide</>} Minor Gates
                </button>
                ) : null
            }
            {
              data.hideMinor ? null : (
                <GateTable
                  data={data}
                  type="minor"
                  title="minor gates"
                  onEditData={onEditData}
                  focusedGate={
                    this.state.focusedIndex &&
                    this.state.focusedIndex.type === 'minor'
                      ? this.state.focusedIndex.index
                      : null
                  }
                  focus={this.handleGatefocus('minor')}
                />
              )
            }
          </table>
        </div>
      </div>
    );
  }

  handleGatefocus(gateType) {
    return (index) => {
      if (index === null) this.setState({focusedIndex: null});
      else this.setState({focusedIndex: {index, type: gateType}});
    };
  }
}

VocationalGating.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
