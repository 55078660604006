import {v4 as uuid4} from 'uuid';
import _ from 'underscore';

import document from './document.js';


export const PLAYBOOK_TYPES = {
  'lifeplan': 'LifePlan',
  'stratop': 'StratOp',
};


export function addClientSideState(playbook) {
  return {
    ...playbook,
    document: {
      present: {
        ...playbook.document,
        widgets: playbook.document.widgets.map((w) => ({
          id: uuid4(),
          ...w,
        })),
      },
      past: [],
      future: [],
    }
  };
}


export function removeClientSideState(playbook) {
  playbook = removeUndoState(playbook);
  return {
    ...playbook,
    document: {
      ...playbook.document,
      widgets: playbook.document.widgets.map((w) => _.omit(w, 'id')),
    }
  };
}


export function removeUndoState(playbook) {
  return {
    ...playbook,
    document: playbook.document.present,
  };
}


function playbook(state=null, action) {
  if (state) {
    state = {
      ...state,
      document: document(state.document, action),
    };
  }

  switch (action.type) {
    case 'PLAYBOOK_LOADED':
      return addClientSideState(action.playbook);
    case 'SAVE_METADATA':
      return {
        ...state,
        ...action.metadata,
      };
    case 'SAVE_COMPLETE':
      return {
        ...state,
        version: action.playbook.version,
      };
    case 'CLEAR_PLAYBOOK':
      return null;
    case 'PERMISSIONS_LOADED':
      return {
        ...state,
        permissionsList: action.permissions,
      };
    case 'SET_PERMISSION_LEVEL':
      var permissionsList;
      if (action.level === null) {
        permissionsList = _.filter(state.permissionsList, (p) => p.user.email !== action.email);
      } else {
        permissionsList = state.permissionsList.map((p) =>
          p.user.email === action.email ? {...p, level: action.level} : p
        );
      }
      return {
        ...state,
        permissionsList,
      };
    default:
      return state;
  }
}

export default playbook;
