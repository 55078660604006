import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditableTitle from '../EditableTitle';
import RichTextEditor from '../RichTextEditor.js';
import './FountainOfYouth.scss';

import arc from '../../image/foy-path-mobile.png';


const Column = ({name, data, onChange}) => {
  const changeCallback = onChange(name);

  return (
    <div className="column">
      <h2>
        {name}
        <img src={arc} alt="" />
      </h2>
      <div className="textWrap">
        <RichTextEditor
          value={data[name]}
          onChange={changeCallback}
          blankPlaceholder="Add Opportunities..."
          readonly={!changeCallback}
        />
      </div>
    </div>
  );
};

Column.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};


class FountainOfYouth extends Component {
  render() {
    return (
      <div className="chart fountainOfYouth">
        <p className="stickyTitle">{this.props.data.title}</p>
        <EditableTitle
          data={this.props.data}
          onEditData={this.props.onEditData}
        />
        <div className="dragInner">
          <div className="dataWrapper">
            <div className="columns data">
              <Column name="sensing" data={this.props.data} onChange={this.onChange} />
              <Column name="seizing" data={this.props.data} onChange={this.onChange} />
              <Column name="building" data={this.props.data} onChange={this.onChange} />
              <Column name="reaping" data={this.props.data} onChange={this.onChange} />
            </div>
          </div>
          <div className="columns titles">
            <div><h2>Sensing</h2></div>
            <div><h2>Seizing</h2></div>
            <div><h2>Building</h2></div>
            <div><h2>Reaping</h2></div>
          </div>
          <div className="columns label">
            <div><h1>Fountain of Youth</h1></div>
          </div>
        </div>
      </div>
    );
  }

  onChange = (columnName) => {
    if (this.props.onEditData) {
      return (newValue) => {
        this.props.onEditData({
          ...this.props.data,
          [columnName]: newValue,
        });
      };
    } else {
      return null;
    }
  }
}

FountainOfYouth.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};

export default FountainOfYouth;
