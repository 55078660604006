import React, {Component} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import _ from 'underscore';

import EditableTitle from '../EditableTitle';
import WinWheel from './WinWheel.js';
import StatusAndTrendIndicator from '../StatusAndTrendIndicator.js';
import { InlineInput } from '../InlineInput.js';
import {getDomainColour} from './LifeInitiativesProfile.js';

import './InitiativesProfile.scss';

export const INITIAL_STATE = {
  title: "Action Initiatives Profile",
  description: "The purpose of this tool is to profile each Action Initiative on the W.I.N. Wheel with a clearly-stated objective, expected key deliverables, a targeted delivery date, one team leader, cross-functional team members, and a current red, yellow, or green status.",
  coreIssues: [
    {
      name: "",
      objectives: [
        {
          objective: "",
          planName: "",
          deliverables: [
            {deliverable: '', date: ''}
          ],
          team: '',
          leader: '',
          todaysStatus: null,
        },
      ]
    }
  ],
};



export function reducer(state=INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_DELIVERABLE':
      return update(state, {
        coreIssues: {
          [action.coreIssueIndex]: {
            objectives: {
              [action.objectiveIndex]: {
                deliverables: {
                  $push: [{deliverable: '', date: ''}],
                }
              }
            }
          }
        }
      });
    case 'DELETE_DELIVERABLE':
      return update(state, {
        coreIssues: {
          [action.coreIssueIndex]: {
            objectives: {
              [action.objectiveIndex]: {
                deliverables: {
                  $splice: [[action.deliverableIndex, 1]]
                }
              }
            }
          }
        }
      });
    case 'SET_DELIVERABLE_TEXT':
    case 'SET_DELIVERABLE_DATE':
      {
        let deliverable;
        if (action.type === 'SET_DELIVERABLE_TEXT') {
          deliverable = {
            deliverable: {
              $set: action.value
            }
          };
        } else {
          deliverable = {
            date: {
              $set: action.value
            }
          };
        }

        return update(state, {
          coreIssues: {
            [action.coreIssueIndex]: {
              objectives: {
                [action.objectiveIndex]: {
                  deliverables: {
                    [action.deliverableIndex]: deliverable
                  }
                }
              }
            }
          }
        });
      }
    case 'SET_OBJECTIVE':
    case 'SET_PLAN_NAME':
    case 'SET_TODAYS_STATUS':
    case 'SET_TEAM':
    case 'SET_LEADER':
      {
        let objective;
        if (action.type === 'SET_OBJECTIVE') {
          objective = {
            objective: {
              $set: action.value
            }
          };
        } else if (action.type === 'SET_PLAN_NAME') {
          objective = {
            planName: {
              $set: action.value
            }
          };
        } else if (action.type === 'SET_TODAYS_STATUS') {
          objective = {
            todaysStatus: {
              $set: action.value
            }
          };
        } else if (action.type === 'SET_LEADER') {
          objective = {
            leader: {
              $set: action.value
            }
          };
        } else if (action.type === 'SET_TEAM') {
          objective = {
            team: {
              $set: action.value
            }
          };
        } else {
          throw 'invalid action type ' + action.type;
        }

        return update(state, {
          coreIssues: {
            [action.coreIssueIndex]: {
              objectives: {
                [action.objectiveIndex]: objective
              }
            }
          }
        });
      }
    case 'DELETE_OBJECTIVE':
      return {
        ...state,
        coreIssues: state.coreIssues.map((coreIssue, i) =>
          i === action.coreIssueIndex ?
            {
              ...coreIssue,
              objectives: coreIssue.objectives.filter((objective, j) => j !== action.objectiveIndex)
            }
          :
            coreIssue
        ).filter((coreIssue) =>
          coreIssue.objectives.length !== 0
        )
      };
    case 'ADD_OBJECTIVE':
      {
        const emptyObjective = {
          planName: '',
          objective: '',
          deliverables: [{deliverable: '', date: ''}],
          leader: '',
          team: '',
          todaysStatus: null,
        };

        if (state.coreIssues.length > 0) {
          return update(state, {
            coreIssues: {
              [state.coreIssues.length - 1]: {
                objectives: {
                  $push: [emptyObjective]
                }
              }
            }
          });
        } else {
          return {
            ...state,
            coreIssues: [
              {
                name: '',
                objectives: [emptyObjective]
              }
            ],
          };
        }
      }
    case 'SET_OBJECTIVE_CORE_ISSUE':
      if (action.value || (action.coreIssueIndex === 0 && action.objectiveIndex === 0)) {
        // Editing the coreIssue of an objective splits it and creates a new coreIssue
        const coreIssues = state.coreIssues.slice();
        const coreIssue = coreIssues[action.coreIssueIndex];

        const before = {
          ...coreIssue,
          objectives: coreIssue.objectives.filter((objective, i) => i < action.objectiveIndex)
        };

        const after = {
          ...coreIssue,
          name: action.value,
          objectives: coreIssue.objectives.filter((objective, i) => i >= action.objectiveIndex)
        };

        const replacements = [];
        if (before.objectives.length) {
          replacements.push(before);
        }
        if (after.objectives.length) {
          replacements.push(after);
        }
        coreIssues.splice(action.coreIssueIndex, 1, ...replacements);

        return {
          ...state,
          coreIssues: coreIssues,
        };
      } else if (action.objectiveIndex === 0) {
        // Blanking the first objective of a coreIssue joins it with the previous coreIssue
        const coreIssues = state.coreIssues.slice();
        const coreIssue = coreIssues[action.coreIssueIndex - 1];

        coreIssues.splice(action.coreIssueIndex - 1, 2, {
          ...coreIssue,
          objectives: coreIssue.objectives.concat(coreIssues[action.coreIssueIndex].objectives)
        });

        return {
          ...state,
          coreIssues: coreIssues,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}


export function focusNextType(params, data) {
  let { type, coreIssueIndex, objectiveIndex } = params;

  if (type === 'DELIVERABLE') {
    return {
      ...params,
      type: 'DATE'
    };
  } else if (type === 'DATE') {
    return {
      ...params,
      type: 'DELIVERABLE',
      deliverableIndex: params.deliverableIndex + 1
    };
  } else {
    const lastObjectiveInCoreIssue =
      objectiveIndex === data.coreIssues[coreIssueIndex].objectives.length - 1;

    return {
      type: type,
      coreIssueIndex: lastObjectiveInCoreIssue ? coreIssueIndex + 1 : coreIssueIndex,
      objectiveIndex: lastObjectiveInCoreIssue ? 0 : objectiveIndex + 1,
    };
  }
}


function getWinWheelData(data) {
  return {
    groups: data.coreIssues.map((coreIssue) => ({
      name: coreIssue.name,
      items: coreIssue.objectives.map((objective) => ({
        name: objective.planName
      })),
    }))
  };
}


export default class ActionInitiativesProfile extends Component {
  state = {
    focusedIndex: null,
  }

  render() {
    const { data, onEditData } = this.props;

    return (
      <div className="chart actionInitiativesProfile">
        <p className="stickyTitle">{data.title}</p>
        <EditableTitle
          data={data}
          onEditData={onEditData}
        />

        <div className="tableOverflowWrapper">
          <table>
            <thead>
              <tr>
                <th className="initiativesProfileGroup">
                  Core Issue
                </th>
                <th className="initiativesProfilePlanName">
                  Plan Name
                </th>
                <th className="initiativesProfileObjective">
                  Objective
                </th>
                <th className="initiativesProfileNext">
                  Deliverables<span className="by"> (By)</span>
                </th>
                <th className="initiativesProfileStart">
                  By
                </th>
                <th className="initiativesProfileLeader">
                  Leader
                </th>
                <th className="initiativesProfileTeam">
                  <span className="leader">(Leader) </span>Team
                </th>
                <th className="initiativesProfileStatus">
                  {"Today's Status"}
                </th>
              </tr>
            </thead>
            <tbody>
              {
                _.flatten(
                  data.coreIssues.map((coreIssue, coreIssueIndex) =>
                    onEditData ?
                      coreIssue.objectives.map((objective, objectiveIndex) =>
                        <tr key={this.rowIndex(coreIssueIndex, objectiveIndex)}>
                          <td>
                            <div>
                              <button
                                title="Delete"
                                className="delete"
                                onClick={() =>
                                  onEditData(reducer(data, {
                                    type: 'DELETE_OBJECTIVE',
                                    coreIssueIndex: coreIssueIndex,
                                    objectiveIndex: objectiveIndex,
                                  }))
                                }
                              />
                              <InlineInput
                                value={objectiveIndex === 0 ? coreIssue.name : ''}
                                blankPlaceholder={coreIssue.name || 'Add Core Issue...'}
                                onChange={(value) => {
                                  onEditData(reducer(data, {
                                    type: 'SET_OBJECTIVE_CORE_ISSUE',
                                    coreIssueIndex: coreIssueIndex,
                                    objectiveIndex: objectiveIndex,
                                    value: value,
                                  }));

                                  this.setState({focusedIndex: null});
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <InlineInput
                              value={objective.planName}
                              blankPlaceholder='Set plan name...'
                              onChange={(value, enterPressed) => {
                                let newData = reducer(data, {
                                  type: 'SET_PLAN_NAME',
                                  coreIssueIndex: coreIssueIndex,
                                  objectiveIndex: objectiveIndex,
                                  value: value,
                                });

                                onEditData(newData);

                                this.setState({focusedIndex: null});
                              }}
                              isFocused={_.isEqual(this.state.focusedIndex, {
                                type: 'PLAN_NAME',
                                coreIssueIndex: coreIssueIndex,
                                objectiveIndex: objectiveIndex,
                              })}
                            />
                          </td>
                          <td>
                            <InlineInput
                              value={objective.objective}
                              blankPlaceholder='Set objective...'
                              onChange={(value, enterPressed) => {
                                let newData = reducer(data, {
                                  type: 'SET_OBJECTIVE',
                                  coreIssueIndex: coreIssueIndex,
                                  objectiveIndex: objectiveIndex,
                                  value: value,
                                });

                                if (
                                  enterPressed && coreIssueIndex === data.coreIssues.length - 1 &&
                                  objectiveIndex === data.coreIssues[data.coreIssues.length - 1].objectives.length - 1
                                ) {
                                  newData = reducer(newData, {type: 'ADD_OBJECTIVE'});
                                }

                                onEditData(newData);

                                if (enterPressed) {
                                  this.setState({
                                    focusedIndex: focusNextType({
                                      type: 'PLAN_NAME',
                                      coreIssueIndex: coreIssueIndex,
                                      objectiveIndex: objectiveIndex,
                                    }, newData)
                                  });
                                } else {
                                  this.setState({focusedIndex: null});
                                }
                              }}
                              isFocused={_.isEqual(this.state.focusedIndex, {
                                type: 'OBJECTIVE',
                                coreIssueIndex: coreIssueIndex,
                                objectiveIndex: objectiveIndex,
                              })}
                            />
                          </td>
                          <td colSpan="2">
                            {
                              objective.deliverables.map((deliverable, deliverableIndex) =>
                                <div key={deliverableIndex}>
                                  <button
                                    title="Delete Deliverable"
                                    className="delete"
                                    onClick={() =>
                                      onEditData(reducer(data, {
                                        type: 'DELETE_DELIVERABLE',
                                        coreIssueIndex: coreIssueIndex,
                                        objectiveIndex: objectiveIndex,
                                        deliverableIndex: deliverableIndex,
                                      }))
                                    }
                                  />
                                  <span className="deliverable">
                                    <InlineInput
                                      value={deliverable.deliverable}
                                      blankPlaceholder="Set deliverable..."
                                      onChange={(value, enterPressed) => {
                                        onEditData(reducer(data, {
                                          type: 'SET_DELIVERABLE_TEXT',
                                          coreIssueIndex: coreIssueIndex,
                                          objectiveIndex: objectiveIndex,
                                          deliverableIndex: deliverableIndex,
                                          value: value,
                                        }));

                                        if (enterPressed) {
                                          this.setState({
                                            focusedIndex: focusNextType({
                                              type: 'DELIVERABLE',
                                              coreIssueIndex: coreIssueIndex,
                                              objectiveIndex: objectiveIndex,
                                              deliverableIndex: deliverableIndex,
                                            }, data)
                                          });
                                        } else {
                                          this.setState({focusedIndex: null});
                                        }
                                      }}
                                      isFocused={_.isEqual(this.state.focusedIndex, {
                                        type: 'DELIVERABLE',
                                        coreIssueIndex: coreIssueIndex,
                                        objectiveIndex: objectiveIndex,
                                        deliverableIndex: deliverableIndex,
                                      })}
                                    />
                                  </span>
                                  <span className="date">
                                    <InlineInput
                                      value={deliverable.date}
                                      blankPlaceholder="Set date..."
                                      onChange={(value, enterPressed) => {
                                        let newData = reducer(data, {
                                          type: 'SET_DELIVERABLE_DATE',
                                          coreIssueIndex: coreIssueIndex,
                                          objectiveIndex: objectiveIndex,
                                          deliverableIndex: deliverableIndex,
                                          value: value,
                                        });

                                        if (enterPressed &&
                                          deliverableIndex === objective.deliverables.length - 1
                                        ) {
                                          newData = reducer(newData, {
                                            type: 'ADD_DELIVERABLE',
                                            coreIssueIndex: coreIssueIndex,
                                            objectiveIndex: objectiveIndex,
                                          });
                                        }

                                        onEditData(newData);

                                        if (enterPressed) {
                                          this.setState({
                                            focusedIndex: focusNextType({
                                              type: 'DATE',
                                              coreIssueIndex: coreIssueIndex,
                                              objectiveIndex: objectiveIndex,
                                              deliverableIndex: deliverableIndex,
                                            }, data)
                                          });
                                        } else {
                                          this.setState({focusedIndex: null});
                                        }
                                      }}
                                      isFocused={_.isEqual(this.state.focusedIndex, {
                                        type: 'DATE',
                                        coreIssueIndex: coreIssueIndex,
                                        objectiveIndex: objectiveIndex,
                                        deliverableIndex: deliverableIndex,
                                      })}
                                    />
                                  </span>
                                </div>
                              )
                            }
                            <button
                              className="add"
                              onClick={() => {
                                onEditData(reducer(data, {
                                  type: 'ADD_DELIVERABLE',
                                  coreIssueIndex: coreIssueIndex,
                                  objectiveIndex: objectiveIndex,
                                }));

                                this.setState({focusedIndex: {
                                  type: 'DELIVERABLE',
                                  coreIssueIndex: coreIssueIndex,
                                  objectiveIndex: objectiveIndex,
                                  deliverableIndex: objective.deliverables.length
                                }});
                              }}
                            >
                              <span className="plus">+ </span>
                              Add Deliverable
                            </button>
                          </td>
                          <td>
                            <InlineInput
                              value={objective.leader}
                              blankPlaceholder='Set leader...'
                              onChange={(value, enterPressed) => {
                                onEditData(reducer(data, {
                                  type: 'SET_LEADER',
                                  coreIssueIndex: coreIssueIndex,
                                  objectiveIndex: objectiveIndex,
                                  value: value,
                                }));

                                if (enterPressed) {
                                  this.setState({
                                    focusedIndex: focusNextType({
                                      type: 'LEADER',
                                      coreIssueIndex: coreIssueIndex,
                                      objectiveIndex: objectiveIndex,
                                    }, data)
                                  });
                                } else {
                                  this.setState({focusedIndex: null});
                                }
                              }}
                              isFocused={_.isEqual(this.state.focusedIndex, {
                                type: 'LEADER',
                                coreIssueIndex: coreIssueIndex,
                                objectiveIndex: objectiveIndex,
                              })}
                            />
                          </td>
                          <td>
                            <InlineInput
                              value={objective.team}
                              blankPlaceholder='Set team...'
                              onChange={(value, enterPressed) => {
                                onEditData(reducer(data, {
                                  type: 'SET_TEAM',
                                  coreIssueIndex: coreIssueIndex,
                                  objectiveIndex: objectiveIndex,
                                  value: value,
                                }));

                                if (enterPressed) {
                                  this.setState({
                                    focusedIndex: focusNextType({
                                      type: 'TEAM',
                                      coreIssueIndex: coreIssueIndex,
                                      objectiveIndex: objectiveIndex,
                                    }, data)
                                  });
                                } else {
                                  this.setState({focusedIndex: null});
                                }
                              }}
                              isFocused={_.isEqual(this.state.focusedIndex, {
                                type: 'TEAM',
                                coreIssueIndex: coreIssueIndex,
                                objectiveIndex: objectiveIndex,
                              })}
                            />
                          </td>
                          <td>
                            <StatusAndTrendIndicator
                              value={objective.todaysStatus}
                              onChange={(value) =>
                                onEditData(reducer(data, {
                                  type: 'SET_TODAYS_STATUS',
                                  coreIssueIndex: coreIssueIndex,
                                  objectiveIndex: objectiveIndex,
                                  value: value
                                }))
                              }
                            />
                          </td>

                        </tr>
                      )
                    :
                      coreIssue.objectives.map((objective, objectiveIndex) =>
                        <tr key={this.rowIndex(coreIssueIndex, objectiveIndex)}>
                          {
                            objectiveIndex === 0 ?
                              <td
                                className="coreIssue"
                                rowSpan={coreIssue.objectives.length}
                                style={{
                                  backgroundColor: getDomainColour(coreIssueIndex)
                                }}
                              >
                                <span>{coreIssue.name}</span>
                              </td>
                            :
                              null
                          }
                          <td>
                            {objective.planName}
                          </td>
                          <td>
                            {objective.objective}
                          </td>
                          <td colSpan="2">
                            {
                              objective.deliverables.map((deliverable, deliverableIndex) =>
                                <div key={deliverableIndex} className="deliverableWrap">
                                  <span className="deliverable">{deliverable.deliverable}</span>
                                  <span className="date">{deliverable.date}</span>
                                </div>
                              )
                            }
                          </td>
                          <td>
                            {objective.leader}
                          </td>
                          <td>
                            <span className="leader">({objective.leader})</span> {objective.team}
                          </td>
                          <td>
                            <StatusAndTrendIndicator
                              value={objective.todaysStatus}
                            />
                          </td>
                        </tr>
                      )
                  )
                )
              }

              {
                onEditData ?
                  <tr className="add">
                    <td>
                      <button
                        className="add"
                        onClick={() => {
                          const newData = reducer(data, {
                            type: 'ADD_OBJECTIVE',
                          });
                          onEditData(newData);

                          this.setState({focusedIndex: {
                            type: 'CORE_ISSUE',
                            coreIssueIndex: newData.coreIssues.length - 1,
                            objectiveIndex: newData.coreIssues[newData.coreIssues.length - 1].objectives.length - 1
                          }});
                        }}
                      >
                        <span className="plus">+</span> Add Objective
                      </button>
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                :
                  null
              }
            </tbody>
          </table>
        </div>

        <WinWheel data={getWinWheelData(data)} />
      </div>
    );
  }

  rowIndex = (coreIssueIndex, objectiveIndex) => {
    let accumulator = 0;
    for (let i=0; i < coreIssueIndex; i++) {
      accumulator += this.props.data.coreIssues[i].objectives.length;
    }

    accumulator += objectiveIndex;

    return accumulator;
  }
}

ActionInitiativesProfile.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
