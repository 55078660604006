import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import cx from 'classnames';

import * as actions from '../actions.js';
import { isValidEmail } from '../utils.js';
import PermissionLevel, { LABELS } from '../PermissionLevel.js';
import './ShareModal.scss';


function PermissionLevelSelect({value, onChange}) {
  return (
    <select value={value} onChange={(event) => onChange(parseInt(event.target.value))}>
      {
        Object.keys(LABELS).map((i) =>
          <option value={i} key={i}>{LABELS[i]}</option>
        )
      }
    </select>
  );
}
PermissionLevelSelect.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};


class PermissionEditor extends Component {
  state = {
    saving: false,
    sent: false,
  }

  render() {
    let {permission} = this.props;

    let status;
    if (this.state.sent) {
      status = <span className="sent">SENT</span>;
    } else if (this.state.saving) {
      status = (
        <button
          className="resend"
          type="button"
          disabled
        >Resend</button>
      );
    } else if (permission.has_usable_password) {
      status = <span className="status">ACTIVE</span>;
    } else {
      status = (
        <button
          className="resend"
          type="button"
          onClick={this.resendInvite}
        >Resend</button>
      );
    }

    return (
      <div className="permissionEditor">
        <span className="email"><span className="textwrap">{permission.user.email}</span></span>
        <PermissionLevelSelect
          value={permission.level}
          onChange={this.setPermissionLevel}
        />
        { status }
        <button
          className="delete"
          type="button"
          onClick={() => this.setPermissionLevel(null)}
          disabled={this.state.saving}
        ></button>
      </div>
    );
  }

  resendInvite = async () => {
    this.setState({
      saving: true,
    });
    await this.props.resendInvite(this.props.permission.user.email);
    this.setState({
      saving: false,
      sent: true,
    });
  }

  setPermissionLevel = async (value) => {
    this.setState({
      saving: true,
    });
    await this.props.setPermissionLevel(this.props.permission.user.email, value);
    this.setState({
      saving: false,
    });
  }
}
PermissionEditor.propTypes = {
  permission: PropTypes.object.isRequired,
  setPermissionLevel: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
};


function PermissionViewer({permission}) {
  return (
    <div className="permissionViewer">
      <span className="email"><span className="textwrap">{permission.user.email}</span></span>
      <span className="level">{LABELS[permission.level]}</span>
      <span className="status">ACTIVE</span>
    </div>
  );
}
PermissionViewer.propTypes = {
  permission: PropTypes.object.isRequired,
};


function PermissionItem({permission, setPermissionLevel, resendInvite, isSelf}) {
  if (isSelf) {
    return <PermissionViewer permission={permission} />;
  } else {
    return <PermissionEditor
      permission={permission}
      setPermissionLevel={setPermissionLevel}
      resendInvite={resendInvite}
    />;
  }
}
PermissionItem.propTypes = {
  permission: PropTypes.object.isRequired,
  isSelf: PropTypes.bool.isRequired,
  setPermissionLevel: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
};


class AddPermissionForm extends Component {
  state = {
    email: '',
    permissionLevel: PermissionLevel.VIEW,
    error: null,
  }

  render() {
    return (
      <form onSubmit={this.add} noValidate>
        {
          this.state.error ?
            <p className="error">{this.state.error}</p>
          :
            null
        }
        <input
          type="email"
          value={this.state.email}
          onChange={this.onEmailChange}
          placeholder="example@email.com"
          ref={(x) => this.emailInput = x}
        />
        <PermissionLevelSelect
          value={this.state.permissionLevel}
          onChange={this.onPermissionLevelChange}
        />
        <button type="submit">add</button>
      </form>
    );
  }

  onEmailChange = (event) => {
    this.setState({
      email: event.target.value,
      error: null,
    });
  }

  onPermissionLevelChange = (level) => {
    this.setState({permissionLevel: level});
  }

  add = (event) => {
    event.preventDefault();
    if (!isValidEmail(this.state.email)) {
      this.setState({
        error: "Enter a valid email",
      });
    } else {
      this.props.setPermissionLevel(this.state.email, this.state.permissionLevel)
        .catch((error) =>
          this.setState({
            error: error.errors.email[0],
          })
        );
      this.setState({
        email: '',
        error: null,
      });
    }
    this.emailInput.focus();
  }
}
AddPermissionForm.propTypes = {
  setPermissionLevel: PropTypes.func.isRequired,
};


class ShareModal extends Component {
  state = {
    saving: false,
  }

  componentDidMount() {
    this.props.loadPermissions();
  }

  render() {
    const { playbook, session, closeModal } = this.props;

    let inner;
    if (playbook.permissionsList !== undefined) {
      let permissionsList = _.sortBy(playbook.permissionsList, (p) => p.user.email);
      inner = (
        <div className={cx({saving: this.state.saving})}>
          <h2>Playbook shared with:</h2>
          <ul>
            {
              permissionsList.map((p, i) =>
                <li key={i}>
                  <PermissionItem
                    permission={p}
                    setPermissionLevel={this.setPermissionLevel}
                    resendInvite={this.resendInvite}
                    isSelf={p.user.email === session.user.email}
                  />
                </li>
              )
            }
          </ul>
          <AddPermissionForm
            setPermissionLevel={this.setPermissionLevel}
          />
        </div>
      );

    } else {
      inner = <div className="loading">Loading...</div>;
    }

    return (
      <React.Fragment>
        <div
          className="overlay"
          onClick={closeModal}
        />
        <div className="share">
          { inner }
          <button
            className="close"
            onClick={closeModal}
          />
        </div>
      </React.Fragment>
    );
  }

  setPermissionLevel = (email, level) => {
    this.setState({saving: true});
    return this.props.setPermissionLevel(email, level, false)
      .then(() => this.setState({saving: false}));
  }

  resendInvite = (email) => {
    this.setState({saving: true});
    let currentLevel = _.find(
      this.props.playbook.permissionsList,
      (p) => p.user.email === email
    ).level;
    return this.props.setPermissionLevel(email, currentLevel, true)
      .then(() => this.setState({saving: false}));
  }
}

ShareModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  playbook: PropTypes.object.isRequired,
  loadPermissions: PropTypes.func.isRequired,
  setPermissionLevel: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};


ShareModal = connect(
  (state) => ({
    playbook: state.playbook,
    session: state.session,
  }),
  {
    loadPermissions: actions.loadPermissions,
    setPermissionLevel: actions.setPermissionLevel,
  }
)(ShareModal);

export default ShareModal;
