import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './PlaybooksPagination.scss';


export default class PlaybooksPagination extends Component {
  render() {
    return (
      <div className="pagination">
        {this.props.previous ? (
          <Link to={this.props.previous}>Previous</Link>
        ) : null}
        <span>
          Page {this.props.currentPage} of {this.props.numPages}
        </span>
        {this.props.next ? (
          <Link to={this.props.next}>Next</Link>
        ) : null}
      </div>
    );
  }
}

PlaybooksPagination.propTypes = {
  next: PropTypes.string,
  previous: PropTypes.string,
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
};

PlaybooksPagination = connect(
  (state) => ({
    next: state.api.playbooks?.next,
    previous: state.api.playbooks?.previous,
    currentPage: state.api.playbooks?.current_page,
    numPages: state.api.playbooks?.num_pages,
  })
)(PlaybooksPagination);
