import React, {Component} from 'react';
import update from 'immutability-helper';
import _ from 'underscore';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import RichTextEditor from '../RichTextEditor.js';
import { InlineInput, InlineTextarea } from '../InlineInput.js';
import StatusAndTrendIndicator from '../StatusAndTrendIndicator.js';
import EditableTitle from '../EditableTitle';
import { StatusSlider, StatusDisplay } from '../StatusSlider';

import './Dashboard.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const EMPTY_RISK = {name: '', status: null, magnitude: 3};
const EMPTY_PERFORMANCE_MODEL_ROW = {title: '', description: null};
const EMPTY_PERFORMANCE_DRIVER = {name: '', performance: 1, status: 5};

export const INITIAL_STATE = {
  risks: [EMPTY_RISK],
  performanceModel: [EMPTY_PERFORMANCE_MODEL_ROW],
  performanceDrivers: [EMPTY_PERFORMANCE_DRIVER],
  title: 'Strategic Dashboard',
  description: 'The strategic dashboard is the collective working model that defines and drives performance for mission and vision. The purpose of this exercise is to create a dashboard, in an at-a-glance format, for us to see our performance objectives surrounded by the indicator lights of both the drivers that fuel performance and the indicator lights of the risks and constraints that can slow performance. This is the heartbeat of the plan.',
};


export class StrategicDashboardInner extends Component {
  state = {}

  render() {
    if (this.props.onEditData) {
      return this.renderEditor();
    } else {
      return this.renderViewer();
    }
  }

  renderEditor() {
    const { data, onEditData } = this.props;

    return (
      <div className="strategicDashboardInner">
        <section className='risks' key='0'>
          <div>
            <h2>Risks</h2>
            <span>&larr; high | low &rarr;</span>
          </div>

          <ul>
            {
              data.risks.map((item, i) =>
                <li key={i}>
                  <button
                    title='Delete risk'
                    className="delete"
                    onClick={() => onEditData(update(data, {
                      risks: {
                        $splice: [
                          [i, 1]
                        ]
                      }
                    }))}
                  />
                  <div className="statusSlider">
                    <InlineInput
                      value={item.name}
                      onChange={(value, enterPressed) => {
                        let newData = update(data, {
                          risks: {
                            [i]: {
                              name: {
                                $set: value
                              }
                            }
                          }
                        });

                        if (enterPressed) {
                          if (i === data.risks.length - 1) {
                            newData = update(newData, {
                              risks: {
                                $push: [EMPTY_RISK]
                              }
                            });
                          }

                          this.setState({focusedIndex: {
                            type: 'risks',
                            index: i+1,
                          }});
                        } else {
                          this.setState({focusedIndex: null});
                        }

                        onEditData(newData);
                      }}
                      isFocused={_.isEqual(this.state.focusedIndex, {
                        type: 'risks',
                        index: i,
                      })}
                    />
                    <label>
                      <span className="title">Risk</span>
                      <input
                        type='range'
                        value={6 - item.magnitude}
                        min='1'
                        max='5'
                        step='0.5'
                        onChange={(event) => onEditData(update(data, {
                          risks: {
                            [i]: {
                              magnitude: {
                                $set: 6 - parseFloat(event.target.value, 10)
                              }
                            }
                          }
                        }))}
                      />
                      <span className="value">{item.magnitude}</span>
                    </label>
                    <StatusAndTrendIndicator
                      value={item.status}
                      onChange={
                        (value) => onEditData(update(data, {
                          risks: {
                            [i]: {
                              status: {
                                $set: value
                              }
                            }
                          }
                        }))
                      }
                    />
                  </div>
                </li>
              )
            }
          </ul>
          <button
            className="add"
            onClick={() => {
              onEditData(update(data, {
                risks: {
                  $push: [EMPTY_RISK]
                }
              }));

              this.setState({focusedIndex: {
                type: 'risks',
                index: data.risks.length
              }});
            }}
          >
            <span className="plus">+</span> Add Risk
          </button>
        </section>
        <section className='performanceModel' key='1'>
          <div>
            <h2>Performance Model</h2>
          </div>
          <ul>
            {
              data.performanceModel.map((item, i) =>
                <li key={i}>
                  <button
                    title='Delete section'
                    className="delete"
                    onClick={() => onEditData(update(data, {
                      performanceModel: {
                        $splice: [
                          [i, 1]
                        ]
                      }
                    }))}
                  />
                  <InlineTextarea
                    value={item.title}
                    onChange={(value) => {
                      onEditData(update(data, {
                        performanceModel: {
                          [i]: {
                            title: {
                              $set: value
                            }
                          }
                        }
                      }));

                      this.setState({focusedIndex: null});
                    }}
                    isFocused={_.isEqual(this.state.focusedIndex, {
                      type: 'performanceModel',
                      index: i,
                    })}
                  />
                  <RichTextEditor
                    value={item.description}
                    onChange={(value) => onEditData(update(data, {
                      performanceModel: {
                        [i]: {
                          description: {
                            $set: value
                          }
                        }
                      }
                    }))}
                  />
                </li>
              )
            }
          </ul>
          <button
            className="add"
            onClick={() => {
              onEditData(update(data, {
                performanceModel: {
                  $push: [EMPTY_PERFORMANCE_MODEL_ROW]
                }
              }));

              this.setState({focusedIndex: {
                type: 'performanceModel',
                index: data.performanceModel.length
              }});
            }}
          >
            <span className="plus">+</span> Add Section
          </button>
        </section>
        <section className='performanceDrivers' key='2'>
          <div>
            <h2>Performance Drivers</h2>
            <span>&larr; high | low &rarr;</span>
          </div>
          <ul>
            {
              data.performanceDrivers.map((item, i) =>
                <li key={i}>
                  <button
                    title="Delete Performance Driver"
                    className="delete"
                    onClick={() => onEditData(update(data, {
                      performanceDrivers: {
                        $splice: [
                          [i, 1]
                        ]
                      }
                    }))}
                  />
                  <StatusSlider
                    name={item.name}
                    onChangeName={(value, enterPressed) => {
                      let newData = update(data, {
                        performanceDrivers: {
                          [i]: {
                            name: {
                              $set: value
                            }
                          }
                        }
                      });

                      if (enterPressed) {
                        if (i === data.performanceDrivers.length - 1) {
                          newData = update(newData, {
                            performanceDrivers: {
                              $push: [EMPTY_PERFORMANCE_DRIVER]
                            }
                          });
                        }

                        this.setState({focusedIndex: {
                          type: 'performanceDrivers',
                          index: i+1,
                        }});
                      } else {
                        this.setState({focusedIndex: null});
                      }

                      onEditData(newData);
                    }}
                    isFocused={_.isEqual(this.state.focusedIndex, {
                      type: 'performanceDrivers',
                      index: i,
                    })}
                    titleText="Performance"
                    value={item.performance}
                    onChangeValue={(value) => onEditData(update(data, {
                      performanceDrivers: {
                        [i]: {
                          performance: {
                            $set: value,
                          }
                        }
                      }
                    }))}
                    status={item.status}
                    onChangeStatus={
                      (value) => onEditData(update(data, {
                        performanceDrivers: {
                          [i]: {
                            status: {
                              $set: value
                            }
                          }
                        }
                      }))
                    }
                  />
                </li>
              )
            }
          </ul>
          <button
            className="add"
            onClick={() => {
              onEditData(update(data, {
                performanceDrivers: {
                  $push: [EMPTY_PERFORMANCE_DRIVER]
                }
              }));

              this.setState({focusedIndex: {
                type: 'performanceDrivers',
                index: data.performanceDrivers.length
              }});
            }}
          >
            <span className="plus">+</span> Add Performance Driver
          </button>
        </section>
      </div>
    );
  }

  renderViewer() {
    const { data } = this.props;

    return (
      <div className="strategicDashboardInner">
        <CarouselWrapper>
          <section className='risks' key='0'>
            <div>
              <h2>Risks</h2>
              <span>&larr; high risk | low risk &rarr;</span>
            </div>
            <div className="risksInner">
              <div className="axisGroup">
                <div className="axisLine"></div>
                <div className="axisLabel high">High Priority</div>
                <div className="axisLabel low">Low Priority</div>
              </div>
              <ul className="horizontalDrivers reverse">
                {
                  data.risks.map((item, i) =>
                    <li key={i}  style={{position: 'relative'}}>
                      <StatusDisplay name={item.name} value={item.magnitude} status={item.status} reverse />
                    </li>
                  )
                }
              </ul>
            </div>
          </section>
          <section className='performanceModel' key='1'>
            <div>
              <h2>Performance Model</h2>
            </div>

            <ul>
              {
                data.performanceModel.map((item, i) =>
                  <li key={i}>
                    <h3>{item.title}</h3>
                    <RichTextEditor
                      value={item.description}
                    />
                  </li>
                )
              }
            </ul>
          </section>
          <section className='performanceDrivers' key='2'>
            <div>
              <h2>Performance Driver</h2>
              <span>&larr; high performance | low performance &rarr;</span>
            </div>

            <ul className="horizontalDrivers">
              {
                data.performanceDrivers.map((item, i) =>
                  <li key={i}>
                    <StatusDisplay name={item.name} value={item.performance} status={item.status} />
                  </li>
                )
              }
            </ul>
          </section>
        </CarouselWrapper>
      </div>
    );
  }
}

StrategicDashboardInner.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};


export default class StrategicDashboard extends Component {
  render() {
    const {data, onEditData} = this.props;

    return (
      <div className="chart strategicDashboard editableHeaders">
        <p className="stickyTitle">{data.title}</p>
        <EditableTitle
          data={data}
          onEditData={onEditData}
        />

        <StrategicDashboardInner
          data={data}
          onEditData={onEditData}
        />

      </div>
    );
  }
}

StrategicDashboard.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};


export class CarouselWrapper extends Component {
  render() {
    return (
      <div>
        <div className='desktop'>
          {this.props.children}
        </div>
        <div className='mobile'>
          <Slider
            dots={true}
            centerMode={true}
            slidesToShow={1}
            infinite={false}
          >
            {this.props.children}
          </Slider>
        </div>
      </div>
    );
  }
}

CarouselWrapper.propTypes = {
  children: PropTypes.array.isRequired,
};
