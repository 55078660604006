import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Redirect } from 'react-router-dom';
import Helmet from "react-helmet";
import PropTypes from 'prop-types';

import * as actions from '../actions.js';
import RenderFormInput from './RenderFormInput.js';


let LoginForm = ({ handleSubmit, error }) => (
  <form className="loginForm" onSubmit={handleSubmit}>
    <h1 className="loginFormHeader">Paterson Playbook Builder</h1>
    <Field name="email" component={RenderFormInput} type="email" label="Email" />
    <Field name="password" component={RenderFormInput} type="password" label="Password" />
    {error && <span className="error">{error}</span>}
    <a className="passwordResetLink" href="/auth/password_reset/">Forgot your password?</a>
    <button type="submit">Login</button>
  </form>
);

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
};

LoginForm = reduxForm({
  form: 'login',
})(LoginForm);


class Login extends Component {
  render() {
    if (this.props.session.status === 'loading') {
      return <p>Loading</p>;
    }
    else if (this.props.session.status === 'authenticated') {
      return <Redirect
        to={this.props.location.state ? this.props.location.state.next : '/'}
      />;
    }
    else {
      return (
        <div className="login-form">
          <Helmet title="Paterson Playbook Builder Login" />
          <LoginForm
            onSubmit={({email, password}) => {
              return this.props.login(email, password)
                .catch((err) => {
                  throw new SubmissionError(err.errors);
                });
            }}
          />
        </div>
      );
    }
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  session: PropTypes.object,
  location: PropTypes.object.isRequired,
};

Login = connect(
  (state) => ({
    session: state.session,
  }),
  {
    login: actions.login,
  }
)(Login);

export default Login;
