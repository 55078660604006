import React  from 'react';
import PropTypes from 'prop-types';

import { DOMAIN_COLOURS, getDomainColour } from './LifeInitiativesProfile.js';
import './WinWheel.scss';
import { ViewIcon, HideIcon } from '../icons.js';


const CHART_WIDTH = 1000;
const WHEEL_RADIUS = 310;
const WHEEL_INNER_RADIUS = WHEEL_RADIUS * 2/5;
const LABEL_LINE_LENGTH = 30;
const ITEMS_CIRCLE_SCALE = WHEEL_RADIUS / (CHART_WIDTH / 2) - 0.068;


function WinWheelMobile({data}) {
  return (
    <div className="winWheelMobile">
      {
        data.groups.map((group, i) =>
          <div key={i}>
            <h2>{group.name}</h2>
            <ul>
              {
                group.items.map((item, j) =>
                  <li key={j}>
                    {item.name}
                  </li>
                )
              }
            </ul>
          </div>
        )
      }
    </div>
  );
}
WinWheelMobile.propTypes = {
  data: PropTypes.object.isRequired,
};

export default function WinWheel({data, data: {groups}, show, toggle}) {
  const numItems = groups.reduce((a, b) => a + b.items.length, 0);

  const arcWidths = groups.map((group) =>
    group.items.length / numItems * Math.PI * 2
  );

  const arcs = [];
  let total = 0;

  arcWidths.forEach((w) => {
    arcs.push([total, total + w]);
    total += w;
  });

  return (
    <div className="winWheel">
      {show || toggle ? <h1 className="chartTitle">W.I.N. Wheel</h1> : null}
      {toggle ? (
        <button onClick={toggle}>{show ? <><HideIcon /> Hide W.I.N. Wheel</> : <><ViewIcon /> Show W.I.N. Wheel</>}</button>
      ) : null}
      <div className="winWheelInner" hidden={!show}>
        <svg
          viewBox={`${-CHART_WIDTH / 2} ${-CHART_WIDTH / 2} ${CHART_WIDTH} ${CHART_WIDTH}`}
        >
          <defs>
            {
              DOMAIN_COLOURS.map((colour, i) =>
                <marker
                  id={`circle-${colour}`}
                  key={i}
                  markerWidth="4"
                  markerHeight="4"
                  refX="2"
                  refY="2"
                >
                  <circle cx="2" cy="2" r="2" fill={colour} />
                </marker>
              )
            }
          </defs>

          {
            arcs.length === 1 ?
              <circle cx="0" cy="0" r={WHEEL_RADIUS} fill={DOMAIN_COLOURS[0]}/>
            :
              arcs.map(([start, end], i) =>
                <g key={i}>
                  <path
                    d={`M ${Math.sin(start).toFixed(10) * WHEEL_RADIUS}
                          ${Math.cos(start).toFixed(10) * -WHEEL_RADIUS}
                        A ${WHEEL_RADIUS} ${WHEEL_RADIUS}
                          0 ${(end - start >= Math.PI) ? 1 : 0} 1
                          ${Math.sin(end).toFixed(10) * WHEEL_RADIUS}
                          ${Math.cos(end).toFixed(10) * -WHEEL_RADIUS}
                        L 0 0
                        Z`}
                    fill={getDomainColour(i)}
                    stroke='#fff'
                    strokeWidth="2"
                  />,
                  <line
                    x1="0"
                    y1="0"
                    x2={Math.sin((start + end) / 2).toFixed(10) * (WHEEL_RADIUS + LABEL_LINE_LENGTH)}
                    y2={Math.cos((start + end) / 2).toFixed(10) * -(WHEEL_RADIUS + LABEL_LINE_LENGTH)}
                    strokeWidth="3"
                    stroke={getDomainColour(i)}
                    markerEnd={`url(#circle-${getDomainColour(i)})`}
                  />,
                  <text
                    x={Math.sin((start + end) / 2).toFixed(10) * (WHEEL_RADIUS + LABEL_LINE_LENGTH)}
                    y={Math.cos((start + end) / 2).toFixed(10) * -(WHEEL_RADIUS + LABEL_LINE_LENGTH)}
                    fill={getDomainColour(i)}
                    textAnchor={(start + end) / 2 < Math.PI ? 'start' : 'end'}
                    transform={(start + end) / 2 < Math.PI ? 'translate(20 5)' : 'translate(-20 6)'}
                  >
                    {groups[i].name}
                  </text>
                </g>
              )
          }

          <circle cx="0" cy="0" r={WHEEL_INNER_RADIUS} fill="white"/>
        </svg>

        <ul style={{
          transformOrigin: '50% 50%',
          transform: `scale(${ITEMS_CIRCLE_SCALE}, ${ITEMS_CIRCLE_SCALE})`,
        }}>
          {
            getItems(groups, numItems)
          }
        </ul>
      </div>

      {show ? <WinWheelMobile data={data} /> : null}
    </div>
  );
}

WinWheel.propTypes = {
  data: PropTypes.object.isRequired,
  show: PropTypes.bool,
  toggle: PropTypes.func,
};


function getItems(groups, numItems) {
  const ITEM_WIDTH = `${23 - (numItems >= 10 ? numItems/3 : 0)}%`;
  const RADIUS = `((100% - ${ITEM_WIDTH}) / 2)`;

  let i = 0;
  const items = [];

  groups.forEach((group) =>
    group.items.forEach((item) => {
      const theta = (i * 2 + 1) * Math.PI / numItems;

      items.push(
        <li
          key={i}
          className='item'
          style={{
            top: `calc(${RADIUS} - (${Math.cos(theta).toFixed(10)} * ${RADIUS}))`,
            left: `calc(${Math.sin(theta).toFixed(10)} * ${RADIUS} + ${RADIUS})`,
            height: ITEM_WIDTH,
            width: ITEM_WIDTH
          }}
        >
          <span>{item.name}</span>
        </li>
      );

      i += 1;
    })
  );

  return items;
}
