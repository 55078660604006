import React from 'react';
import { connect } from 'react-redux';
import Helmet from "react-helmet";
import { SubmissionError } from 'redux-form';
import PropTypes from 'prop-types';

import * as actions from '../actions.js';
import MetadataEditor from './MetadataEditor.js';
import RequireLogin from '../RequireLogin.js';


const BLANK_PLAYBOOK = {
  client_name: '',
  facilitation_date: '',
  client_email: '',
  facilitator: '',
  client_organization: '',
  notes: '',
};

let NewPlaybook = (props, context) => {
  if (!props.session.user.add_playbook) {
    return <div className="error"><h1>You do not have permission to see this page</h1></div>;
  }
  return (
    <div>
      <Helmet title="New Playbook"/>
      <MetadataEditor
        playbook={BLANK_PLAYBOOK}
        onFormSubmit={
          (data) => {
            return props.createPlaybook({...data, type: props.match.params.type})
              .then((playbook) =>
                context.router.history.push(`/editor/${encodeURIComponent(playbook.id)}`)
              )
              .catch((err) => {
                throw new SubmissionError(err.errors);
              });
          }
        }
        onCancel={() => {
          context.router.history.push('/');
        }}
        submitText="Create"
      />
    </div>
  );
};

NewPlaybook.propTypes = {
  createPlaybook: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

NewPlaybook.contextTypes = {
  router: PropTypes.object,
};


NewPlaybook = connect(
  ({session}) => ({session}),
  {
    createPlaybook: actions.createPlaybook,
  }
)(NewPlaybook);

NewPlaybook = RequireLogin(NewPlaybook);

export default NewPlaybook;
