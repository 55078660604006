import BaseStatusIndicator from './BaseStatusIndicator.js';
import './StatusAndTrendIndicator.scss';


export default class StatusAndTrendIndicator extends BaseStatusIndicator {
  STATUSES = [
    null,
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8
  ];

  className = 'statusIndicator statusAndTrendIndicator'

  getStatusClassNames(status) {
    if (status !== null) {
      return `status-${Math.floor(status / 3) + 1}-${status % 3 + 1}`;
    } else {
      return 'status-null';
    }
  }
}

StatusAndTrendIndicator.defaultProps = {
  ...BaseStatusIndicator.defaultProps,
  altTexts: {
    null: 'no status',
    0: 'good, getting better',
    1: 'good, holding',
    2: 'good, getting worse',
    3: 'neutral, getting better',
    4: 'neutral, holding',
    5: 'neutral, getting worse',
    6: 'bad, getting better',
    7: 'bad, holding',
    8: 'bad, getting worse',
  }
};
