export default function api(state={}, action) {
  switch (action.type) {
    case 'PLAYBOOKS_LOADED':
      return {
        ...state,
        playbooks: action.playbooks,
        facilitatorMap: action.facilitatorMap,
      };
    case 'FACILITATORS_LOADED':
      return {
        ...state,
        facilitators: action.facilitators,
      };
    case 'SET_SESSION_STATUS':
      if (action.user === null)  {
        // logout
        return {};
      } else {
        return state;
      }
    default:
      return state;
  }
}
