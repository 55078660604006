import React from 'react';
import PropTypes from 'prop-types';

import StatusAndTrendIndicator from './StatusAndTrendIndicator.js';
import { InlineInput } from './InlineInput.js';


export function StatusSlider({
  titleText, name, onChangeName, value, onChangeValue, isFocused, status,
  onChangeStatus, blankPlaceholder,
  }) {
  return (
    <div className="statusSlider">
      <InlineInput
        value={name}
        onChange={onChangeName}
        isFocused={isFocused}
        blankPlaceholder={blankPlaceholder}
      />
      <label>
        <span className="title">{titleText}</span>
        <input
          type='range'
          value={value}
          min='1'
          max='5'
          step='0.5'
          onChange={(event) => onChangeValue(parseFloat(event.target.value, 10))}
        />
        <span className="value">{value}</span>
      </label>
      <StatusAndTrendIndicator
        value={status}
        onChange={onChangeStatus}
      />
    </div>
  );
}
StatusSlider.propTypes = {
  titleText: PropTypes.string.isRequired,
  blankPlaceholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export function StatusDisplay({name, value, status, reverse}) {
  var offsetValue;

  if (reverse)
    offsetValue = 6 - value;
  else
    offsetValue = value;

  return (
    <div data-name={name}>
      <div className="lineWrap">
        <div className="line">
          <span className="dashed"></span>
          <span className="solid" style={{ width: `${(5 - value) / 4 * 100}%` }}></span>
        </div>
        <div className="marker" style={{left: `${(offsetValue - 1) / 4 * 100}%`}}>
          <StatusAndTrendIndicator
            value={status}
          />
          <span>{value}</span>
        </div>
      </div>
      <div className="label">{name}</div>
    </div>
  );
}
StatusDisplay.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  reverse: PropTypes.bool,
};
