import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from '../actions.js';


class Logout extends Component {
  componentWillMount() {
    this.props.logout().then(() =>
      this.context.router.history.push('/login')
    );
  }

  render() {
    return null;
  }
}

Logout.contextTypes = {
  router: PropTypes.object,
};

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
};

Logout = connect(null, {
  logout: actions.logout,
})(Logout);

export default Logout;
