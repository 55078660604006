import _ from 'underscore';
import Cookies from 'js-cookie';


let cachebust;

if (process.env.REACT_APP_GIT_COMMIT)
  cachebust = process.env.REACT_APP_GIT_COMMIT;
else
  cachebust = (new Date()).getTime();

const ENTRYPOINT = `/api/v1/?${cachebust}`;


function makeRequest(method, url, body) {
  return fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    body: body !== undefined ? JSON.stringify(body) : undefined,
    credentials: 'include',
  })
    .then((resp) => {
      if (resp.status === 204) {
        return null;
      }
      else if (resp.ok) {
        return resp.json();
      }
      else {
        throw resp;
      }
    });
}


function getUrl(url) {
  return makeRequest('GET', ENTRYPOINT)
    .then((json) =>
      json[url]
    );
}


export function login(email, password) {
  return getUrl('authentication')
    .then((url) =>
      makeRequest('POST', url, {email, password})
    );
}


export function logout() {
  return getUrl('authentication')
    .then((url) =>
      makeRequest('DELETE', url)
    );
}


export function getLoggedInUser() {
  return getUrl('authentication')
    .then((url) =>
      makeRequest('GET', url)
    );
}


export function getPlayBooks(search) {
  return getUrl('playbooks')
    .then((url) =>
      makeRequest('GET', url + search)
    );
}


export function savePlaybook(playbook) {
  return getUrl('playbooks')
    .then((playbooksUrl) =>
      makeRequest('PATCH', `${playbooksUrl}${playbook.id}/`, playbook)
    );
}


export function createPlaybook(metadata) {
  return getUrl('playbooks')
    .then((url) =>
      makeRequest('POST', url, metadata)
    );
}


export function getPlaybook(id) {
  return getUrl('playbooks')
    .then((playbooksUrl) =>
      makeRequest('GET', `${playbooksUrl}${id}/`)
    );
}


export function getFacilitators() {
  return getUrl('facilitators')
    .then((url) =>
      makeRequest('GET', url)
    );
}


export function getPermissions(playbook) {
  return makeRequest('GET', playbook.permissions);
}


export function setPermissionLevel(playbook, email, level, resend) {
  if (level === null) {
    let perm = _.find(playbook.permissionsList, (p) => p.user.email === email);
    return makeRequest('DELETE', perm.url);
  } else {
    return makeRequest('POST', playbook.permissions, {
      email,
      level,
      resend,
    });
  }
}
