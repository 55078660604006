import React from 'react';
import { Bezier } from 'bezier-js';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import DraggableInputChartBase from './DraggableInputChartBase.js';
import EditableTitle from '../EditableTitle';
import './LifeCycleAnalysis.scss';


export default class LifeCycleAnalysis extends DraggableInputChartBase {
  constructor(props) {
    super(props);

    this.graphWidth = 1000;
    this.graphHeight = 1000;

    //constants for quadratic equation of the curve to place items on
    this.a = 19/3200;
    this.b = -95/16;
    this.c = 12275/8;

    this.equation = (x) => (this.a*x*x + this.b*x + this.c);

    this.root1 = (-this.b - Math.sqrt(this.b * this.b - 4 * this.a * (this.c - this.graphHeight))) / (2 * this.a);
    this.root2 = (-this.b + Math.sqrt(this.b * this.b - 4 * this.a * (this.c - this.graphHeight))) / (2 * this.a);
    this.centerX = (this.root1 + this.root2) / 2;

    this.bezierCurvePoints = [
      this.root2,
      this.graphHeight,
      this.centerX,
      (2*this.a*this.root1 + this.b) * this.centerX + (this.graphHeight - (2*this.a*this.root1 + this.b) * this.root1),
      this.root1,
      this.graphHeight
    ];

    this.curve = new Bezier(...this.bezierCurvePoints);
  }

  render() {
    const { isDragging } = this.state;
    this.itemElems = [];

    return (
      <div className="chart">
        <p className="stickyTitle">{this.props.data.title}</p>
        <EditableTitle
          data={this.props.data}
          onEditData={this.props.onEditData}
        />
        <div className="lifeCycleAnalysis">
          <div className="lifeCycleAnalysisInner">
            <svg
              viewBox={`0 0 ${this.graphWidth} ${this.graphHeight}`}
              ref={(svg) => this.svg = svg}
              onClick={this.props.onEditData ? this.addItem : null}
              className={isDragging ? 'dragging' : null}
            >
              <defs>
                <linearGradient id="ArcGradient" x1="0" x2="1" y1="0" y2="0">
                  <stop offset="0%" stopColor="#00A275" stopOpacity="1"/>
                  <stop offset="33%" stopColor="#102777" stopOpacity="1"/>
                  <stop offset="67%" stopColor="#8E0F58" stopOpacity="1"/>
                  <stop offset="100%" stopColor="#FF4500" stopOpacity="1"/>
                </linearGradient>
              </defs>
              <rect x="0" y="0" width="100%" height="100%" stroke="#999" strokeWidth="4" fill="transparent" />
              <line x1="50%" y1="0" x2="50%" y2="100%" stroke="#999" strokeWidth="2" />
              <line x1="0" y1="50%" x2="100%" y2="50%" stroke="#999" strokeWidth="2" />
              <text x="45%" y="95%" textAnchor="end" fontSize="26" fontWeight="bold" fill="#999">
                ACCELERATING
              </text>
              <text x="5%" y="7.5%" textAnchor="start" fontSize="26" fontWeight="bold" fill="#999">
                BOOMING
              </text>
              <text x="95%" y="7.5%" textAnchor="end" fontSize="26" fontWeight="bold" fill="#999">
                DECELERATING
              </text>
              <text x="55%" y="95%" textAnchor="start" fontSize="26" fontWeight="bold" fill="#999">
                TANKING
              </text>
              <path
                d={`M ${this.bezierCurvePoints[0]} ${this.bezierCurvePoints[1]}
                    Q ${this.bezierCurvePoints[2]} ${this.bezierCurvePoints[3]}
                    ${this.bezierCurvePoints[4]} ${this.bezierCurvePoints[5]}`}
                stroke="url(#ArcGradient)"
                strokeWidth="3"
                fill="transparent"
              />
            </svg>

            {
              this.renderItems()
            }
          </div>
        </div>
      </div>
    );
  }

  mouseCoordinatesToData = (x, y) => {
    let point = this.svg.createSVGPoint();
    point.x = x;
    point.y = y;
    point = point.matrixTransform(this.svg.getScreenCTM().inverse());
    return {
      x: (this.curve.project(point).x - this.root1) / (this.root2 - this.root1)
    };
  }

  getItemProps = (itemIndex) => {
    const item = this.state.data.items[itemIndex];

    return {
      className: classNames('item', {betaQuadrant: item.x > 0.84}),
      style: {
        left: `${(item.x * (this.root2 - this.root1) + this.root1) * 100 / this.graphWidth}%`,
        top: `${this.equation(item.x * (this.root2 - this.root1) + this.root1) * 100 / this.graphWidth}%`
      }
    };
  }
}

LifeCycleAnalysis.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
