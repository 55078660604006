import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as widgets from '../components/widgets';


export default class Widget extends Component {
  render() {
    const {data, onEditData} = this.props;
    let WidgetComponentClass = widgets[data.type];

    return (
      <div ref={(e) => this.elem = e}>
        <WidgetComponentClass
          data={data}
          onEditData={onEditData}
          ref={(elem) => this.elem = elem}
        />
      </div>
    );
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (this.props.scrolledTo) {
      // I don't know why, but it doesn't work without the setTimeout on the Viewer.
      setTimeout(
        () => this.elem.scrollIntoView({
          behavior: "smooth",
          block: 'start'
        }),
        0
      );
    }
  }
}

Widget.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
  scrolledTo: PropTypes.bool,
};
