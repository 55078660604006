import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';


const RequireLogin = (Component) => {
  let NewComponent = (props) => {
    if (props.session.status === 'loading') {
      return null;
    } else if (props.session.status === 'unauthenticated') {
      return <Redirect to={{
        pathname: '/login',
        state: {next: props.match.url}
      }} />;
    } else {
      return <Component {...props} />;
    }
  };

  NewComponent.propTypes = {
    session: PropTypes.object,
    match: PropTypes.object.isRequired,
  };

  return connect(
    ({ session }) => ({ session })
  )(NewComponent);
};


export default RequireLogin;
