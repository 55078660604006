export function arrayFill(n, value) {
  return Array.apply(null, Array(n)).map(() => value);
}


export function leftPad(n) {
  return n < 10 ? '0' + n : n;
}


export function clamp(n, min, max) {
  return Math.min(Math.max(n, min), max);
}


export function isValidEmail(email) {
  return /.+@.+\...+/.test(email);
}
