import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '../actions.js';
import LifePlanOnAPage from './planOnAPage/lifeplan.js';
import StratOpPlanOnAPage from './planOnAPage/stratop.js';
import { removeUndoState } from '../reducers/playbook.js';
import RequireLogin from '../RequireLogin.js';


export function BarePlanOnAPage({ playbook }) {
  if (playbook.type === 'lifeplan') {
    return (
      <LifePlanOnAPage
        widgets={playbook.document.widgets}
        client_name={playbook.client_name}
      />
    );
  } else {
    return (
      <StratOpPlanOnAPage
        widgets={playbook.document.widgets}
        client_name={playbook.client_name}
      />
    );
  }
}
BarePlanOnAPage.propTypes = {
  playbook: PropTypes.object,
};

class PlanOnAPage extends Component {
  componentDidMount() {
    this.props.loadPlaybook(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.clearPlaybook();
  }


  render() {
    let { playbook } = this.props;

    if (!playbook)
      return null;

    playbook = removeUndoState(this.props.playbook);
    return (
      <>
        <button
          className="printPlanOnaPage"
          onClick={window.print}
        >
          Print
        </button>
        <BarePlanOnAPage playbook={playbook} />;
      </>
    );
  }
}

PlanOnAPage.propTypes = {
  loadPlaybook: PropTypes.func.isRequired,
  playbook: PropTypes.object,
  match: PropTypes.object.isRequired,
  clearPlaybook: PropTypes.func.isRequired,
};

PlanOnAPage = RequireLogin(PlanOnAPage);

PlanOnAPage = connect(
  (state) => ({
    session: state.session,
    playbook: state.playbook,
  }),
  {
    loadPlaybook: actions.loadPlaybook,
    clearPlaybook: actions.clearPlaybook,
  }
)(PlanOnAPage);

export default PlanOnAPage;
