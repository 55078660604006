import BaseStatusIndicator from './BaseStatusIndicator.js';

import './SimpleStatusIndicator.scss';


export default class SimpleStatusIndicator extends BaseStatusIndicator {
  STATUSES = [
    null,
    1,
    2,
    3,
  ]

  className = 'statusIndicator'

  getStatusClassNames(status) {
    return `simple-status-${status}`;
  }
}

SimpleStatusIndicator.defaultProps = {
  ...BaseStatusIndicator.defaultProps,
  altTexts: {
    null: 'no status',
    1: 'good',
    2: 'neutral',
    3: 'bad',
  }
};
