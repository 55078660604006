import React, {Component} from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import _ from 'underscore';

import EditableTitle from '../EditableTitle';
import RichTextEditor from '../RichTextEditor.js';
import { InlineInput, InlineTextarea } from '../InlineInput.js';
import StatusAndTrendIndicator from '../StatusAndTrendIndicator.js';
import { leftPad } from '../../utils.js';
import { CarouselWrapper } from './StrategicDashboard.js';
import './Dashboard.scss';
import { StatusSlider, StatusDisplay } from '../StatusSlider.js';


const EMPTY_RISK = {risk: '', status: null};
const EMPTY_VITAL_SIGN = {name: '', health: 1, status: 0};
const EMPTY_DIRECTION = {title: '', description: null};

export const INITIAL_STATE = {
  title: "My Life Dashboard",
  description: "The purpose of this tool is to converge gained perspective into a clear Life Pathway (consisting of personalized and compelling statements for your life purpose, life vision, and life strategies). Critical Vital Signs and Risks populate the right and left panels of this tool, and if accurate and true, possess breakthrough potential to help you activate and live your uniquely created purpose and vision. This is the heartbeat of your LifePlan.",
  risks: [EMPTY_RISK],
  direction: [
    {title: 'My Life Purpose', description: null},
    {title: 'My Life Vision', description: null},
    {title: 'My Life Strategies', description: null},
  ],
  vitalSigns: [EMPTY_VITAL_SIGN],
};


export class MyLifeDashboardInner extends Component {
  state = {}

  render() {
    const {data, onEditData} = this.props;

    return (
      <div className="myLifeDashboardInner">
        <CarouselWrapper>
        {
            this.getSection(
              'risks',
              'Risk/Constraint',
              'Risks/Constraints',
              (itemData, i) => [
                <span key="0">
                  {leftPad(i+1)}
                </span>,
                <StatusAndTrendIndicator
                  key="1"
                  value={itemData.status}
                  onChange={
                    onEditData ?
                      (value) => onEditData(update(data, {
                        risks: {
                          [i]: {
                            status: {
                              $set: value
                            }
                          }
                        }
                      }))
                    :
                      null
                  }
                />,
                onEditData ?
                  <InlineInput
                    key="2"
                    value={itemData.risk}
                    blankPlaceholder="Description"
                    onChange={(value, enterPressed) => {
                      let newData = update(data, {
                        risks: {
                          [i]: {
                            risk: {
                              $set: value
                            }
                          }
                        }
                      });

                      if (enterPressed) {
                        if (i === data.risks.length - 1) {
                          newData = update(newData, {
                            risks: {
                              $push: [EMPTY_RISK]
                            }
                          });
                        }

                        this.setState({focusedIndex: {
                          type: 'risks',
                          index: i+1,
                        }});
                      } else {
                        this.setState({focusedIndex: null});
                      }

                      onEditData(newData);
                    }}
                    isFocused={_.isEqual(this.state.focusedIndex, {
                      type: 'risks',
                      index: i,
                    })}
                  />
                :
                  <span key="2">{itemData.risk}</span>
              ],
              EMPTY_RISK
            )
          }

          {
            this.getSection(
              'direction',
              'section',
              'Life Direction Pathway',
              (itemData, i) => [
                onEditData ?
                  <InlineTextarea
                    key="0"
                    value={itemData.title}
                    blankPlaceholder="Direction"
                    onChange={(value) => {
                      onEditData(update(data, {
                        direction: {
                          [i]: {
                            title: {
                              $set: value
                            }
                          }
                        }
                      }));

                      this.setState({focusedIndex: null});
                    }}
                    isFocused={_.isEqual(this.state.focusedIndex, {
                      type: 'direction',
                      index: i,
                    })}
                  />
                :
                  <h3 key="0">{itemData.title}</h3>,
                <RichTextEditor
                  key="1"
                  value={itemData.description}
                  onChange={
                    onEditData ?
                      (value) => onEditData(update(data, {
                        direction: {
                          [i]: {
                            description: {
                              $set: value
                            }
                          }
                        }
                      }))
                    :
                      null
                  }
                />
              ],
              EMPTY_DIRECTION
            )
          }

          {
            this.getSection(
              'vitalSigns',
              'Vital Sign',
              'Vital Signs',
              (itemData, i) =>
                onEditData ?
                  <StatusSlider
                    blankPlaceholder="Vital Sign..."
                    name={itemData.name}
                    onChangeName={(value, enterPressed) => {
                      let newData = update(data, {
                        vitalSigns: {
                          [i]: {
                            name: {
                              $set: value
                            }
                          }
                        }
                      });

                      if (enterPressed) {
                        if (i === data.vitalSigns.length - 1) {
                          newData = update(newData, {
                            vitalSigns: {
                              $push: [EMPTY_VITAL_SIGN]
                            }
                          });
                        }

                        this.setState({focusedIndex: {
                          type: 'vitalSigns',
                          index: i+1,
                        }});
                      } else {
                        this.setState({focusedIndex: null});
                      }

                      onEditData(newData);
                    }}
                    isFocused={_.isEqual(this.state.focusedIndex, {
                      type: 'vitalSigns',
                      index: i,
                    })}
                    status={itemData.status}
                    value={itemData.health}
                    onChangeStatus={(value) =>
                        onEditData(update(data, {
                          vitalSigns: {
                            [i]: {
                              status: {
                                $set: value
                              },
                            }
                          }
                        }))
                    }
                    onChangeValue={(value) =>
                        onEditData(update(data, {
                          vitalSigns: {
                            [i]: {
                              health: {
                                $set: value
                              },
                            }
                          }
                        }))
                    }
                  />
                :
                  <StatusDisplay name={itemData.name} value={itemData.health} status={itemData.status} />,
              EMPTY_VITAL_SIGN,
              <span>&larr; healthy | unhealthy &rarr;</span>
            )
          }
        </CarouselWrapper>
      </div>
    );
  }

  getSection(key, name, title, renderInner, emptyItemData, intro=null) {
    const {data, onEditData} = this.props;

    return (
      <section className={key}>
        <div>
          <h2>{title}</h2>
          {intro}
        </div>
        <ul>
          {
            data[key].map((item, i) =>
              <li key={i}>
                {
                  onEditData ?
                    <button
                      title={`Delete ${name}`}
                      className="delete"
                      onClick={() => onEditData(update(data, {
                        [key]: {
                          $splice: [
                            [i, 1]
                          ]
                        }
                      }))}
                    />
                  :
                    null
                }
                {
                  renderInner(item, i)
                }
              </li>
            )
          }
        </ul>
        {
          onEditData ?
            <button
              className="add"
              onClick={() => {
                onEditData(update(data, {
                  [key]: {
                    $push: [emptyItemData]
                  }
                }));

                this.setState({focusedIndex: {
                  type: key,
                  index: data[key].length
                }});
              }}
            >
              <span className="plus">+</span> Add {name}
            </button>
          :
            null
        }
      </section>
    );
  }
}

MyLifeDashboardInner.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};



export default class MyLifeDashboard extends Component {
  render() {
    return (
      <div className="chart myLifeDashboard">
        <p className="stickyTitle">{this.props.data.title}</p>
        <EditableTitle
          data={this.props.data}
          onEditData={this.props.onEditData}
        />
        <MyLifeDashboardInner
          data={this.props.data}
          onEditData={this.props.onEditData}
        />
      </div>
    );
  }
}

MyLifeDashboard.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
