import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Widget from './Widget.js';


class EditableWidget extends Component {
  state = {}

  render() {
    const {data, onEditData, onDelete, scrolledTo } = this.props;

    return (
      <section
        className={classNames('playbookChart', {
          scrolledTo: scrolledTo,
        })}
      >
        <button className="delete" onClick={onDelete} title="Delete" />
        <Widget data={data} onEditData={onEditData} scrolledTo={scrolledTo} />
      </section>
    );
  }
}

EditableWidget.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  scrolledTo: PropTypes.bool,
};


EditableWidget = connect(
  null,
  (dispatch, {index}) => ({
    onEditData: (newData) => {
      dispatch({
        type: 'EDIT_WIDGET',
        widgetIndex: index,
        data: newData
      });
    },
    onDelete: () => {
      dispatch({
        type: 'DELETE_WIDGET',
        index: index,
      });
    },
  })
)(EditableWidget);

export default EditableWidget;
