import React from 'react';
import CycleChartBase from './CycleChartBase.js';
import EditableTitle from '../EditableTitle';
import StatusAndTrendIndicator from '../StatusAndTrendIndicator.js';
import RichTextEditor from '../RichTextEditor.js';

export const INITIAL_STATE = {
  center: "What Refuels Me",
  items: [
    {text: null, status: null},
  ],
  title: "Replenishment Cycle",
  description: "These are thoughtful activities that help you to be more productive, more creative, and more engaged with people and projects in all domains of your life. This is you...working on YOU.",
};


export default class ReplenishmentCycle extends CycleChartBase {
  STATUS_COMPONENT = StatusAndTrendIndicator;
  ALT_TEXTS = StatusAndTrendIndicator.defaultProps.altTexts;
  DESCRIPTION_COMPONENT = RichTextEditor;

  getHeader() {
    return [
      <p key='0' className="stickyTitle">{this.props.data.title}</p>,
      <EditableTitle
        key='1'
        data={this.props.data}
        onEditData={this.props.onEditData}
      />
    ];
  }
}
