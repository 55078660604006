import React from 'react';
import PropTypes from 'prop-types';

import statusGreen from '../image/status-green.svg';
import statusGreenYellow from '../image/status-green-yellow.svg';
import statusYellow from '../image/status-yellow.svg';
import statusYellowRed from '../image/status-yellow-red.svg';
import statusRed from '../image/status-red.svg';
import statusGreenUp from '../image/status-arrows/green-up.svg';
import statusGreenFlat from '../image/status-arrows/green-flat.svg';
import statusGreenDown from '../image/status-arrows/green-down.svg';
import statusYellowUp from '../image/status-arrows/yellow-up.svg';
import statusYellowFlat from '../image/status-arrows/yellow-flat.svg';
import statusYellowDown from '../image/status-arrows/yellow-down.svg';
import statusRedUp from '../image/status-arrows/red-up.svg';
import statusRedFlat from '../image/status-arrows/red-flat.svg';
import statusRedDown from '../image/status-arrows/red-down.svg';

import './StatusIndicatorDecorator.scss';


const STATUS_ICONS = {
  'green': statusGreen,
  'green-yellow': statusGreenYellow,
  'yellow': statusYellow,
  'yellow-red': statusYellowRed,
  'red': statusRed,
  'green-up': statusGreenUp,
  'green-flat': statusGreenFlat,
  'green-down': statusGreenDown,
  'yellow-up': statusYellowUp,
  'yellow-flat': statusYellowFlat,
  'yellow-down': statusYellowDown,
  'red-up': statusRedUp,
  'red-flat': statusRedFlat,
  'red-down': statusRedDown,
};


const STATUS_ALT_TEXTS = {
  'green': 'good',
  'green-yellow': 'good/neutral',
  'yellow': 'neutral',
  'yellow-red': 'neutral/bad',
  'red': 'bad',
  'green-up': 'good, getting better',
  'green-flat': 'good, holding',
  'green-down': 'good, getting worse',
  'yellow-up': 'neutral, getting better',
  'yellow-flat': 'neutral, holding',
  'yellow-down': 'neutral, getting worse',
  'red-up': 'bad, getting better',
  'red-flat': 'bad, holding',
  'red-down': 'bad, getting worse',
};


function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}


function StatusIndicatorComponent({decoratedText, children}) {
  decoratedText = decoratedText.slice(1, -1);
  return (
    <span className="statusIndicatorDecorator">
      {children}
      <img
        src={STATUS_ICONS[decoratedText]}
        alt={STATUS_ALT_TEXTS[decoratedText]}
        title={STATUS_ALT_TEXTS[decoratedText]}
      />
    </span>
  );
}
StatusIndicatorComponent.propTypes = {
  decoratedText: PropTypes.string.isRequired,
  children: PropTypes.array,
};


const statusIndicatorDecorator = [{
  strategy: (contentBlock, callback) => {
    findWithRegex(
      new RegExp('\\[(' + Object.keys(STATUS_ICONS).join('|') + ')\\]', 'g'),
      contentBlock,
      callback
    );
  },
  component: StatusIndicatorComponent,
}];

export default statusIndicatorDecorator;
