import React, {Component} from 'react';
import update from 'immutability-helper';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './GenericTable.scss';

import RichTextEditor from '../RichTextEditor.js';
import { arrayFill } from '../../utils.js';
import EditableTitle from '../EditableTitle';

export const INITIAL_STATE = {
  title: '',
  description: '',
  rows: [
    [null],
  ],
  highlightedRows: [false],
  highlightedColumns: [false],
  isNumbered: false,
  fixedLayout: false,
  originalType: 'GenericTable'
};


export default class GenericTable extends Component {
  render() {
    const { data, onEditData } = this.props;

    return (
      <div className="chart editableHeaders">
        <p className="stickyTitle">{data.title}</p>
        <EditableTitle data={data} onEditData={onEditData} />

        {
          onEditData ?
            <div className='fixedLayoutToggleWrap'>
              <label>
                <input
                  type="checkbox"
                  checked={data.fixedLayout}
                  onChange={(event) => onEditData({...data, fixedLayout: event.target.checked})}
                />
                Use equal-width columns
              </label>
            </div>
          :
            null
        }
        <div className='genericTableWrap'>
          <table className={classNames('genericTable', {
            isNumbered: data.isNumbered,
            fixedLayout: data.fixedLayout,
          })}>
            <tbody>
              {
                onEditData ?
                  <tr className="actions">
                    <td className="actions" />
                    {
                      data.isNumbered ? <td className="actions" /> : null
                    }
                    {
                      data.rows[0].map((value, colIndex) =>
                        <td key={colIndex} className="actions">
                          <button
                            title="Highlight column"
                            className="highlight"
                            onClick={() => onEditData(update(data, {
                              highlightedColumns: {
                                [colIndex]: {
                                  $set: !data.highlightedColumns[colIndex]
                                }
                              }
                            }))}
                          />
                          {
                            colIndex === 0 ?
                              <button
                                title="Number"
                                className="number"
                                onClick={() => onEditData({
                                  ...data,
                                  isNumbered: !data.isNumbered,
                                })}
                              />
                            :
                              null
                          }
                          <button
                            title="Delete column"
                            className="delete"
                            onClick={() => onEditData({
                              ...data,
                              rows: data.rows.map((row) =>
                                row.filter((value, i) => i !== colIndex)
                              ),
                              highlightedColumns: data.highlightedColumns.filter((value, i) =>
                                i !== colIndex
                              )
                            })}
                            disabled={data.rows[0].length <= 1}
                          />
                        </td>
                      )
                    }
                    <td className="actions">
                      <button
                        className="add"
                        onClick={() => onEditData({
                          ...data,
                          rows: data.rows.map((row) => [...row, null]),
                          highlightedColumns: [...data.highlightedColumns, false]
                        })}
                      >
                        <span className="plus">+</span>
                      </button>
                    </td>
                  </tr>
                :
                  null
              }
              {
                data.rows.map((row, rowIndex) =>
                  <tr
                    key={rowIndex}
                    className={classNames({
                      highlighted: data.highlightedRows[rowIndex]
                    })}
                  >
                    {
                      onEditData ?
                        <td className="actions">
                          <button
                            title="Highlight row"
                            className="highlight"
                            onClick={() => onEditData(update(data, {
                              highlightedRows: {
                                [rowIndex]: {
                                  $set: !data.highlightedRows[rowIndex]
                                }
                              }
                            }))}
                          />
                          <button
                            title="Delete row"
                            className="delete"
                            onClick={() => onEditData(update(data, {
                              rows: {
                                $splice: [
                                  [rowIndex, 1]
                                ],
                              },
                              highlightedRows: {
                                $splice: [
                                  [rowIndex, 1]
                                ]
                              }
                            }))}
                            disabled={data.rows.length <= 1}
                          />
                        </td>
                      :
                        null
                    }
                    {
                      data.isNumbered ?
                        <td className="numbers">
                          {(data.highlightedRows[0] ? rowIndex : rowIndex + 1) || '#'}
                        </td>
                      :
                        null
                    }
                    {
                      row.map((value, colIndex) =>
                        <td
                          key={colIndex}
                          className={classNames({highlighted: data.highlightedColumns[colIndex]})}
                        >
                          <RichTextEditor
                            value={value}
                            onChange={
                              onEditData ?
                                (value) => onEditData(update(data, {
                                  rows: {
                                    [rowIndex]: {
                                      [colIndex]: {
                                        $set: value
                                      }
                                    }
                                  }
                                }))
                              :
                                null
                            }
                          />
                        </td>
                      )
                    }
                  </tr>
                )
              }
              {
                onEditData ?
                  <tr className="add actions"><td className="actions" /><td className="actions">
                    <button
                      className="add"
                      onClick={() => onEditData(update(data, {
                        rows: {
                          $push: [arrayFill(data.rows[0].length, null)]
                        },
                        highlightedRows: {
                          $push: [false]
                        }
                      }))}
                    >
                      <span className="plus">+</span> Add Row
                    </button>
                  </td></tr>
                :
                  null
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

GenericTable.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
