import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as keycodes from '../keycodes.js';


export default class Modal extends Component {
  render() {
    const {message, actions, dismissible, closeModal} = this.props;
    return (
      <div
        className="modal"
      >
        <div className="modalInner">
          <div className="modalContent">
            {message}
            <div className="actions">
              {
                actions.map((action, index) =>
                  <button key={index} onClick={action.action}>{action.text}</button>
                )
              }
            </div>
            {
              dismissible ?
                <button onClick={closeModal} className="close"></button>
                :
                null
            }
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (event) => {
    if (this.props.dismissible && event.keyCode === keycodes.ESCAPE) {
      this.props.closeModal();
    }
  }
}

Modal.defaultProps = {
  actions: []
};

Modal.propTypes = {
  message: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired,
  dismissible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
