function saveState(state={saving: false}, action) {
  switch (action.type) {
    case 'SAVE':
      return {...state, saving: true};
    case 'SAVE_COMPLETE':
      return {...state, saving: false, savedDocument: action.playbook.document};
    case 'SAVE_ERROR':
      return {...state, saving: false};
    case 'PLAYBOOK_LOADED':
      return {...state, saving: false, savedDocument: action.playbook.document};
    default:
      return state;
  }
}

export default saveState;
