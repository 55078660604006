import React from 'react';
import PropTypes from 'prop-types';

import { InlineInput, InlineTextarea } from './InlineInput';


export default function EditableTitle({ data, onEditData }) {
  if (onEditData) {
    return (
      <section className="editableTitleWrap">
        <InlineInput
          viewerElement='h1'
          editorElement='input'
          className='chartTitle chartTitleEditable'
          value={data.title}
          blankPlaceholder='Set Title...'
          onChange={(value) => onEditData({...data, title: value})}
          key={0}
        />
        <InlineTextarea
          viewerElement='h2'
          multiline={true}
          className='chartCaption chartCaptionEditable'
          value={data.description}
          blankPlaceholder='Set description...'
          onChange={(value) => onEditData({...data, description: value})}
          key={1}
        />
      </section>
    );
  } else {
    return (
      <section className="editableTitleWrap">
        <h1 key={0} className='chartTitle'>{data.title}</h1>
        { data.description ? <h2 className="chartCaption" key={1}>{data.description}</h2> : null}
      </section>
    );
  }
}

EditableTitle.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
