import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditableTitle from '../EditableTitle';
import { InlineTextarea } from '../InlineInput.js';
import './OurMission.scss';


export const INITIAL_STATE = {
  title: "Our Mission",
  description: "Why we exist",
  content: '',
};


export default class OurMission extends Component {
  render() {
    const {data, onEditData} = this.props;

    return (
      <div className="chart ourMission">
        <p className="stickyTitle">{this.props.data.title}</p>
        <EditableTitle
          data={this.props.data}
          onEditData={this.props.onEditData}
        />
        <section>
          {
            onEditData ?
              <InlineTextarea
                value={data.content}
                onChange={(value) => onEditData({
                  ...data,
                  content: value
                })}
                viewerElement='h1'
              />
            :
              <h1 className='ourMissionStatement'>{data.content}</h1>
          }
        </section>
      </div>
    );
  }
}
OurMission.propTypes = {
  data: PropTypes.object.isRequired,
  onEditData: PropTypes.func,
};
